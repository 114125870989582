import { useDispatch, useStore } from '../Store/storeProvider';
import { useHistory } from 'react-router';
import UpdateCookies from '../Helpers/UpdateCookies';
import { EventTrackAndLogData } from '../Config/TrackAndLogData';
import { trackCustomEvent } from '../Config/DataDog';
import { StepsTrackeoRedimirPin } from '../Config/StepsTrackeoRedimirPin';
import getUserMailAndCrmIdFromCookies from '../Helpers/GetUserMailAndCrmId';

const useValidarPinHandler = (data) => {
    const dispatch = useDispatch();
    const { Config } = useStore();
    const history = useHistory();
    const isError = true;

    const handler = async (resp) => {
        const { code } = resp;

        switch (code) {
            case '0000':
                // Validacion del PIN OK
                await UpdateCookies();
                trackCustomEvent(StepsTrackeoRedimirPin[4], !isError, getUserMailAndCrmIdFromCookies());
                history.push('/login/validar-pin-gracias');
            //     break;
            case '6008':
            case '6011':
                // PIN NO EXISTE
                trackCustomEvent(StepsTrackeoRedimirPin[6], isError);
                dispatch({
                    type: 'ERROR INPUT',

                    value: 'Código de Activación inválido.',
                });
                break;

            case '6009':
                // PIN USADO
                trackCustomEvent(StepsTrackeoRedimirPin[7], isError);
                dispatch({
                    type: 'ERROR INPUT',

                    value: 'El código de activación que ingresaste ya ha sido utilizado.',
                });
                EventTrackAndLogData('pin_ya_utilizado');
                break;

            case '6010':
                // PIN VENCIDO
                trackCustomEvent(StepsTrackeoRedimirPin[8], isError);
                dispatch({
                    type: 'ERROR INPUT',

                    value: 'El código de activación ha vencido. Por favor, comunicate al call center: 5199-4787',
                });
                break;

            case '0002':
                //Usuario no logeado, pide login/crear usuario.
                trackCustomEvent(StepsTrackeoRedimirPin[2]);
                const callbackUrl = `${process.env.REACT_APP_LOGIN}login/validar-pin/${Config?.Pin ? `?pin=${Config?.Pin}` : ''}`;
                const linkUrlWitoutUser = `${process.env.REACT_APP_LOGIN}login/ingresar/${callbackUrl ? `?callback=${window.btoa(callbackUrl)}` : ''}`;
                dispatch({
                    type: 'SETEO_CALLBACK',
                    value: `${window.location.pathname}?pin=${Config.Pin}`,
                });
                dispatch({
                    type: 'SETEO_ACTIONAUTO',
                    value: true,
                });
                location.href = linkUrlWitoutUser;
                break;

            case '0201':
                // PIN ASOCIOADO A OTRO CRM
                trackCustomEvent(StepsTrackeoRedimirPin[9], isError);
                dispatch({
                    type: 'ERROR INPUT',

                    value: 'No hemos podido activar el PIN a tu usuario porque se encuentra asociado a otro cliente. Te pedimos que por favor te comuniques al 5199-4787.',
                });
                break;
            case '07':
            case '08':
                // Ya tiene suscripcion
                dispatch({
                    type: 'ERROR INPUT',

                    value: 'Tu cuenta de LA NACION ya tiene una suscripción digital activa. El código de activación podrás aplicarlo en otra cuenta diferente.',
                });
                break;
            case '13':
            case '14':
            case '30':
            case '31':
                // Tarjeta asociada sin permisos, necesita desasociar.
                dispatch({
                    type: 'ERROR INPUT',

                    value: 'Tu cuenta de LA NACION ya tiene una tarjeta asociada. Para continuar, es necesario que desvincules esa tarjeta',
                });
                break;
            case '25':
            case '26':
            //     // Premium
            //     credencialCat = 'Premium';
            case '28':
                // Tarjeta Black o Premium, mensaje de acceso digital bonificado.
                dispatch({
                    type: 'ERROR INPUT',

                    value: 'Tenes una tarjeta asociada en tu cuenta de LA NACION que te permite tener acceso digital bonificado. No es necesario que utilices el código de activación.',
                });
                break;
            default:
                dispatch({
                    type: 'ERROR PAGE COMPONENT',
                    value: 'error generico',
                });
        }
    };

    return [handler];
};

export default useValidarPinHandler;
