import React from "react";
const SunCloudy = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M8.984 4.06a6.5 6.5 0 1111.286 6.436A5.5 5.5 0 0116.5 20L8 19.999A8 8 0 118.984 4.06zm2.071.544a8.026 8.026 0 014.403 4.495 5.53 5.53 0 013.12.307 4.5 4.5 0 00-7.523-4.802zM16.5 18a3.5 3.5 0 10-2.465-5.985A.02.02 0 0114 12a6 6 0 10-6 6h8.5z"
      ></path>
    </svg>
  );
};

export default SunCloudy;
