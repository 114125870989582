const _handleStatusFormik = (metaState) => {
	let res = false;

	if (metaState == undefined) {
		res = false;
	}
	// valida si vienen errores de formik que suceden al comenzar a escribir en el input
	if (metaState.error && metaState.touched) {
		res = {
			status: 'error',
			msgs: [{ status: 'error', text: metaState.error }],
		};
	} else if (!metaState.error && metaState.touched) {
		res = { status: 'success' };
		// valido errores del state del input que vienen de la respuesta del servicio luego del onSubmit
	} else if (metaState.state) {
		res = { status: metaState.state.status, msgs: metaState.state.msgs };
	}
	return res;
};

export default _handleStatusFormik;
