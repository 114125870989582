import React from "react";
const Supermarket = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M12 2a6 6 0 016 6v1h4v2h-1.167l-.757 9.083a1 1 0 01-.996.917H4.92a1 1 0 01-.996-.917L3.166 11H2V9h4V8a6 6 0 016-6zm6.826 9H5.173l.667 8h12.319l.667-8zM13 13v4h-2v-4h2zm-4 0v4H7v-4h2zm8 0v4h-2v-4h2zm-5-9a4 4 0 00-3.995 3.8L8 8v1h8V8a4 4 0 00-3.8-3.995L12 4z"
      ></path>
    </svg>
  );
};

export default Supermarket;
