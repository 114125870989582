import React from "react";
const Rain = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 23 21"
      {...r}
    >
      <path
        fill="#272727"
        d="M4 14.93a8 8 0 1111.458-9.831A5.5 5.5 0 0118 15.793v-2.13a3.5 3.5 0 10-4-5.612V8a6 6 0 10-10 4.472v2.458zM6 12h2v6H6v-6zm8 0h2v6h-2v-6zm-4 3h2v6h-2v-6z"
      ></path>
    </svg>
  );
};

export default Rain;
