import React from "react";
const HomeFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M20 20a1 1 0 01-1 1H5a1 1 0 01-1-1v-9h-.413c-.914 0-1.35-1.125-.673-1.74l8.413-7.648a1 1 0 011.346 0l8.413 7.648c.676.615.241 1.74-.673 1.74H20v9zm-8-7a1 1 0 00-1 1v4a1 1 0 102 0v-4a1 1 0 00-1-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default HomeFilled;
