import PropTypes from 'prop-types';
import { getFontFamily, getFontSize, getFontWeight } from '../helpers/getFontData';

import classNames from 'classnames';

const propTypes = {
	/**
	 * Elemento a renderizar
	 */
	as: PropTypes.string,
	className: PropTypes.string,
	font: PropTypes.string,
	size: PropTypes.string,
	weight: PropTypes.string,
	color: PropTypes.string,
	bold: PropTypes.bool,
	highlight: PropTypes.bool,
	capital: PropTypes.bool,
};

const defaultProps = {
	as: 'span',
};

const Text = ({
	as: Component,
	className,
	font,
	size,
	weight,
	highlight,
	capital,
	children,
	text,
	color,
}) => {
	const _content = children || text;

	const _font = getFontFamily(font);
	const _size = getFontSize(size);
	const _weight = getFontWeight(weight);

	const classes = classNames('text', className, _font, _size, _weight, {
		'--font-highlight': highlight,
		'--capital': capital,
	});
	const _color = { color: `${color}` };

	if (!_content) return null;

	return (
		<Component className={classes} style={_color}>
			{_content}
		</Component>
	);
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
