import React from "react";
const Bill = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M17 20H1a1 1 0 01-1-1V1a1 1 0 011-1h16a1 1 0 011 1v18a1 1 0 01-1 1zm-1-2V2H2v16h14zM5 7h8v2H5V7zm0 4h8v2H5v-2z"
      ></path>
    </svg>
  );
};

export default Bill;
