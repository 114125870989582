import React, { useEffect, useRef, useState } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Routes from './Routes/Routes';
import { Layout } from '@lana-ui/common';
import ErrorBoundary from './Components/Common/Organism/ErrorBoundary';

import './Statics/Scss/Styles.scss';
import Hotjar from './Handlers/Hotjar';
import RouteWithSubRoutes from './Routes/RouteWithSubRoutes';
import { StoreProvider } from './Store/storeProvider';

import Error404 from './Pages/Error404';

import './Helpers/Datadog';
import RecaptchaWrapper from './Components/Features/RecaptchaWrapper';

const App = () => {
    
    if (!window.dataLayer) window.dataLayer = [];

    if (!window.hj) Hotjar.HotjarRun();

    //ReactGA.initialize(process.env.REACT_APP_GAID);
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM });

    return (
        // <LoadingSpinner isLoading={isLoading}>
            <StoreProvider>
                <Router>
                    <Layout>
                        <ErrorBoundary>
                            <RecaptchaWrapper>
                                <Switch>
                                    <Redirect
                                        exact={true}
                                        from="/"
                                        to="/auth0-login"
                                    />
                                    {Routes.map((route, i) => (
                                        <RouteWithSubRoutes key={i} {...route} />
                                    ))}
                                    <Route>
                                        <Error404
                                        showHeader={true}
                                        />
                                    </Route>
                                </Switch>
                            </RecaptchaWrapper>
                        </ErrorBoundary>
                    </Layout>
                </Router>
            </StoreProvider>
        // </LoadingSpinner>
    );
};

export default App;
