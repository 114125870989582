import React from "react";
const Group = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M3 22c-.552 0-1.006-.45-.938-.997a8 8 0 0115.876 0c.069.547-.386.997-.938.997-.552 0-.991-.45-1.083-.995a6 6 0 00-11.834 0C3.991 21.549 3.553 22 3 22zm7-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm7.873 4.615c.227-.504.824-.733 1.295-.445a8.003 8.003 0 013.77 5.833c.069.548-.386.997-.938.997-.552 0-.991-.45-1.083-.996a6 6 0 00-2.58-3.992c-.46-.308-.69-.893-.464-1.397zm-.595-11.09c.17-.593.825-.911 1.333-.563A5.498 5.498 0 0121 8.5a5.499 5.499 0 01-3.9 5.263c-.576.175-1.1-.305-1.1-.909 0-.51.384-.928.855-1.125a3.501 3.501 0 00.908-5.902c-.382-.323-.622-.821-.485-1.302z"
      ></path>
    </svg>
  );
};

export default Group;
