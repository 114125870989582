import React from "react";
const Android = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M19 13H5v7h14v-7zm0-2a7 7 0 00-14 0h14zM6.382 3.968A8.962 8.962 0 0112 2c2.125 0 4.078.736 5.618 1.968l1.453-1.453 1.414 1.414-1.453 1.453A8.962 8.962 0 0121 11v10a1 1 0 01-1 1H4a1 1 0 01-1-1V11c0-2.125.736-4.078 1.968-5.618L3.515 3.93l1.414-1.414 1.453 1.453v-.001zM9 9a1 1 0 110-2 1 1 0 010 2zm6 0a1 1 0 110-2 1 1 0 010 2z"
      ></path>
    </svg>
  );
};

export default Android;
