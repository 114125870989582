const ArrowLeftLine = ({ ...r }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
			<path
				fill="#272727"
				d="M3.828 9l5.364 5.364-1.414 1.414L0 8 7.778.222l1.414 1.414L3.828 7H16v2H3.828z"
				{...r}
			></path>
		</svg>
	);
};

export default ArrowLeftLine;
