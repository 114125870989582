const ArrowLeft = ({ ...r }) => {
  return(
    <svg
			xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
			{...r}
		>
			<path
				fill="#272727"
                d="M10.828 12.364l4.243 4.243a1 1 0 11-1.414 1.414l-4.95-4.95a1 1 0 010-1.414l4.95-4.95a1 1 0 111.414 1.414l-4.243 4.243z"
                clipRule="evenodd"            
            ></path>            
		</svg>
  );}

export default ArrowLeft;