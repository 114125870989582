import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router';

import registerServiceWorker from './registerServiceWorker';


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


const onRedirectCallBack = (appState) => {
  let history = useHistory();
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: process.env.REACT_APP_DOMAIN,
  clientId: process.env.REACT_APP_CLIENTID,
  audience: process.env.REACT_APP_AUDIENCE,
  redirectUri: `${window.location.origin}/auth0-callback`,
  onRedirectCallBack: { onRedirectCallBack },
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  scope:"openid profile email offline_access"
};

ReactDOM.render(

  <Auth0Provider {...providerConfig}>
    <BrowserRouter baseName={baseUrl}>
      <App />
    </BrowserRouter>
  </Auth0Provider>,

  rootElement);

//registerServiceWorker();
