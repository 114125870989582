import Cookies from 'js-cookie'
import { createCookie, borrarCustomCookies } from '../Helpers/Cookies';
import { METERING_ARC_EXPIRATION } from '../Config/Cookies'

const meteringConfig = {
    "metering": "metering_arc",
    "serviceDown": "MeteringCookieServiceDown"
}

const obtenerCookieSuscriptor = async () => {
    borrarCustomCookies(Object.values(meteringConfig))
    let token = Cookies.get('token');

    return fetch(`${process.env.REACT_APP_APIAUTHORIZE}user`, {
        method: 'GET',
        headers: { 'X-Token': token || '' }
    })
        .then(response => {
            if (response.status === 200) {
                return response.text();
            }
            if (response.status === 500) {
                createCookie(meteringConfig["serviceDown"], true, 600);
                createCookie('metering_arc_ERROR', "response.status: 500 - " + ((response && response.statusText) ? response.statusText : ""), 600);
            }
            return null;
        })
        .then(data => {
            if (data) {
                createCookie(meteringConfig["metering"], data, METERING_ARC_EXPIRATION);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            createCookie(meteringConfig["serviceDown"], true, 600);
            createCookie('metering_arc_ERROR', error, 600);
        });
}

export default { obtenerCookieSuscriptor }