// import config from 'react-global-configuration';
import Send from './AppEvent'

const RedirectToLoginHomeMicuenta = function () {
    if (Send('LOGIN', "")) return;
    RedirectToLogin(process.env.REACT_APP_MICUENTA);
}

const RedirectToLoginPreserveCallback = () => {
    if (Send('LOGIN', "")) return;
    RedirectToLogin(location.href);
}

const RedirectToLogin = (callback) => {
    location.href = `${process.env.REACT_APP_LOGIN}ingresar/D/9/?callback=${window.btoa(callback)}`;
}

const ObtenerLinkActivarSuscripcion = () => {
    return `${process.env.REACT_APP_SUSCRIPCIONES}suscripcion/A/1/?callback=${window.btoa(location.href)}&fc=176&productCategory=Voluntario`
}

export default {
    RedirectToLoginHomeMicuenta,
    RedirectToLoginPreserveCallback,
    ObtenerLinkActivarSuscripcion
}