import React from "react";
const UnlockFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M7.778 9.601h12.278a.953.953 0 01.944.95v9.5a.95.95 0 01-.277.67.942.942 0 01-.667.279H4.944a.94.94 0 01-.667-.278A.953.953 0 014 20.05v-9.499c0-.252.1-.493.277-.671a.942.942 0 01.667-.279h.945v-.95c0-1.498.502-2.952 1.426-4.127a6.607 6.607 0 013.666-2.346 6.577 6.577 0 014.322.45 6.63 6.63 0 013.111 3.05l-1.69.849a4.735 4.735 0 00-2.222-2.18 4.698 4.698 0 00-3.087-.32 4.72 4.72 0 00-2.619 1.676 4.767 4.767 0 00-1.018 2.949v.95zm2.833 4.75v1.9h3.778v-1.9H10.61z"
      ></path>
    </svg>
  );
};

export default UnlockFilled;
