import React from "react";
const Rss = ({ ...r }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M0 0c9.941 0 18 8.059 18 18h-3C15 9.716 8.284 3 0 3V0zm0 7c6.075 0 11 4.925 11 11H8a8 8 0 00-8-8V7zm0 7a4 4 0 014 4H0v-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Rss;
