import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Button } from '@lana-ui/login';
import { Image, Text } from '@lana-ui/common';
import ErrorImage from '@images/loginImg/user-error.png';

import '../../Statics/Scss/Components/Auth0ResendSuspendEmail.scss';

function SuspendEmail(props) {
    const { onLogout } = props;
    const [disableResendEmail] = useState(false);

    const className = `${disableResendEmail ? '--disabled' : '--not-disabled'}`;
    return (
        <>
            <Image src={ErrorImage} className="img-Auth0Email" />
            <Text as="p" className="--sueca text-user-suspended">
                Tu usuario está suspendido por 24hs{' '}
            </Text>
            <div className="container-button">
                <Button
                    className={className}
                    label="Cambiar de cuenta"
                    aria-label="Cambiar de cuenta"
                    onClick={onLogout}
                ></Button>
            </div>
        </>
    );
}

export default SuspendEmail;
