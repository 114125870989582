import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_footer.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const Footer = ({ children, className }) => {
	const classes = classNames('lgn-footer', className);

	return <div className={classes}>{children}</div>;
};

Footer.propTypes = propTypes;

export default Footer;
