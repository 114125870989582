import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStore } from "../Store/storeProvider";

const RouteWithSubRoutes = (route) => {
  //CONTEXT
  const { User } = useStore();
  /**
   * macheamos el array de datos obligatorios (requiredValues) con el estado del User
   * si encotramos que falta uno hacemos redirect al page q indica el protected
   */
  const renderProtectedRoute = (props) => {
    const { requiredValues } = route.protected;
    const arr = Array.from(requiredValues).filter((item) => !!!User[item]);
    const isfail = arr.length > 0;

    return isfail ? (
      <Redirect to={route.protected.redirect} />
    ) : (
      <route.component {...props} {...route} routes={route.routes} />
    );
  };

  /**
    * handle el cual preg si la page es proteguida
    * si es true return fn el cual aplica logica de proteccion
    *   protected: {
          requiredValues: ["Email"],
          redirect: Ingresar,
        },
   */
  const handleProtectedRoute = (props) => {
    if (!route.protected)
      return <route.component {...props} {...route} routes={route.routes} />;

    return renderProtectedRoute(props);
  };

  return (
    <Route path={route.path} render={(props) => handleProtectedRoute(props)} />
  );
};

export default RouteWithSubRoutes;
