import Cookie from 'js-cookie';
const email = Cookie.get('usuarioemail');

export const SetUserContextDataDog = (email) => {
    DD_LOGS?.onReady(() => {
        console.log(
            `DDLOG :: context :: email: ${email}, gaId: ${window.gaId}`
        );
        DD_LOGS.logger.addContext('user.gaId', window.gaId);
        DD_LOGS.logger.addContext('user.email', email);
    });
};

export const loginDataDog = (tipo, SitioId, email) => {
    DD_LOGS.onReady(() => {
        SetUserContextDataDog(email);

        console.log(`DDLOG: login => SitioID: ${SitioId}, AuthTipoId: ${tipo}`);
        DD_LOGS.logger.info('customEvent', {
            eventData: {
                evento: 'login',
                origen: SitioId,
                autenticacion: tipo,
            },
        });
    });

    DD_RUM.onReady(() => {
        console.log('DD_RUM.setUser');
        DD_RUM.setUser({
            gaId: window.gaId,
            email: email,
        });
        DD_RUM.addRumGlobalContext('ingresarVersion', 2);
    });
};

export const registerDataDog = (tipo, SitioId, email) => {
    DD_LOGS.onReady(() => {
        SetUserContextDataDog(email);

        console.log(
            `DDLOG: register => SitioID: ${SitioId}, AuthTipoId: ${tipo}`
        );
        DD_LOGS.logger.info('customEvent', {
            eventData: {
                evento: 'register',
                origen: SitioId,
                autenticacion: tipo,
            },
        });
    });

    DD_RUM.onReady(() => {
        console.log('DD_RUM.setUser');
        DD_RUM.setUser({
            gaId: window.gaId,
            email: email,
        });
        DD_RUM.addRumGlobalContext('ingresarVersion', 2);
    });
};

export const registerActivationDataDog = (tipo, SitioId, email) => {
    DD_LOGS.onReady(() => {
        SetUserContextDataDog(email);

        console.log(
            `DDLOG: register-activation => SitioID: ${SitioId}, AuthTipoId: ${tipo}`
        );
        DD_LOGS.logger.info('customEvent', {
            eventData: {
                evento: 'register-activation',
                origen: SitioId,
                autenticacion: tipo,
            },
        });
    });

    DD_RUM.onReady(() => {
        console.log('DD_RUM.setUser');
        DD_RUM.setUser({
            gaId: window.gaId,
            email: email,
        });
        DD_RUM.addRumGlobalContext('ingresarVersion', 2);
    });
};

export const trackCustomEvent = (event, isError, data) => { 
    DD_LOGS.onReady(() => {
        if (isError) {
            DD_LOGS.logger.error('customEvent', {
                eventData: {
                    evento: event,
                    ...data,
                },
            });
        } else {
            DD_LOGS.logger.info('customEvent', {
                eventData: {
                    evento: event,
                    ...data,
                },
            });
        }
    });

    DD_RUM.onReady(() => {
        DD_RUM.setUser({
            gaId: window.gaId,
            email: email ? email : null,
        });
    });
};
