import React from 'react';
import { Logo, Link } from '@lana-ui/common';
import { useWindowSize } from '../../../../../../Helpers/UseWindowSize';
import './_header-activar-pin.scss';
import { readCookie } from '../../../../../../Helpers/Cookies';
import { useStore } from '../../../../../../Store/storeProvider';
import Button from '../../button/Button';
import useLogout from '../../../../../../Services/_LogoutService';
import { setOriginIdCookie } from '../../../../../../Helpers/Auth0CookieManager';
import { Origins } from '../../../../../../Config/Origin';

const HeaderActivarPin = () => {
    const windowWidth = useWindowSize();
    const isMobile = windowWidth < 767;
    const { Config } = useStore();
    
    const hasSuscribed = readCookie('ProductoPremiumId') || null;
    const hasUsuario = readCookie('usuarioemail') || null;
    const callbackUrl = `${process.env.REACT_APP_LOGIN}login/validar-pin/${Config?.Pin ? `?pin=${Config?.Pin}` : ''}`;
    const linkUrlWithoutUser = `${process.env.REACT_APP_LOGIN}login/ingresar/${callbackUrl ? `?callback=${window.btoa(callbackUrl)}` : ''}`;
    const handleLogout = useLogout();
    const handleClick = async () => {
        setOriginIdCookie(Origins.firstStepRediminPin);
        if (!!hasUsuario) {
            await handleLogout(true);
        } else {
            window.location = linkUrlWithoutUser;
        }
    }
    return (
        <header className="header-activate-pin">
            <div className="--left">
                {!isMobile ? (
                    <Logo
                        name="la-nacion"
                        href={'https://www.lanacion.com.ar'}
                        mod="--color"
                        title="Ir a LA NACION"
                    />
                ) : (
                    <Logo
                        className="logo-mobile"
                        name="ln"
                        href={'https://www.lanacion.com.ar'}
                    />
                )}
            </div>
            <div className="--right">
                <div className="user-info">
                    {!!hasUsuario && (
                        <Link
                            className="user-links --email"
                            href={`${process.env.REACT_APP_MYACCOUNT}`}
                        >
                            {hasUsuario}
                        </Link>
                    )}
                    <div className="user-info-block">
                        {!!hasUsuario && (
                            <span className="suscription-status">
                                {hasSuscribed
                                    ? 'Suscriptor digital'
                                    : 'Sin suscripción digital'}
                            </span>
                        )}
                        <Button
                            className="--not-disabled"
                            size="mini"
                            onClick={handleClick}
                            label={
                                !!hasUsuario
                                    ? 'Cambiar cuenta'
                                    : 'Iniciar sesión'
                            }
                        ></Button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderActivarPin;
