const sitiosId = {
    webLN: 1,
    webCLL: 2,
    webOH: 3,
    appLNAndroid: 4,
    clubLN: 6,
    autogestion: 7,
    experienciasBlack: 8,
    miCuenta: 9,
    appLNIOS: 11,
    pressReader: 12,
    appClubAndroid: 13,
    appClubIOS: 14,
    webSignwallLN: 15,
    webSignwallCanchallena: 16,
    foodit: 19,
    campanita: 20,
    checkoutStepper: 21,
    redimirPinStapper: 22,
    juegosLN: 24

};

export default sitiosId;
