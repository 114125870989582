import React from "react";
const ReplyFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M12 17.404V14h2a8.999 8.999 0 017.968 4.81c.022-.267.032-.537.032-.81 0-5.523-4.477-10-10-10V4.596a1 1 0 00-1.63-.777l-7.91 6.404a1 1 0 000 1.554l7.91 6.404a1 1 0 001.63-.777z"
      ></path>
    </svg>
  );
};

export default ReplyFilled;
