import React from "react";
const BulletXs = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <circle cx="12" cy="12" r="2" fill="#CCC"></circle>
    </svg>
  );
};

export default BulletXs;
