import React from "react";
const DownloadFile = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M13 12h.586c.89 0 1.337 1.077.707 1.707l-1.586 1.586a1 1 0 01-1.414 0l-1.586-1.586c-.63-.63-.184-1.707.707-1.707H11V9a1 1 0 112 0v3zm2-8H5v16h14V8h-3a1 1 0 01-1-1V4zM3 2.992C3 2.444 3.447 2 3.999 2h11.587a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707v13.579A1 1 0 0120.007 22H3.993A1 1 0 013 21.008V2.992z"
      ></path>
    </svg>
  );
};

export default DownloadFile;
