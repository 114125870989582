// Permite agregar un metodo en un eleemnto y que se propague su accion por fuera
// pero al elemento y sus hijos no. y agregamos el btn esc tambien

let OnClickOutElement = onClickOut => {
	let currentRef = null;

	function callback(clickEvent) {
		if (!currentRef || currentRef.contains(clickEvent.target)) return;
		document.removeEventListener('click', callback, true);
		onClickOut(clickEvent);
	}

	function btnEsc(clickEvent) {
		if (clickEvent.key === 'Escape' || clickEvent.key === 'Esc') {
			document.removeEventListener('keydown', btnEsc, true);
			clickEvent.preventDefault();
			onClickOut(clickEvent);
		}
	}

	function onRefObtained(ref) {
		if (ref === currentRef) return;
		currentRef = ref;
		if (ref) {
			document.addEventListener('click', callback, true);
			document.addEventListener('keydown', btnEsc, true);
		} else {
			document.removeEventListener('click', callback, true);
			document.removeEventListener('keydown', btnEsc, true);
		}
	}

	return onRefObtained;
};

export default OnClickOutElement;
