import React from 'react';

const IosStore = (props) => {
	const { className, ...r } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			width="121"
			height="40"
			viewBox="0 0 121 40"
			className={className}
			{...r}
		>
			<path
				d="M0.473755 4C0.473755 1.79086 2.26462 0 4.47376 0H116.474C118.683 0 120.474 1.79086 120.474 4V36C120.474 38.2091 118.683 40 116.474 40H4.47376C2.26462 40 0.473755 38.2091 0.473755 36V4Z"
				fill="black"
			/>
			<path
				d="M43.3765 26.0154H38.9166L37.8455 29.1779H35.9565L40.1808 17.4774H42.1435L46.3679 29.1779H44.4466L43.3765 26.0154ZM39.3785 24.5561H42.9137L41.171 19.4235H41.1222L39.3785 24.5561Z"
				fill="white"
			/>
			<path
				d="M55.491 24.9131C55.491 27.564 54.0722 29.2672 51.931 29.2672C51.3886 29.2956 50.8492 29.1706 50.3745 28.9067C49.8998 28.6427 49.5091 28.2504 49.247 27.7747H49.2065V32H47.4554V20.6473H49.1504V22.0662H49.1826C49.4567 21.5927 49.8541 21.2025 50.3324 20.9369C50.8107 20.6714 51.352 20.5404 51.8988 20.5581C54.0639 20.5581 55.491 22.2695 55.491 24.9131ZM53.6913 24.9131C53.6913 23.186 52.7987 22.0505 51.4369 22.0505C50.099 22.0505 49.1991 23.2099 49.1991 24.9131C49.1991 26.6319 50.099 27.783 51.4369 27.783C52.7987 27.783 53.6913 26.6558 53.6913 24.9131Z"
				fill="white"
			/>
			<path
				d="M64.8801 24.9131C64.8801 27.564 63.4613 29.2672 61.3201 29.2672C60.7777 29.2956 60.2383 29.1706 59.7636 28.9067C59.2889 28.6427 58.8982 28.2504 58.6361 27.7747H58.5956V32H56.8445V20.6473H58.5394V22.0662H58.5716C58.8458 21.5927 59.2431 21.2025 59.7214 20.9369C60.1998 20.6714 60.7411 20.5404 61.2879 20.5581C63.453 20.5581 64.8801 22.2695 64.8801 24.9131ZM63.0804 24.9131C63.0804 23.186 62.1878 22.0505 60.826 22.0505C59.4881 22.0505 58.5882 23.2099 58.5882 24.9131C58.5882 26.6319 59.4881 27.783 60.826 27.783C62.1878 27.783 63.0804 26.6558 63.0804 24.9131Z"
				fill="white"
			/>
			<path
				d="M71.0856 25.9179C71.2153 27.0782 72.3425 27.84 73.8828 27.84C75.3587 27.84 76.4205 27.0781 76.4205 26.0319C76.4205 25.1238 75.7801 24.58 74.2637 24.2073L72.7474 23.842C70.5988 23.323 69.6014 22.3183 69.6014 20.6878C69.6014 18.669 71.3607 17.2823 73.8589 17.2823C76.3313 17.2823 78.0262 18.669 78.0832 20.6878H76.3156C76.2098 19.5201 75.2446 18.8153 73.834 18.8153C72.4234 18.8153 71.4582 19.5284 71.4582 20.5663C71.4582 21.3935 72.0747 21.8803 73.5828 22.2529L74.8719 22.5694C77.2726 23.1371 78.27 24.1015 78.27 25.8129C78.27 28.0019 76.5263 29.3729 73.753 29.3729C71.1582 29.3729 69.4063 28.0341 69.2931 25.9178L71.0856 25.9179Z"
				fill="white"
			/>
			<path
				d="M82.049 18.6285V20.6473H83.6712V22.034H82.049V26.7368C82.049 27.4674 82.3738 27.8079 83.087 27.8079C83.2795 27.8045 83.4718 27.791 83.663 27.7673V29.1457C83.3423 29.2056 83.0165 29.2327 82.6904 29.2267C80.9633 29.2267 80.2897 28.578 80.2897 26.9236V22.034H79.0494V20.6473H80.2897V18.6285H82.049Z"
				fill="white"
			/>
			<path
				d="M84.6107 24.9131C84.6107 22.2291 86.1915 20.5424 88.6566 20.5424C91.1299 20.5424 92.7034 22.229 92.7034 24.9131C92.7034 27.6045 91.1382 29.2838 88.6566 29.2838C86.1759 29.2838 84.6107 27.6045 84.6107 24.9131ZM90.9192 24.9131C90.9192 23.0719 90.0754 21.9852 88.6566 21.9852C87.2377 21.9852 86.3949 23.0802 86.3949 24.9131C86.3949 26.7617 87.2377 27.8401 88.6566 27.8401C90.0754 27.8401 90.9192 26.7617 90.9192 24.9131Z"
				fill="white"
			/>
			<path
				d="M94.147 20.6473H95.8171V22.0993H95.8576C95.9706 21.6458 96.2363 21.2451 96.6099 20.9644C96.9836 20.6837 97.4425 20.5402 97.9095 20.5581C98.1113 20.5574 98.3125 20.5793 98.5094 20.6234V22.2612C98.2547 22.1834 97.989 22.1477 97.7227 22.1554C97.4683 22.1451 97.2147 22.19 96.9793 22.2869C96.7439 22.3838 96.5323 22.5306 96.3589 22.717C96.1856 22.9035 96.0546 23.1253 95.9751 23.3671C95.8955 23.6089 95.8692 23.8651 95.8981 24.1181V29.1779H94.147L94.147 20.6473Z"
				fill="white"
			/>
			<path
				d="M106.583 26.6724C106.347 28.221 104.839 29.2838 102.91 29.2838C100.428 29.2838 98.8876 27.6211 98.8876 24.9536C98.8876 22.2778 100.436 20.5424 102.836 20.5424C105.196 20.5424 106.68 22.1637 106.68 24.7502V25.3502H100.655V25.456C100.627 25.7699 100.667 26.0862 100.77 26.3838C100.874 26.6814 101.04 26.9535 101.257 27.182C101.474 27.4105 101.738 27.5903 102.029 27.7093C102.321 27.8283 102.635 27.8838 102.95 27.8723C103.364 27.911 103.779 27.8152 104.134 27.599C104.489 27.3828 104.765 27.0578 104.92 26.6724L106.583 26.6724ZM100.663 24.1264H104.928C104.944 23.8441 104.901 23.5616 104.803 23.2966C104.704 23.0317 104.552 22.79 104.355 22.5867C104.159 22.3834 103.922 22.2229 103.661 22.1152C103.399 22.0076 103.119 21.9552 102.836 21.9613C102.551 21.9596 102.268 22.0144 102.004 22.1225C101.74 22.2306 101.501 22.3899 101.299 22.5912C101.097 22.7925 100.936 23.0318 100.827 23.2953C100.718 23.5588 100.663 23.8412 100.663 24.1264Z"
				fill="white"
			/>
			<path
				d="M36.8992 11.4832C36.8992 9.65993 37.869 8.53368 39.4314 8.53368C39.9945 8.50579 40.5475 8.69067 40.9806 9.05161C41.4138 9.41255 41.6953 9.92316 41.7695 10.4821H40.8999C40.827 10.1509 40.6399 9.8558 40.3715 9.6485C40.103 9.4412 39.7703 9.33484 39.4314 9.34801C38.422 9.34801 37.7908 10.1702 37.7908 11.4832C37.7908 12.7926 38.422 13.6142 39.4351 13.6142C39.7616 13.6387 40.0864 13.5486 40.3538 13.3595C40.6211 13.1705 40.8142 12.8942 40.8999 12.5782H41.7695C41.6846 13.1238 41.3972 13.6173 40.9645 13.9603C40.5318 14.3033 39.9856 14.4705 39.4351 14.4286C37.8727 14.4286 36.8992 13.3028 36.8992 11.4832Z"
				fill="white"
			/>
			<path
				d="M42.7246 12.1692C42.6979 11.8903 42.7298 11.609 42.8182 11.3432C42.9066 11.0775 43.0496 10.8331 43.238 10.6258C43.4264 10.4186 43.656 10.253 43.9122 10.1396C44.1683 10.0263 44.4453 9.96777 44.7254 9.96777C45.0055 9.96777 45.2825 10.0263 45.5386 10.1396C45.7948 10.253 46.0244 10.4186 46.2128 10.6258C46.4012 10.8331 46.5442 11.0775 46.6326 11.3432C46.721 11.609 46.7529 11.8903 46.7262 12.1692C46.7534 12.4483 46.7219 12.73 46.6337 12.9962C46.5455 13.2624 46.4026 13.5072 46.2142 13.7149C46.0258 13.9225 45.796 14.0885 45.5396 14.2021C45.2832 14.3156 45.0058 14.3743 44.7254 14.3743C44.445 14.3743 44.1676 14.3156 43.9112 14.2021C43.6548 14.0885 43.425 13.9225 43.2366 13.7149C43.0482 13.5072 42.9053 13.2624 42.8171 12.9962C42.7289 12.73 42.6974 12.4483 42.7246 12.1692ZM45.865 12.1692C45.865 11.2495 45.4518 10.7117 44.7268 10.7117C43.999 10.7117 43.5895 11.2495 43.5895 12.1692C43.5895 13.0962 43.999 13.6299 44.7268 13.6299C45.4519 13.6299 45.865 13.0925 45.865 12.1692Z"
				fill="white"
			/>
			<path
				d="M47.8497 10.0496H48.6558V10.7236H48.7183C48.8245 10.4815 49.0035 10.2786 49.2305 10.1431C49.4574 10.0076 49.721 9.94629 49.9845 9.96771C50.1909 9.95219 50.3982 9.98332 50.591 10.0588C50.7837 10.1343 50.9571 10.2521 51.0981 10.4037C51.2391 10.5552 51.3443 10.7366 51.4057 10.9343C51.4671 11.132 51.4833 11.341 51.453 11.5457V14.2923H50.6157V11.756C50.6157 11.0742 50.3194 10.7351 49.7001 10.7351C49.56 10.7286 49.4201 10.7524 49.29 10.805C49.1599 10.8576 49.0427 10.9378 48.9465 11.0399C48.8503 11.142 48.7773 11.2637 48.7325 11.3967C48.6877 11.5297 48.6722 11.6708 48.6871 11.8103V14.2924H47.8497V10.0496Z"
				fill="white"
			/>
			<path
				d="M54.299 9.96772C55.2532 9.96772 55.877 10.4117 55.9589 11.1598H55.1556C55.0784 10.8483 54.7738 10.6495 54.299 10.6495C53.8316 10.6495 53.4764 10.8713 53.4764 11.2025C53.4764 11.456 53.6908 11.6157 54.1509 11.721L54.8557 11.8844C55.6626 12.0716 56.0408 12.4185 56.0408 13.0419C56.0408 13.8406 55.2965 14.3743 54.2834 14.3743C53.274 14.3743 52.6161 13.9183 52.5415 13.1666H53.3798C53.452 13.3455 53.5823 13.495 53.7497 13.591C53.917 13.687 54.1118 13.724 54.3027 13.6961C54.8244 13.6961 55.1952 13.4624 55.1952 13.1233C55.1952 12.8703 54.9965 12.7065 54.5714 12.6053L53.8316 12.4337C53.0246 12.2428 52.6511 11.8807 52.6511 11.2495C52.651 10.4973 53.3402 9.96772 54.299 9.96772Z"
				fill="white"
			/>
			<path
				d="M57.0981 9.34018L58.0762 8H59.058L57.963 9.34018H57.0981ZM57.1846 10.0496H58.0182V14.2924H57.1846V10.0496Z"
				fill="white"
			/>
			<path
				d="M59.322 14.717H60.1787C60.2495 15.0248 60.6038 15.2236 61.1688 15.2236C61.8662 15.2236 62.2794 14.8923 62.2794 14.3315V13.5172H62.2168C62.0855 13.7451 61.8937 13.9323 61.6626 14.0581C61.4315 14.1838 61.1702 14.2433 60.9074 14.2298C59.8244 14.2298 59.1546 13.3925 59.1546 12.1222C59.1546 10.8286 59.8318 9.97922 60.9157 9.97922C61.185 9.96985 61.4517 10.0347 61.6867 10.1666C61.9217 10.2985 62.1159 10.4925 62.2481 10.7273H62.3143V10.0496H63.1167V14.3273C63.1167 15.2976 62.3567 15.9132 61.1568 15.9132C60.0968 15.9132 59.4113 15.4379 59.322 14.717ZM62.295 12.1181C62.295 11.2729 61.8579 10.7314 61.1448 10.7314C60.4244 10.7314 60.0195 11.2729 60.0195 12.1181C60.0195 12.9637 60.4244 13.5052 61.1448 13.5052C61.8625 13.5052 62.295 12.9678 62.295 12.1181Z"
				fill="white"
			/>
			<path
				d="M68.0279 14.2924H67.221V13.6184H67.1547C67.0475 13.863 66.8659 14.0676 66.6359 14.2033C66.4058 14.3389 66.1389 14.3988 65.873 14.3743C65.6678 14.3888 65.4619 14.3573 65.2705 14.2819C65.079 14.2065 64.9069 14.0892 64.7667 13.9386C64.6266 13.788 64.5219 13.608 64.4604 13.4116C64.3989 13.2153 64.3821 13.0077 64.4113 12.8041V10.0496H65.25V12.586C65.25 13.2715 65.5261 13.599 66.1417 13.599C66.2844 13.6126 66.4283 13.5941 66.563 13.5449C66.6977 13.4958 66.8197 13.4172 66.9201 13.3148C67.0205 13.2125 67.0968 13.0891 67.1435 12.9535C67.1901 12.8179 67.2059 12.6737 67.1897 12.5312V10.0496H68.0279V14.2924Z"
				fill="white"
			/>
			<path
				d="M72.9498 13.1468C72.836 13.5346 72.5895 13.87 72.2535 14.0944C71.9174 14.3188 71.5131 14.4178 71.1113 14.3743C70.8318 14.3816 70.554 14.3281 70.2973 14.2174C70.0405 14.1066 69.8109 13.9413 69.6245 13.733C69.438 13.5246 69.2991 13.2782 69.2174 13.0107C69.1357 12.7433 69.1132 12.4613 69.1514 12.1843C69.1142 11.9065 69.1371 11.6239 69.2186 11.3557C69.3001 11.0874 69.4383 10.8398 69.6238 10.6297C69.8093 10.4195 70.0378 10.2516 70.2938 10.1374C70.5498 10.0232 70.8273 9.96532 71.1077 9.96771C72.2882 9.96771 73.0004 10.7743 73.0004 12.1066V12.3987H70.0044V12.4457C69.9913 12.6014 70.0109 12.7581 70.0618 12.9058C70.1128 13.0535 70.1941 13.1889 70.3005 13.3033C70.4069 13.4178 70.536 13.5087 70.6796 13.5703C70.8232 13.6319 70.9781 13.6629 71.1343 13.6611C71.3346 13.6852 71.5375 13.6491 71.7173 13.5575C71.897 13.4659 72.0455 13.323 72.1437 13.1468L72.9498 13.1468ZM70.0044 11.7795H72.1474C72.158 11.6371 72.1386 11.494 72.0906 11.3596C72.0426 11.2251 71.967 11.1022 71.8686 10.9987C71.7703 10.8951 71.6513 10.8134 71.5195 10.7585C71.3876 10.7037 71.2458 10.6771 71.103 10.6804C70.9582 10.6785 70.8145 10.7057 70.6803 10.7603C70.5462 10.8149 70.4243 10.8958 70.322 10.9983C70.2196 11.1007 70.1387 11.2226 70.0842 11.3568C70.0296 11.4909 70.0025 11.6347 70.0044 11.7795Z"
				fill="white"
			/>
			<path d="M74.1634 8.39335H75.0008V14.2924H74.1634V8.39335Z" fill="white" />
			<path
				d="M76.1647 12.1692C76.1381 11.8903 76.1699 11.609 76.2584 11.3432C76.3468 11.0775 76.4898 10.8331 76.6782 10.6258C76.8666 10.4186 77.0962 10.253 77.3523 10.1396C77.6085 10.0263 77.8855 9.96777 78.1656 9.96777C78.4457 9.96777 78.7227 10.0263 78.9788 10.1396C79.235 10.253 79.4646 10.4186 79.653 10.6258C79.8414 10.8331 79.9844 11.0775 80.0728 11.3432C80.1612 11.609 80.1931 11.8903 80.1664 12.1692C80.1936 12.4483 80.1621 12.73 80.0739 12.9962C79.9857 13.2624 79.8428 13.5072 79.6544 13.7149C79.4659 13.9225 79.2361 14.0885 78.9797 14.2021C78.7233 14.3156 78.446 14.3743 78.1656 14.3743C77.8851 14.3743 77.6078 14.3156 77.3514 14.2021C77.095 14.0885 76.8652 13.9225 76.6768 13.7149C76.4883 13.5072 76.3454 13.2624 76.2572 12.9962C76.1691 12.73 76.1375 12.4483 76.1647 12.1692ZM79.3052 12.1692C79.3052 11.2495 78.892 10.7117 78.167 10.7117C77.4391 10.7117 77.0297 11.2495 77.0297 12.1692C77.0297 13.0962 77.4392 13.6299 78.167 13.6299C78.892 13.6299 79.3052 13.0925 79.3052 12.1692Z"
				fill="white"
			/>
			<path
				d="M87.1797 13.1468C87.066 13.5346 86.8195 13.87 86.4834 14.0944C86.1473 14.3188 85.743 14.4178 85.3413 14.3743C85.0618 14.3816 84.784 14.3281 84.5272 14.2174C84.2705 14.1066 84.0409 13.9413 83.8544 13.733C83.6679 13.5246 83.529 13.2782 83.4474 13.0107C83.3657 12.7433 83.3432 12.4613 83.3814 12.1843C83.3442 11.9065 83.3671 11.6239 83.4486 11.3557C83.5301 11.0874 83.6682 10.8398 83.8537 10.6297C84.0392 10.4195 84.2677 10.2516 84.5237 10.1374C84.7797 10.0232 85.0573 9.96532 85.3376 9.96771C86.5181 9.96771 87.2303 10.7743 87.2303 12.1066V12.3987H84.2344V12.4457C84.2213 12.6014 84.2408 12.7581 84.2918 12.9058C84.3428 13.0535 84.4241 13.1889 84.5305 13.3033C84.6369 13.4178 84.766 13.5087 84.9096 13.5703C85.0532 13.6319 85.2081 13.6629 85.3643 13.6611C85.5646 13.6852 85.7675 13.6491 85.9472 13.5575C86.127 13.4659 86.2754 13.323 86.3737 13.1468L87.1797 13.1468ZM84.2343 11.7795H86.3774C86.388 11.6371 86.3686 11.494 86.3206 11.3596C86.2726 11.2251 86.197 11.1022 86.0986 10.9987C86.0003 10.8951 85.8813 10.8134 85.7495 10.7585C85.6177 10.7037 85.4758 10.6771 85.333 10.6804C85.1882 10.6785 85.0445 10.7057 84.9103 10.7603C84.7762 10.8149 84.6543 10.8958 84.5519 10.9982C84.4495 11.1007 84.3687 11.2226 84.3142 11.3567C84.2596 11.4909 84.2325 11.6347 84.2343 11.7795Z"
				fill="white"
			/>
			<path
				d="M88.3547 10.0496H89.1608V10.7236H89.2234C89.3295 10.4815 89.5085 10.2786 89.7355 10.1431C89.9624 10.0076 90.226 9.94629 90.4895 9.96771C90.6959 9.95219 90.9032 9.98332 91.096 10.0588C91.2888 10.1343 91.4621 10.2521 91.6031 10.4037C91.7442 10.5552 91.8493 10.7366 91.9107 10.9343C91.9722 11.132 91.9883 11.341 91.958 11.5457V14.2923H91.1207V11.756C91.1207 11.0742 90.8244 10.7351 90.2051 10.7351C90.065 10.7286 89.9251 10.7524 89.795 10.805C89.6649 10.8576 89.5477 10.9378 89.4515 11.0399C89.3553 11.142 89.2823 11.2637 89.2375 11.3967C89.1927 11.5297 89.1772 11.6708 89.1921 11.8103V14.2924H88.3547V10.0496Z"
				fill="white"
			/>
			<path
				d="M99.1425 13.1468C99.0287 13.5346 98.7822 13.87 98.4461 14.0944C98.1101 14.3188 97.7058 14.4178 97.304 14.3743C97.0245 14.3816 96.7467 14.3281 96.49 14.2174C96.2332 14.1066 96.0036 13.9413 95.8171 13.733C95.6307 13.5246 95.4918 13.2782 95.4101 13.0107C95.3284 12.7433 95.3059 12.4613 95.3441 12.1843C95.3069 11.9065 95.3298 11.6239 95.4113 11.3557C95.4928 11.0874 95.631 10.8398 95.8165 10.6297C96.0019 10.4195 96.2304 10.2516 96.4864 10.1374C96.7425 10.0232 97.02 9.96532 97.3003 9.96771C98.4809 9.96771 99.1931 10.7743 99.1931 12.1066V12.3987H96.1971V12.4457C96.184 12.6014 96.2036 12.7581 96.2546 12.9058C96.3056 13.0535 96.3869 13.1889 96.4932 13.3033C96.5996 13.4178 96.7287 13.5087 96.8723 13.5703C97.0159 13.6319 97.1708 13.6629 97.327 13.6611C97.5273 13.6852 97.7302 13.6491 97.91 13.5575C98.0897 13.4659 98.2382 13.323 98.3364 13.1468L99.1425 13.1468ZM96.1971 11.7795H98.3401C98.3506 11.6371 98.3313 11.494 98.2833 11.3596C98.2353 11.2251 98.1596 11.1022 98.0613 10.9987C97.9629 10.8951 97.844 10.8134 97.7122 10.7585C97.5803 10.7037 97.4385 10.6771 97.2957 10.6804C97.1509 10.6786 97.0072 10.7057 96.873 10.7603C96.7389 10.8149 96.617 10.8958 96.5147 10.9983C96.4123 11.1007 96.3314 11.2226 96.2769 11.3568C96.2223 11.4909 96.1952 11.6347 96.1971 11.7795Z"
				fill="white"
			/>
			<path d="M100.356 8.39335H101.193V14.2924H100.356V8.39335Z" fill="white" />
			<path
				d="M27.0643 19.636C27.0741 18.8694 27.2778 18.1177 27.6562 17.451C28.0346 16.7842 28.5755 16.224 29.2286 15.8225C28.8137 15.23 28.2664 14.7423 27.6301 14.3983C26.9937 14.0543 26.286 13.8634 25.563 13.8408C24.0207 13.6789 22.5255 14.7637 21.7396 14.7637C20.9384 14.7637 19.7284 13.8569 18.4254 13.8837C17.5825 13.9109 16.7611 14.156 16.0412 14.5951C15.3212 15.0342 14.7273 15.6522 14.3172 16.3891C12.541 19.4644 13.8659 23.9841 15.5674 26.47C16.4187 27.6873 17.4137 29.047 18.7155 28.9988C19.9895 28.9459 20.4652 28.1864 22.003 28.1864C23.5265 28.1864 23.9729 28.9988 25.3011 28.9681C26.6681 28.9459 27.5294 27.7454 28.3508 26.5166C28.9625 25.6493 29.4332 24.6907 29.7454 23.6763C28.9512 23.3404 28.2735 22.7782 27.7967 22.0597C27.3199 21.3412 27.0652 20.4983 27.0643 19.636Z"
				fill="white"
			/>
			<path
				d="M24.5554 12.2059C25.3007 11.3112 25.6679 10.1611 25.579 9C24.4403 9.1196 23.3885 9.66383 22.633 10.5243C22.2637 10.9446 21.9808 11.4336 21.8006 11.9633C21.6203 12.4931 21.5463 13.0531 21.5826 13.6115C22.1522 13.6174 22.7156 13.4939 23.2306 13.2505C23.7455 13.007 24.1985 12.6498 24.5554 12.2059Z"
				fill="white"
			/>
		</svg>
	);
};

export default IosStore;
