import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './_label.scss';

const propTypes = {
	/**
	 * Icono asociado al label
	 */
	labelIcon: PropTypes.string,
	/**
	 * Texto asociado.
	 */
	text: PropTypes.string,
	/**
	 * Link adicional, posicionado
	 * a la derecha.
	 */
	link: PropTypes.node,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Tamaño del texto.
	 */
	size: PropTypes.string,
};

const Label = ({ labelIcon, text, link, size, className, ...r }) => {
	const classes = classNames('label --font-bold', className, size);

	return (
		<div className={classes} {...r}>
			<label>
				{text}
				{labelIcon && <i className={labelIcon}></i>}
			</label>
			{link && <> {link} </>}
		</div>
	);
};

Label.propTypes = propTypes;

export default Label;
