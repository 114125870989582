


const analyzeUrlCallbackAuth0 = (url) => {
    return new Promise((resolve, reject) => {
        const pathMobile = getMobilePath(url);
        const pathResetPassword = `${window.location.origin}/auth0-callback-reset-password`
        if (isMobileCallback(pathMobile)) {
            window.location.href = pathMobile;
        } else if (isResetPasswordCallback(url)) {
            window.location.href = pathResetPassword;
        }
        else {
            resolve();
        }
    })
}

const getMobilePath = (url) => {
    let location = new URL(url);
    let pathMobile = location.pathname.replace('/auth0-callback/', '');
    return pathMobile;
}

const isMobileCallback = (pathMobile) => {
    return MOBILE_PATH_CALLBACK.includes(pathMobile) && userAgentIsMobile();
}

const isResetPasswordCallback = (url) => {
    let location = new URL(url);
    let path = location.search;
    return path.includes(`?iss=${process.env.REACT_APP_RESET_PASSWORD_ISS_CALLBACK}`)
}

const userAgentIsMobile = () => {
    return navigator.userAgent.match(/Android|Mobile|webOS|iPhone|iPad|iPod|Windows Phone/i);
}

const MOBILE_PATH_CALLBACK = [
    process.env.REACT_APP_INITIATE_LOGIN_URI_IOS,
    process.env.REACT_APP_INITIATE_LOGIN_URI_ANDROID,
    process.env.REACT_APP_INITIATE_LOGIN_URI_IOS_CLUB,
    process.env.REACT_APP_INITIATE_LOGIN_URI_ANDROID_CLUB
];

export { analyzeUrlCallbackAuth0 }