import React, { useState } from 'react';
import { useEffect } from 'react';
import Error from '../../Components/Features/Error';
import PinActivacion from '../../Components/Form/PinActivacion';
import { PageViewTrackAndLogData } from '../../Config/TrackAndLogData';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import { useDispatch, useStore } from '../../Store/storeProvider';
import { useAuth0 } from '@auth0/auth0-react';
import useVerifySessionAuth0 from '../../Hooks/useVerifySessionAuth0';

const ActivaSuscripcionPin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { HandleError } = useStore();
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth0();
    

    if (HandleError.PageElement)
        return <Error config={HandleError.PageElement} />;

    useEffect(() => {
        PageViewTrackAndLogData('validar_pines');

        //activo el user module en el header, cuando se desmonta el componente lo desactivo
        dispatch({
            type: 'SETEO_USERMODULE',
            value: true,
        });
        return () => {
            dispatch({
                type: 'SETEO_USERMODULE',
                value: false,
            });
        };
    }, []);

    const validarPinHandler = async () => {
        if (!isAuthenticated) {
            window.location = `${window.location.origin}/login/ingresar/D/22/?callback=${window.btoa(window.location)}`
        }else{
            setIsLoading(false)
        }
    }

    useVerifySessionAuth0(validarPinHandler);

    return (
        <LoadingSpinner isLoading={isLoading}>
            <PinActivacion setIsLoading={setIsLoading} title={true} />
        </LoadingSpinner>
    );
};

export default ActivaSuscripcionPin;
