import React from "react";
const AlertFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M12.392 3.5L21.918 20a1 1 0 01-.866 1.5H2a1 1 0 01-.866-1.5L10.66 3.5a1 1 0 011.732 0zm-1.866 13v2h2v-2h-2zm0-7v5h2v-5h-2z"
      ></path>
    </svg>
  );
};

export default AlertFilled;
