import React, { useState } from 'react';
import { useEffect } from 'react';
import Cierre from '../../Components/Features/Cierre';
import { trackCustomEvent } from '../../Config/DataDog';
import { StepsTrackeoRedimirPin } from '../../Config/StepsTrackeoRedimirPin';
import { PageViewTrackAndLogData } from '../../Config/TrackAndLogData';
import { useStore } from '../../Store/storeProvider';
import { Wizard, Paragraph, Toast } from '@lana-ui/common';
import { steps } from '../../Config/CheckoutSteps';
import '../../../src/Statics/Scss/Pages/PinActivacion.scss';
import Progress from '../../Components/Common/common/src/progress/Progress';

const ValidarPinGracias = () => {
    const { Config } = useStore();

    const [showToast, setShowToast] = useState(true);

    useEffect(() => {
        PageViewTrackAndLogData('validar_pines_gracias');
    }, [])

    const handleClick = () => {
        trackCustomEvent(StepsTrackeoRedimirPin[5]);
        window.location.href = Config.URLCallback;
    }

    return (
        <>
            {showToast && (
                <Toast status={'success'} title="¡Listo!" value={showToast} closeAuto={true} close className="--roboto" positionFloat="right" changeValue={() => setShowToast(false)}>
                    <Paragraph className="--roboto">
                        ¡Tu suscripción ya se encuentra activa!
                    </Paragraph>
                </Toast>
            )}
            <div className="progress-steps">
                <Wizard activeStep={3} steps={steps} />
                <div className="progress-steps__container">
                    <Progress currentNumber={steps.step_3.number} amountTotalNumbers={Object.values(steps).length}/>
                    <div className="progress-steps__container__data">
                        <Paragraph size="4xs" className="--arial --font-bold">
                            {steps.step_3.text}
                        </Paragraph>
                    </div>
                </div>
            </div>
            <Cierre hasClub={true} title="¡Gracias por suscribirte!" firstBtnLabel="Volver a La Nacion" onClickCierre={handleClick} validarPin={true}/>
        </>
    );
};

export default ValidarPinGracias;
