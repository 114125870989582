import React from "react";
import { Text, Title, Image } from "@lana-ui/common";
import { Button } from "@lana-ui/login";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./_error.scss";

const propTypes = {
  /**
   * Titulo de la página
   */
  title: PropTypes.string,
  /**
   * Texto secundario
   */
  text: PropTypes.string,
  /**
   * Texto del botón
   */
  buttonText: PropTypes.string,
  /**
   * src / url de la imagen de error
   */
  imageUrl: PropTypes.string,
  /**
   * Acción a realizar cuando se presiona el botón
   */
  onClick: PropTypes.string,
  /**
   * Clases adicionales.
   */
  className: PropTypes.string,
};

const ErrorLogin = ({
  title,
  text,
  buttonText,
  imageUrl,
  onClick,
  className,
  imageWidth,
}) => {
  const classes = classNames("lgn-error", className);

  return (
    <div className={classes}>
      {imageUrl ? (
        <Image src={imageUrl} width={imageWidth} ></Image>
      ) : (
        <div className="image-error"></div>
      )}

      <Title className="--sueca --font-bold title-error" size="l">
        {title}
      </Title>

      {text && (
        <Text className="--arial" size="--fourxs text-error">
          {text}
        </Text>
      )}

      {buttonText && (
        <Button
          className="button --not-disabled button-error"
          label={buttonText}
          onClick={onClick}
        />
      )}
    </div>
  );
};

ErrorLogin.propTypes = propTypes;

export default ErrorLogin;
