import React from 'react';
import './_redireccionUsuarios.scss';
import { isIos } from '../../../Helpers/RedirectMeta';
import { RedireccionAndroid } from "./RedireccionAndroid";
import { RedireccionIOS } from "./RedireccionIOS";

const RedireccionUsuarios = () => {
    const Redirect = isIos() ? RedireccionIOS : RedireccionAndroid;
    return <Redirect/>
}

export default RedireccionUsuarios;