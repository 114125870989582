import React from "react";
const RadioOn = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zM8 12a4 4 0 108 0 4 4 0 00-8 0z"
      ></path>
    </svg>
  );
};

export default RadioOn;
