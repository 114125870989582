import React from "react";
const LockFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M19.111 9.6h.945a.95.95 0 01.944.95v9.5c0 .252-.1.494-.277.672a.942.942 0 01-.667.278H4.944a.94.94 0 01-.667-.278A.953.953 0 014 20.05v-9.5c0-.252.1-.494.277-.672a.942.942 0 01.667-.278h.945v-.95a6.684 6.684 0 011.936-4.702 6.577 6.577 0 019.35 0A6.654 6.654 0 0119.11 8.65v.95zm-1.889 0v-.95c0-1.26-.497-2.468-1.383-3.359A4.708 4.708 0 0012.5 3.9a4.71 4.71 0 00-3.34 1.391A4.764 4.764 0 007.779 8.65v.95h9.444zm-5.666 3.8v3.8h1.888v-3.8h-1.888z"
      ></path>
    </svg>
  );
};

export default LockFilled;
