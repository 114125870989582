import Me from '../Services/UsuarioService';
import setupCookies from './setupCookies';

const UpdateCookies = async () => {
    try {
        const resp = await Me();
        let { Usuario: usuarioMe } = JSON.parse(resp.response);
        setupCookies(usuarioMe);
        await window?.UserClientLibs?.RefreshAsync?.();
    } catch (error) {
        throw new Error();
    }
};

export default UpdateCookies;
