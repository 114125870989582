import { useDispatch } from '../Store/storeProvider';
import setupCookies from '../Helpers/setupCookies';
import Metering from '../Handlers/Metering';
import { readCookie } from '../Helpers/Cookies';
import {
    setSuscriptorType,
    setRegistrado,
    save,
    setUserId,
    createCustomDimension,
} from '../Helpers/CustomDimension';
import useFinalFlow from './useFinalFlow';
import { RefreshAsync } from '@ln/user.client.libs'
const useMeHandler = () => {
    const dispatch = useDispatch();
    const [handleFinalFlow] = useFinalFlow();

    const handler = async (resp, User, callback) => {
        try {
            let { Usuario: usuarioMe } = JSON.parse(resp.response);

            dispatch({
                type: 'USER_ADD_VALUES',
                value: {
                    ClubNacion: usuarioMe.ClubNacion || '',
                    Avatar: usuarioMe.Usuario_detalle_foto_perfil,
                    Email: usuarioMe.usuarioemail,
                    UsuarioDetalleApellido: usuarioMe.UsuarioDetalleApellido,
                    UsuarioDetalleNombre: usuarioMe.UsuarioDetalleNombre,
                },
            });

            setupCookies(usuarioMe);

            const onFinalizarMe = () => {
                let combotype = readCookie('gaComboType');
                if (combotype) {
                    setSuscriptorType(combotype);
                } else {
                    setSuscriptorType('N/A');
                }

                //CustomDimension
                setRegistrado('yes');
                save();
                createCustomDimension();

                //Metodo que abre en dif caminos el final de flow
                //dependiendo del estado del user ( modo, tipo cred, etc)
                handleFinalFlow(usuarioMe?.ClubNacion, User, callback);

                //TODO ESTO CORRESPONDE A SMARTLOCK
                //lo raro que se llama despues del return en el proyecto viejo
                // hay q asegurarse de alguna forma si se esta eejctuando actualemente

                // Si esta cargada la Password (vino por nativo), ejecuto el store nativo.
                // if (Model.Usuario.Clave)
                // Service.storeNativo(Model.Usuario);
            };

            await Metering.obtenerCookieSuscriptor();

            //TODO AVERIGUAR QUE ES EL MODO GLA Y APLICARLO
            //   if (Model.Window.modo === "GLA") {
            //     Model.Window.pagina = `${Model.Window.googleParams.redirect_uri}#access_token=${usuario.Usuario.TokenJWT}&token_type=bearer&state=${Model.Window.googleParams.state}`
            //     console.log(Model.Window.pagina);
            // }

            //CustomDimension
            setUserId(usuarioMe.UsuarioId);
            save();

            // TODO No sabemos poque esta creandola y despues la borra
            //en el proyecto de login. Temporalmente lo dejamos comentado.
            //localStorage.setItem('userLogedIn', 'true');
            //localStorage.removeItem('userLogedIn', true);
            try {
                await RefreshAsync();
            } catch (error) {
                console.log(error);
            }

            onFinalizarMe();
        } catch (error) {
            console.log(error, ' error handleMeService');
            dispatch({
                type: 'ERROR PAGE COMPONENT',
                value: 'error generico',
            });
        }
    };

    return [handler];
};

export default useMeHandler;
