import React from "react";
const Alert = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M12.392 3.5L21.918 20a1 1 0 01-.866 1.5H2a1 1 0 01-.866-1.5L10.66 3.5a1 1 0 011.732 0zm-8.66 16H19.32L11.526 6 3.732 19.5zm6.794-3h2v2h-2v-2zm0-7h2v5h-2v-5z"
      ></path>
    </svg>
  );
};

export default Alert;
