import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_toast.scss';

import { Icon, Title, Paragraph } from '@lana-ui/common';

const propTypes = {
    /**
     * Título del toast.
     */
    title: PropTypes.string,
    /**
     * Estado.
     */
    status: PropTypes.oneOf(['success', 'warning', 'info', 'alert']),
    /**
     * Flag, ¿se cierra?
     */
    close: PropTypes.bool,
    /**
     *
     */
    value: PropTypes.bool,
    /**
     * Close time.
     */
    closeAuto: PropTypes.number,
    /**
     * Posición del flotante.
     */
    positionFloat: PropTypes.string,
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
};

const Toast = ({
    title,
    children,
    status,
    close,
    className,
    changeValue,
    value,
    closeAuto,
    positionFloat,
    ...r
}) => {
    /*
	cierra el toast agegando animacion
	value - boolean - hook state heredado de parent
	changeValue - fun - hook fun modif el value
  */
    const handleCloseToast = (value, changeValue) => {
        if (value) {
            let $toast = document.querySelector('.toast');

            if ($toast === null) {
                return false;
            }

            $toast.classList.add('fadeOutUp');
            setTimeout(() => {
                changeValue(!value);
            }, 600);
        } else {
            changeValue(!value);
        }
    };

    /*
	cierra el toast con un determinado tiempo | validamos que no ejecute la fun si el user toca antes la x
	closeAuto - number - ms que uqeremos que tenga antes que desaparezca
  */
    const handleCloseAuto = useCallback(
        (closeAuto) => {
            closeAuto = typeof closeAuto === 'boolean' ? 6000 : closeAuto;

            const interval = setInterval(() => {
                if (value === true) {
                    handleCloseToast(value, changeValue);
                    clearInterval(interval);
                }
            }, closeAuto);
        },
        [changeValue, value]
    );

    //userEffect (didMount)
    useEffect(() => {
        if (closeAuto) {
            handleCloseAuto(closeAuto);
        }
    }, [handleCloseAuto, closeAuto]);

    const classes = classNames('toast', className, {
        [`--${status}`]: status,
        [`--position-float --${positionFloat} fade-in-down`]: positionFloat,
    });

    return (
        <div className={classes} {...r}>
            <div className="state">
                {close && (
                    <div
                        onClick={() => {
                            handleCloseToast(value, changeValue);
                            if (typeof Function === typeof close) {
                                close();
                            }
                        }}
                    >
                        <Icon name="close" className="close" />
                    </div>
                )}

                {
                    {
                        success: <Icon name="checkmark" />,
                        warning: (
                            <Icon
                                name="error-warning"
                                className="error-warning"
                            />
                        ),
                        info: <Icon name="info" className="info" />,
                        alert: <Icon name="alert" className="alert" />,
                    }[status]
                }
            </div>

            <div className="description">
                <Title tag="h3" size="s" className="--arial --font-bold">
                    {title}
                </Title>

                {children && (
                    <Paragraph size="twoxs" className="--arial --font-regular">
                        {children}
                    </Paragraph>
                )}
            </div>
        </div>
    );
};

Toast.propTypes = propTypes;

export default Toast;
