import PropTypes from "prop-types";
import OnClickOutElement from "./onClickOutElement";

import classNames from "classnames";
import "./_modal.scss";

import { Title, Button } from "@lana-ui/common";
import ModalPortalBG from "./ModalPortalBG";

const propTypes = {
  /**
   * Handler para cerrar el modal.
   */
  closeModal: PropTypes.func,
  /**
   * Título asociado.
   */
  title: PropTypes.string,
  /**
   * Clases adicionales.
   */
  className: PropTypes.string,
};

const defaultProps = {
  closeModal: () => {},
};

const Modal = ({ closeModal, title, children, className, ...r }) => {
  const classes = classNames("modal", className);

  return (
    <ModalPortalBG>
      <div className="contenedor">
        <div className={classes} ref={OnClickOutElement(closeModal)} {...r}>
          <div className="modal__header">
            <div className="mod-title">
              {title && (
                <Title tag="h1" size="--xl" className="--sueca --font-bold">
                  {title}
                </Title>
              )}
              <Button
                className="--tertiary close"
                size="--small"
                onClick={closeModal}
                icon="close"
              />
            </div>
          </div>

          <div className="modal__body">{children}</div>
        </div>
      </div>
    </ModalPortalBG>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
