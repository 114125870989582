import React, { useEffect, useState } from 'react';
import { LoginLayout } from '@lana-ui/login';
import { useStore } from '../../Store/storeProvider';
import ResendEmail from './Auth0ResendEmail';
import SuspendEmail from './Auth0UserSuspended';
import useLogout from '../../Services/_LogoutService';
import * as storage from '../../Helpers/Auth0StorageManager';

const Auth0EmailConfirmation = () => {
    const { Config } = useStore();
    const handleLogout = useLogout();
    const [userBloquedForResendEmail, setUserBloquedForResendEmail] =
        useState(false);

    useEffect(() => {
        const hasCallbackEmailVerification = storage.getCallbackEmailVerification();
        if (!hasCallbackEmailVerification) {
            storage.setCallbackEmailVerification(Config.URLCallback);
        }
    }, [Config.URLCallback]);

    const handleLogoutAuth0 = () => {
        handleLogout(true);
    };
    return (
        <LoginLayout>
            <div className="auth0EmailConfirmation">
                {userBloquedForResendEmail ? (
                    //Le muestra el componente con el mensaje y la imagen de que esta bloqueado
                    <SuspendEmail onLogout={handleLogoutAuth0}></SuspendEmail>
                ) : (
                    //Le muestra el componente que si púede reenviar el email con el timer
                    <ResendEmail
                        userBloquedForResendEmail={setUserBloquedForResendEmail}
                        onLogout={handleLogoutAuth0}
                    />
                )}
            </div>
        </LoginLayout>
    );
};

export default Auth0EmailConfirmation;
