import React from "react";
const Sun = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 22"
      {...r}
    >
      <path
        fill="#272727"
        d="M11 17a6 6 0 110-12 6 6 0 010 12zm0-2a4 4 0 100-8 4 4 0 000 8zM10 0h2v3h-2V0zm0 19h2v3h-2v-3zM2.515 3.929l1.414-1.414L6.05 4.636 4.636 6.05 2.515 3.929zM15.95 17.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121v-.001zM4.636 15.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM22 10v2h-3v-2h3zM3 10v2H0v-2h3z"
      ></path>
    </svg>
  );
};

export default Sun;
