import React from "react";
const Storm = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 22"
      {...r}
    >
      <path
        fill="#272727"
        d="M9 8h7.066a.5.5 0 01.416.777L7.916 21.626A.5.5 0 017 21.349V14H.883a.5.5 0 01-.429-.757L8.071.548C8.332.113 9 .298 9 .805V8zm-1.8 2a.2.2 0 01-.2-.2V6.942c0-.203-.267-.277-.372-.103l-2.914 4.858a.2.2 0 00.171.303H8.8c.11 0 .2.09.2.2v3.534a.2.2 0 00.366.11l3.69-5.533a.2.2 0 00-.167-.311H7.2z"
      ></path>
    </svg>
  );
};

export default Storm;
