import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image, Link } from '@lana-ui/common';
import {
    Footer,
    TextLogin,
    Button,
    MainLogin,
    CallbackArticle,
} from '@lana-ui/login';
import ImagePromoBanner from '@images/image-promo-banner.png';
import './_confirmacion.scss';

const propTypes = {
    /**
     * Nombre del titulo
     */
    titleText: PropTypes.string,
    /**
     * Src de la imagen
     */
    image: PropTypes.string,
    /**
     * Texto del primer botón del footer
     */
    firstBtnLabel: PropTypes.string,
    /**
     * Si existe, texto del segundo botón del footer
     */
    secondBtnLabel: PropTypes.string,
    /**
     * Mostrar CallbackArticle
     */
    showCallbackArticle: PropTypes.bool,
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
};

const ConfirmacionLogin = ({
    titleText,
    image,
    imageSrc,
    firstBtnLabel,
    showCallbackArticle,
    firstBtnOnClick,
    children,
    className,
    imageWidth,
    secondBtnLabel,
    secondBtnOnClick,
}) => {
    const classes = classNames('lgn-confirmacion', className);

    return (
        <div className={classes}>
            <MainLogin>
                <div className="image-confirmacion">
                    {image ? (
                        image
                    ) : (
                        <Image src={imageSrc} width={imageWidth}></Image>
                    )}
                </div>

                <TextLogin title={titleText}>{children}</TextLogin>

                {showCallbackArticle && (
                    <CallbackArticle
                        bg
                        description={
                            <>
                                <div>
                                    <p className="--twoxs">
                                        Conocé los planes digitales de{' '}
                                        <span className="ln">LA NACION.</span>
                                    </p>
                                    <Link className="banner-link" href={`${process.env.REACT_APP_SUSCRIPCIONES}`}>
                                        Mas información.
                                    </Link>
                                </div>
                            </>
                        }
                        imageUrl={ImagePromoBanner}
                        title="¡Suscribite y navegá sin límites!"
                    />
                )}
            </MainLogin>

            {firstBtnLabel && (
                <Footer>
                    <Button
                        className={'--not-disabled'}
                        label={firstBtnLabel}
                        onClick={firstBtnOnClick}
                    />
                    {secondBtnLabel && (
                        <Button
                            className={'--border-blue'}
                            label={secondBtnLabel}
                            onClick={secondBtnOnClick}
                        />
                    )}
                </Footer>
            )}
        </div>
    );
};

ConfirmacionLogin.propTypes = propTypes;

export default ConfirmacionLogin;
