export const steps = {
    step_1: {
        number: 1,
        text: 'Iniciá sesión',
    },
    step_2: {
        number: 2,
        text: 'Activá tu suscripción',
    },
    step_3: {
        number: 3,
        text: 'Disfrutá tu suscripción',
    },
};
