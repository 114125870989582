import { ID_TOKEN_EXPIRATION, REFRESH_TOKEN_EXPIRATION } from "../Config/Cookies";
import { createCookieByMinutes } from "./Cookies";

const setTokenXvalue = (xToken, xValue, dispatch) => {
  try {
    if (xToken) {
      createCookieByMinutes("token", xToken, ID_TOKEN_EXPIRATION);
      sessionStorage?.setItem("token", xToken);
    }

    if (xValue) {
      createCookieByMinutes("xvalue", xValue, REFRESH_TOKEN_EXPIRATION);
      sessionStorage?.setItem("xvalue", xValue);
    }

    dispatch({
      type: "USER_ADD_VALUES",
      value: {
        xToken,
        xValue,
      },
    });
  } catch (err) {
    console.log(err, "Error seteo cookie token/xvalue");
  }
};

export default setTokenXvalue;
