const CheckUrlMatch = (routes, location) => {
	let isMatch = false;
    routes.forEach(element => {
        if(element.path === location){
            isMatch = true;
        }        
    });
	return isMatch;

}

const isCheckoutUrl = (url) => {
    const regex = /\bcheckout\b/i;
    return regex.test(url);
}

export { CheckUrlMatch, isCheckoutUrl };