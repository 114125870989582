import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { trackCustomEvent } from "../Config/DataDog";

const { REACT_APP_LOGIN } = process.env;

const appendToUrl = (url, key, value) => {
    if ((url).indexOf('?') == -1) {
        url = url + '?';
    }
    else {
        url = url + '&';
    }
    url = url + key + '=' + value;
    return url;
}

const isInAppBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /FBAN|FBAV|Instagram/.test(userAgent);
}

const redirectToExternalBrowserMobile = (history, urlCallback, modo, sitioId, path) => {
    if (!isInAppBrowser()) return;
    let urlRedirect = "/login/redireccion-usuarios-meta";
    const urlIngresar = REACT_APP_LOGIN + `login/${path}/${modo}/${sitioId}`;

    urlRedirect = addCallback(urlRedirect, urlCallback, urlIngresar);
    history.replace(urlRedirect);
}

const addCallback = (url, urlCallback, urlIngresar) => {
    const pathIngresar = appendToUrl(urlIngresar, 'callback', window.btoa(urlCallback));
    return appendToUrl(url, 'callback', window.btoa(pathIngresar));
}

const redirectAndroidToChrome = (url) => {
    const urlToExternalBrowser = `intent://${url.replace(/^https?:\/\//, '')}#Intent;scheme=https;package=com.android.chrome;end;`;
    trackCustomEvent("Auth0MobileUtils - redirectInnAppToExternalBrowser", false, { urlToExternalBrowser, url })
    window.location = urlToExternalBrowser;
}

const isIos = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /iPad|iPhone|iPod/.test(userAgent);
}

const useRedirectToExternalBrowser = (URLCallback, Modo, SitioId, path) => {
    const history = useHistory();

    useEffect(() => {
        try{
            redirectToExternalBrowserMobile(
                history,
                URLCallback,
                Modo,
                SitioId,
                path
            );
        }catch(error){
            console.error(error)
        }
        
    }, []);
};

export {
    redirectToExternalBrowserMobile,
    isIos,
    redirectAndroidToChrome,
    isInAppBrowser,
    useRedirectToExternalBrowser
}