import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_wizard-item.scss';

import { Text, Icon, Progress } from '@lana-ui/common';

const propTypes = {
	/**
	 * Texto asociado.
	 */
	text: PropTypes.string,
	/**
	 * Número del paso.
	 */
	stepNumber: PropTypes.number,
	/**
	 * Clases adicionales
	 */
	className: PropTypes.string,
};

const WizardItem = ({ text, stepNumber, mod, className, textNextStep, stepsLength, mobile }) => {
	const classes = classNames('wizard-item', className, mod, { '--mobile': mobile });

	return (
		<div className={classes}>
			{!mobile ? (
				<>
					<div className="step-n">
						{mod && mod.includes('done') ? (
							<Icon name="checkmark" className="checkmark-icon" />
						) : (
							String(stepNumber)
						)}
					</div>
					<Text size="--fourxs" className="--arial --font-regular">
						{text}
					</Text>
				</>
			) : (
				<>
					<Progress currentNumber={stepNumber} amountTotalNumbers={stepsLength} />

					<div className="wizard-item-paragraph">
						<Text size="--fourxs" className="--arial --font-regular step-text">
							{text}
						</Text>

						{textNextStep ? (
							<Text size="--fivexs" className="--arial --font-regular step-next">
								Próximo paso: {textNextStep}
							</Text>
						) : (
							<Text size="--fivexs" className="--arial --font-regular step-next">
								Último paso
							</Text>
						)}
					</div>
				</>
			)}
		</div>
	);
};

WizardItem.propTypes = propTypes;

export default WizardItem;
