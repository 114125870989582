import React from "react";
import { useField } from "formik";
import { Input } from "@lana-ui/common";
import _handleStatusFormik from "../../../Helpers/StatusFormik";

const InputFormik = (props) => {
  const [field, meta, helpers] = useField(props);
  // Creo un objeto donde le paso las props de formik y las props del Input para enviarlas al state del mismo
  const obj = { ...meta, ...props };
  return (
    <Input
      {...field}
      {...props}
      state={_handleStatusFormik(obj)}
      ref={props.r}
    />
  );
};

export default InputFormik;
