import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_main-login.scss';

const propTypes = {
	/**
	 * Si es formulario o no
	 */
	form: PropTypes.bool,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const MainLogin = ({ form, children, className }) => {
	const classes = classNames('lgn-main', className);

	return (
		<>
			{form ? (
				<form className={classes}>{children}</form>
			) : (
				<section className={classes}>{children}</section>
			)}
		</>
	);
};

MainLogin.propTypes = propTypes;

export default MainLogin;
