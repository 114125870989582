import ModosUrl from '../Models/ModosUrl';
import SitiosId from '../Models/SitiosId';
/**
 * Recibimos el modo, sitioId y verificamos si estos pertenecen a signwall.
 * @param {String} modo
 * @param {Number} sitioId
 * @returns {Boolean}
 */
export const comeFromSignwall = (modo, sitioId) => {
    return (
        modo === ModosUrl.signwall &&
        (sitioId === SitiosId.webSignwallLN ||
            sitioId === SitiosId.webSignwallCanchallena)
    );
};
/**
 * Recibimos el callback y devolvemos la url apuntando a el wall de gracias.
 * @param {String} urlCallback
 * @returns {String}
 */
export const updateURLToThankWall = (urlCallback) => {
    const thankWall = 'suscripcion/S/1';
    const { REACT_APP_SUSCRIPCIONES } = process.env;
    const returnData = `${REACT_APP_SUSCRIPCIONES}${thankWall}?callback=${window.btoa(urlCallback)}`;
    return returnData;
};
