import * as storage from "./LocalStorage"
import StorageManagerConstants from "../Models/StorageManagerConstants"

const getCallbackEmailVerification = () => {
    if (storage.checkIfExists(StorageManagerConstants.CALLBACK_EMAIL_VERIFICATION_KEY)) {
        const itemCallbackEmailVerification = storage.checkExpiration(StorageManagerConstants.CALLBACK_EMAIL_VERIFICATION_KEY, StorageManagerConstants.TENANT_EXPIRATION_DAYS)
        return itemCallbackEmailVerification && itemCallbackEmailVerification.value;
    }
    return null;
}
const setCallbackEmailVerification = (value) => {
    storage.setExpiredItemByDays(StorageManagerConstants.CALLBACK_EMAIL_VERIFICATION_KEY, value, StorageManagerConstants.TENANT_EXPIRATION_DAYS)
}
const deleteCallbackEmailVerification = () => {
    storage.removeItem(StorageManagerConstants.CALLBACK_EMAIL_VERIFICATION_KEY);
}

const getCallbackResetPassword = () => {
    if (storage.checkIfExists(StorageManagerConstants.CALLBACK_RESET_PASSWORD_KEY)) {
        const itemCallbackResetPassword = storage.checkExpiration(StorageManagerConstants.CALLBACK_RESET_PASSWORD_KEY, StorageManagerConstants.TENANT_EXPIRATION_DAYS);
        return itemCallbackResetPassword && itemCallbackResetPassword.value
    }
    return null;
}
const setCallbackResetPassword = (value) => {
    storage.setExpiredItemByDays(StorageManagerConstants.CALLBACK_RESET_PASSWORD_KEY, value, StorageManagerConstants.TENANT_EXPIRATION_DAYS)
}
const deleteCallbackResetPassword = () => {
    storage.removeItem(StorageManagerConstants.CALLBACK_RESET_PASSWORD_KEY);
}
/**
 * Elimina del storage el valor de origen_id si este existe.
 */
const deleteSitioId = () => {
    if(storage.checkIfExists(StorageManagerConstants.SITIO_ID_KEY)){
        storage.removeItem(StorageManagerConstants.SITIO_ID_KEY);
    }
}
/**
 * Elimina del storage el valor de modo si este existe.
 */
const deleteModo = () => {
    if(storage.checkIfExists(StorageManagerConstants.MODO_ID_KEY)){
        storage.removeItem(StorageManagerConstants.MODO_ID_KEY);
    }
}

/**
 * Borra si existe en el storage origen_id y guarda el valor obtenido en value.
 * @param {String} value
 */
const setSitioId = (value) => {
    deleteSitioId();
    storage.setItem(StorageManagerConstants.SITIO_ID_KEY, value)
}

/**
 * Borra si existe en el storage modo y guarda el valor obtenido en value.
 * @param {String} value
 */
const setModo = (value) => {
    deleteModo();
    storage.setItem(StorageManagerConstants.MODO_ID_KEY, value)
}

/**
 * Se busca el valor de sitio_id en el storage y te lo devuelve como un numero.
 * @returns {Number | Null}
 */
const getSitioId = () =>{
    let sitioIdItem = '';
    let sitioIdNumber;

    if (storage.checkIfExists(StorageManagerConstants.SITIO_ID_KEY)) {
        sitioIdItem = storage.getItem(StorageManagerConstants.SITIO_ID_KEY);
        sitioIdNumber = parseInt(sitioIdItem, 10)
    }

    if(!isNaN(sitioIdNumber)){
        return sitioIdNumber
    }

    return null;
}

/**
 * Se busca el valor de modo en el storage y lo devuelve.
 * @returns {String | Null}
 */
const getModo = () =>{
    if (storage.checkIfExists(StorageManagerConstants.MODO_ID_KEY)) {
        const modoItem = storage.getItem(StorageManagerConstants.MODO_ID_KEY)
        return modoItem;
    }
    
    return null;
}

export {
    getCallbackEmailVerification,
    setCallbackEmailVerification,
    deleteCallbackEmailVerification,
    getCallbackResetPassword,
    setCallbackResetPassword,
    deleteCallbackResetPassword,
    setSitioId,
    setModo,
    deleteSitioId,
    deleteModo,
    getSitioId,
    getModo
};