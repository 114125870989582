import React from "react";
const TwitterFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 18"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M21.162 2.656a8.384 8.384 0 01-2.402.658A4.196 4.196 0 0020.6 1c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 00-7.126 3.814 11.874 11.874 0 01-8.62-4.37 4.168 4.168 0 00-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 01-1.894-.523v.052a4.185 4.185 0 003.355 4.101 4.21 4.21 0 01-1.89.072A4.185 4.185 0 006.97 13.65a8.394 8.394 0 01-6.191 1.732 11.83 11.83 0 006.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.495 8.495 0 002.087-2.165l-.001-.001z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default TwitterFilled;
