import React from "react";
const ClubFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M3 3h18a1 1 0 011 1v10H2V4a1 1 0 011-1zM2 15.989h20V20a1 1 0 01-1 1H3a1 1 0 01-1-1v-4.011z"
      ></path>
    </svg>
  );
};

export default ClubFilled;
