import React from "react";
const ShareAndroid = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M13.12 17.025l-4.199-2.29a4 4 0 110-5.465l4.2-2.29a4 4 0 11.96 1.755l-4.2 2.29a4.008 4.008 0 010 1.954l4.198 2.29a4 4 0 11-.959 1.755zM6 14.002a2 2 0 100-4 2 2 0 000 4zm11-6a2 2 0 100-4 2 2 0 000 4zm0 12a2 2 0 100-4 2 2 0 000 4z"
      ></path>
    </svg>
  );
};

export default ShareAndroid;
