import React from "react";
const Direction = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M12 3.515L3.515 12 12 20.485 20.485 12 12 3.515zm.707-2.122l9.9 9.9a1 1 0 010 1.414l-9.9 9.9a1 1 0 01-1.414 0l-9.9-9.9a1 1 0 010-1.414l9.9-9.9a1 1 0 011.414 0zM13 10V7.5l3.5 3.5-3.5 3.5V12h-3v3H8v-4a1 1 0 011-1h4z"
      ></path>
    </svg>
  );
};

export default Direction;
