import React from "react";
const EditFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M7.536 17.707a1 1 0 01-.707.293H4a1 1 0 01-1-1v-2.829a1 1 0 01.293-.707L14.435 2.322a1 1 0 011.414 0l2.829 2.829a1 1 0 010 1.414L7.536 17.707zM3 21a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1z"
      ></path>
    </svg>
  );
};

export default EditFilled;
