const RegexValidator = {
    username: /^[A-Za-z0-9_.-]{4,24}$/,
    email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"\%]+\.)+[^<>()[\]\.,;:\s@\"\%]{2,})$/i,
    password: /^.{8,20}$/,
    name: /^[A-Za-zÀ-ÿ ]{2,50}$/,
    lastname: /^[A-Za-zÀ-ÿ ]{2,50}$/,
    confirmCode: /^[A-Za-z0-9]{3,24}$/,
    credencialClub: /^[0-9]{15}?/,
    credencialClub1: /^[0-9]{6}?/,
    credencialClub2: /^[0-9X]{8}?/,
    credencialClub3: /^[0-9]{2}?/,
    dni: /^[0-9]{7,8}$/,
    passport: /^[A-Za-z0-9]{6,10}?/,
    temporary: /loginTemporalKey/,
    pass: {
        'al menos un numero': /\d+/,
        'al menos una letra': /[a-zA-Z]+/,
        'minimo 8 caracteres': /^.{8,20}$/,
        'no tener caracteres': /'|"/,
    },
    dominiosPermitidos: RegExp(
        'lanacion.com|revistaohlala.com|localhost|newspaperdirect.com|lanacionar-la-nacion-ar-sandbox.cdn.arcpublishing.com'
    ),
};

export default RegexValidator;
