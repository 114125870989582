import React, { useState, useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { LoginLayout } from '@lana-ui/login';
import { Wizard } from '@lana-ui/common';
import { useWindowSize } from '../../Helpers/UseWindowSize';
import Error from '../../Components/Features/Error';
import RouteWithSubRoutes from '../../Routes/RouteWithSubRoutes';
import { steps } from '../../Config/CheckoutSteps';
import { useStore } from '../../Store/storeProvider';
import ModosUrl from '../../Models/ModosUrl';
import sitiosId from '../../Models/SitiosId';
import Error404 from '../Error404';
import { CheckUrlMatch } from '../../Helpers/UrlMatch';

function index({ routes }) {
    const [errorApi, setErrorApi] = useState(false);
    const [showArrowBack, setShowArrowBack] = useState(true);
    const [currentStep, setCurrentStep] = useState(1);
    const windowWidth = useWindowSize();
    const isMobile = windowWidth < 767;
    const { Config, User } = useStore();

    const urlMatch = CheckUrlMatch(routes, location.pathname);

    if (errorApi) return <Error />;

    useEffect(() => {
        const body = document.querySelector('body');
        if (Config.Modo !== ModosUrl.black) {
            body.classList.remove('background-black');
        } else {
            body.classList.add('background-black');
        }
    }, []);

    return (
        <LoginLayout
            showArrowBack={urlMatch && showArrowBack}
            checkout={Config.Modo === ModosUrl.paywall}
            black={Config.Modo === ModosUrl.black}
            club={User.SitioId == sitiosId.clubLN}
        >
            {Config.Modo === ModosUrl.paywall && (
                <Wizard
                    activeStep={currentStep}
                    steps={steps}
                    mobile={isMobile}
                />
            )}

            <Switch>
                {routes.map((route, i) => (
                    <RouteWithSubRoutes
                        key={i}
                        {...route}
                        setErrorApi={setErrorApi}
                        setShowArrowBack={setShowArrowBack}
                        setCurrentStep={setCurrentStep}
                    />
                ))}
                <Redirect from="/login" to="/auth0-login" />
                <Route>
                    <Error404 />
                </Route>
            </Switch>
        </LoginLayout>
    );
}

export default index;
