import React from "react";
const LikeFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M2.808 12.808l7.776 7.776a2 2 0 002.83-.002l7.765-7.788A6.5 6.5 0 0012 3.64a6.5 6.5 0 00-8.839.328c-2.419 2.42-2.533 6.272-.353 8.84z"
      ></path>
    </svg>
  );
};

export default LikeFilled;
