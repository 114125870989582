import React from "react";
const ArrowUp = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M12 10.828l-4.243 4.243a1 1 0 11-1.414-1.414l4.95-4.95a1 1 0 011.414 0l4.95 4.95a1 1 0 11-1.414 1.414L12 10.828z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ArrowUp;
