//Auth0
import Auth0Callback from '../Pages/Auth0/Auth0Callback';

//Login
import IndexLogIn from '../Pages/LogIn/Index';
import ValidarPin from '../Pages/LogIn/ValidarPin';
import ValidarPinGracias from '../Pages/LogIn/ValidarPinGracias';
import BuscarEmailConDni from '../Pages/LogIn/BuscarEmailConDni/BuscarEmailConDni';
import RedireccionUsuarios from '../Pages/LogIn/RedireccionUsuarios/RedireccionUsuarios';

import Error404 from '../Pages/Error404';
import Auth0Login from '../Pages/Auth0/Auth0Login';
import Auth0EmailConfirmation from '../Pages/Auth0/Auth0EmailConfirmation';
import Auth0Logout from '../Pages/Auth0/Auth0Logout';
import Auth0Register from '../Pages/Auth0/Auth0Register';
import Auth0LogoutCallback from '../Pages/Auth0/Auth0LogoutCallback';
import Auth0CallbackResetPassword from '../Pages/Auth0/Auth0CallbackResetPassword';

const Routes = [
    {
        path: '/auth0-callback',
        component: Auth0Callback
    },
    {
        path: '/auth0-login',
        component: Auth0Login
    },
    {
        path: '/auth0-emailConfirmation',
        component: Auth0EmailConfirmation
    },
    {
        path: '/auth0-logout',
        component: Auth0Logout,
    },
    {
        path: '/auth0-logout-callback',
        component: Auth0LogoutCallback
    },
    {
        path: '/auth0-callback-reset-password',
        component: Auth0CallbackResetPassword
    },
    {
        path: '/login',
        component: IndexLogIn,
        routes: [
            {
                path: '/login/registracion',
                component: Auth0Register,
            },
            {
                path: '/login/validar-pin',
                component: ValidarPin,
            },
            {
                path: '/login/validar-pin-gracias',
                component: ValidarPinGracias,
            },
            {
                path: '/login/buscar-mail-dni', //TODO: Revisar nombre para que no sea muy descriptivo
                component: BuscarEmailConDni,
            },
            {
                path: '/login/redireccion-usuarios-meta',
                component: RedireccionUsuarios,
            },
        ],
    },
    
    {
        path: '/not-found',
        component: Error404,
    },
];

export default Routes;
