import React from "react";
const Qr = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      {...r}
    >
      <path
        fill="#272727"
        d="M13 14v-1h-3v-3h3v2h2v2h-1v2h-2v2h-2v-3h2v-1h1zm5 4h-4v-2h2v-2h2v4zM0 0h8v8H0V0zm2 2v4h4V2H2zm8-2h8v8h-8V0zm2 2v4h4V2h-4zM0 10h8v8H0v-8zm2 2v4h4v-4H2zm13-2h3v2h-3v-2zM3 3h2v2H3V3zm0 10h2v2H3v-2zM13 3h2v2h-2V3z"
      ></path>
    </svg>
  );
};

export default Qr;
