import React, { useState } from 'react';
import useGetMailWithDni from '../../../Hooks/useGetMailWithDni';
import { ConfirmarMail } from './ConfirmarMail';
import { FormularioDni } from './FormularioDni';
import './_loginDni.scss';
import { useStore } from '../../../Store/storeProvider';

const BuscarEmailConDni = () => {
    const [formError, setFormError] = useState('');
    const [user, setUser] = useState({dni: "", email: "", tipoDeIngreso: ""});
    const { RecaptchaRef } = useStore();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const recaptchaData = await RecaptchaRef.current.executeAsync();

        validateFormData(user.dni);
        if (!!formError) {
            return;
        }

        try {
            const response = await useGetMailWithDni({dni: user.dni, recaptcha: recaptchaData});
            const data = response.response;
            setUser({ dni: data.Dni, email: data.Email, tipoDeIngreso: data.TipoDeIngreso });
        } catch (error) {
            setUser({ dni: "", email: "", tipoDeIngreso: "" });
            setFormError('No encontramos una cuenta con ese número de DNI');
        }
        RecaptchaRef.current.props.grecaptcha.reset();
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setUser({...user, dni: value});
        validateFormData(value);
    };

    const validateFormData = (value) => {
        if (
            value.length === 0 ||
            value.length < 7 ||
            value.length > 8 ||
            !value.match(/^\d+$/)
        ) {
            setFormError('Ingresá un número de DNI válido');
        } else {
            setFormError('');
        }
    };

    const comeBack = () =>{
        setUser({dni: "", email: "", tipoDeIngreso: ""});
        setFormError("");
    }

    if (!!user.email) {
        return (
            <ConfirmarMail user={user} comeBack={comeBack}/>
        );
    } 

    return (
        <FormularioDni handleChange={handleChange} handleSubmit={handleSubmit} user={user} formError={formError} />
    );
};

export default BuscarEmailConDni;