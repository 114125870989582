import React from "react";
const Whatsapp = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M5.253 16.494l.724.423A7.953 7.953 0 0010 18a8 8 0 10-6.916-3.976l.422.724-.653 2.401 2.4-.655zM.004 20l1.352-4.968A9.954 9.954 0 010 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10a9.953 9.953 0 01-5.03-1.355L.004 20zM6.391 5.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.378 4.378 0 01-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 00.367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 01.378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 01-.21.302 2.38 2.38 0 01-.33.288l-.125.09a5.044 5.044 0 01-.383.22 1.989 1.989 0 01-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 01-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 014.9 7.62a2.729 2.729 0 01.564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 01.371-.1l.001.001z"
      ></path>
    </svg>
  );
};

export default Whatsapp;
