import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_title.scss';

const propTypes = {
	/**
	 * Tag asociado al título.
	 */
	as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
	/**
	 * Tamaño del texto.
	 */
	size: PropTypes.string,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const defaultProps = {
	size: 'fourxs',
};

const Title = ({ as, size, children, className, ...r }) => {
	const Element = as || 'h4';
	const classes = classNames('title', className, `--${size}`);

	return (
		<Element className={classes} {...r}>
			{children}
		</Element>
	);
};

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
