import React from "react";
const Medium = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      {...r}
    >
      <path
        fill="#272727"
        d="M1 0h16a1 1 0 011 1v16a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm1 2v14h14V2H2zm12.3 10.94l.955.954v.05h-4.921v-.05l1.004-.954c.1-.1.15-.2.15-.351V6.664c0-.252 0-.603.051-.904l-3.314 8.285h-.05L4.76 6.412c-.05-.2-.1-.2-.15-.3v5.02c-.051.352 0 .653.15.955l1.356 1.807v.05H2.5v-.05l1.356-1.858c.15-.3.2-.652.15-.954V5.56c0-.251-.05-.553-.25-.753L2.851 3.55V3.5h3.515l2.912 6.478L11.84 3.5h3.415v.05l-.954 1.105c-.1.1-.15.251-.15.351v7.633c0 .1.05.251.15.301H14.3z"
      ></path>
    </svg>
  );
};

export default Medium;
