import React from "react";
const BulletDefault = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path fill="#5A5A5A" d="M16 12a4 4 0 11-8 0 4 4 0 018 0z"></path>
    </svg>
  );
};

export default BulletDefault;
