import React from 'react';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import useVerifySessionAuth0 from '../../Hooks/useVerifySessionAuth0';
import useLoginAuth0Handler from '../../Hooks/useLoginAuth0Handler';
import { useStore } from '../../Store/storeProvider';
import useValidateUserAuth0Handler from '../../Hooks/useValidateUserAuth0Handler';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuth0Cookies, getAuth0TokensFromCache, setAuth0TokensCookies, setOriginIdCookie } from '../../Helpers/Auth0CookieManager';
import getSegmentAndGaFromCookies from '../../Helpers/getSegmendAndGaFromCookies';
import * as storage from '../../Helpers/Auth0StorageManager';
import { useRedirectToExternalBrowser } from '../../Helpers/RedirectMeta';

const Auth0Login = () => {
    const [loginAuth0Handler] = useLoginAuth0Handler();
    const { Config } = useStore();
    const [validateUser] = useValidateUserAuth0Handler();
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const { segment, ga } = getSegmentAndGaFromCookies();

    useRedirectToExternalBrowser(
        Config.URLCallback,
        Config.Modo,
        Config.SitioId,
        "ingresar"
    );

    const loginHandler = async (isVerified) => {
        storage.setSitioId(Config.SitioId);
        storage.setModo(Config.Modo);
        const userHaveCookies = getAuth0Cookies();
        if (userHaveCookies) {
            await validateUser(Config.URLCallback);
        }
        else if (isAuthenticated) {
            if (isVerified) {
                const { finalRefreshToken, idToken, accessToken } = getAuth0TokensFromCache();
                setAuth0TokensCookies(accessToken, finalRefreshToken, idToken)
                await loginAuth0Handler();
            } else if (isVerified === false) {
                storage.setCallbackEmailVerification(Config.URLCallback);
                window.location = `${window.location.origin}/auth0-emailConfirmation`
            }
        }
        else {
            setOriginIdCookie(Config.SitioId, Config.URLCallback);
            storage.setCallbackResetPassword(Config.URLCallback);
            loginWithRedirect({
                callbackURL: `${window.btoa(Config.URLCallback)}`,
                modo: `${Config.Modo}`,
                origen: `${Config.SitioId}`,
                prompt: 'login',
                redirectUri: `${window.location.origin}/auth0-callback/?callback=${window.btoa(Config.URLCallback)}`,
                segment: segment,
                ga: ga
            });
        }
    }
    const [isLoadingProcess] = useVerifySessionAuth0(loginHandler);

    return (
        <div className='callback-view containerSpinner'>
            <LoadingSpinner isLoading={isLoadingProcess}>
            </LoadingSpinner>
        </div>
    );
}

export default Auth0Login;