import React from "react";
const Car = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 18"
      {...r}
    >
      <path
        fill="#272727"
        d="M17 16H3v1a1 1 0 01-1 1H1a1 1 0 01-1-1V7l2.48-5.788A2 2 0 014.32 0h11.36a2 2 0 011.838 1.212L20 7v10a1 1 0 01-1 1h-1a1 1 0 01-1-1v-1zm1-7H2v5h16V9zM2.176 7h15.648l-2.143-5H4.32L2.177 7h-.001zM4.5 13a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm11 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
      ></path>
    </svg>
  );
};

export default Car;
