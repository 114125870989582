import React from "react";
const Settings = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M2 12c0-.518.113-1.313.204-1.867.064-.389.359-.694.733-.818.692-.23 1.764-.673 2.134-1.314.347-.601.237-1.648.1-2.366-.087-.455.102-.932.503-1.163L8.64 2.758c.401-.232.91-.157 1.26.146.553.478 1.405 1.098 2.099 1.098.694 0 1.546-.62 2.098-1.098.352-.303.86-.378 1.261-.146l2.965 1.713c.402.232.59.71.504 1.165-.137.717-.248 1.765.1 2.366.37.642 1.442 1.085 2.135 1.314.374.124.67.43.733.818.09.554.204 1.348.204 1.866s-.113 1.312-.204 1.867c-.064.389-.36.694-.733.818-.692.229-1.764.672-2.135 1.314-.347.6-.237 1.648-.1 2.366.087.455-.102.932-.503 1.163l-2.966 1.714c-.401.232-.91.157-1.26-.146-.553-.478-1.405-1.098-2.099-1.098-.694 0-1.546.62-2.099 1.098-.35.303-.859.378-1.26.146l-2.966-1.713c-.4-.232-.59-.71-.503-1.165.137-.717.248-1.765-.1-2.366-.37-.641-1.442-1.085-2.135-1.314-.374-.124-.67-.43-.733-.818C2.113 13.312 2 12.518 2 12zm5.368 6.524l1.297.75S10.769 17.999 12 18c1.26 0 3.335 1.274 3.335 1.274l1.297-.75s-.052-2.459.564-3.524c.615-1.066 2.77-2.25 2.77-2.25v-1.5S17.81 10.066 17.195 9c-.616-1.065-.564-3.524-.564-3.524l-1.297-.75S13.23 6.001 12 6c-1.23 0-3.335-1.274-3.335-1.274l-1.297.75S7.42 7.935 6.804 9c-.615 1.066-2.77 2.25-2.77 2.25v1.5S6.19 13.934 6.805 15c.629 1.091.563 3.524.563 3.524zM12 15a3 3 0 110-6 3 3 0 010 6zm0-2a1 1 0 100-2 1 1 0 000 2z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Settings;
