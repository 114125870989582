import React from "react";
const RainyCloudy = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M21.28 10.81a4.33 4.33 0 00-.28-.46 6.5 6.5 0 10-10.89-7.07c-.23-.06-.46-.11-.7-.15a7.88 7.88 0 00-4 .31 8 8 0 00-5 9.89 8.11 8.11 0 002.11 3.45 8 8 0 003.54 2v-2.12a6 6 0 01-2.65-1.87 6 6 0 01-.86-6.17A6 6 0 0114 11v.05a3.5 3.5 0 013.15-1 3.5 3.5 0 011 6.52 3.551 3.551 0 01-1.65.43H16v2h.5a5.511 5.511 0 002.22-.48 5.4 5.4 0 001.84-1.33A5.452 5.452 0 0022 13a5.31 5.31 0 00-.72-2.19zm-1.73-1.88a5.57 5.57 0 00-1.82-.79 5.65 5.65 0 00-2.28-.05 7.94 7.94 0 00-2.37-3.27c-.34-.282-.7-.536-1.08-.76a4.5 4.5 0 117.57 4.87h-.02zM10 17H8v4h2v-4zm2 3h2v4h-2v-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default RainyCloudy;
