import React from "react";
const NoWifi = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M12 18c.238 0 .47.028.691.08.721.17.817 1.054.352 1.63a1.341 1.341 0 01-2.086-.001c-.466-.576-.37-1.459.35-1.629.226-.053.458-.08.693-.08zM2.1 2.1a1 1 0 011.415 0l16.263 16.264a1 1 0 01-1.414 1.414l-4.473-4.473A5.992 5.992 0 0012 15c-1.07 0-2.073.28-2.943.77-.481.271-1.109.213-1.456-.217-.346-.43-.283-1.064.186-1.355a7.963 7.963 0 013.809-1.188L9 10.414a10.968 10.968 0 00-3.106 1.435c-.46.307-1.088.245-1.436-.185-.347-.43-.282-1.062.172-1.376.865-.596 1.8-1.086 2.786-1.458L5.132 6.545a15.91 15.91 0 00-2.388 1.403c-.45.32-1.079.256-1.426-.173-.347-.43-.282-1.062.166-1.385.684-.494 1.405-.94 2.157-1.335l-1.54-1.54a1 1 0 010-1.415zm12.953 8.329a2.21 2.21 0 01-.957-.548L12.37 8.155A.528.528 0 0012 8c2.736 0 5.274.845 7.37 2.289.454.313.518.946.171 1.376-.347.43-.975.492-1.435.184a10.962 10.962 0 00-3.054-1.42zM12 3c3.926 0 7.559 1.257 10.516 3.39.448.323.513.955.166 1.384-.347.43-.976.493-1.426.173A15.933 15.933 0 0012 5c-.694 0-1.378.044-2.049.13a1.088 1.088 0 01-.91-.303c-.577-.578-.297-1.553.512-1.662A18.19 18.19 0 0112 3z"
      ></path>
    </svg>
  );
};

export default NoWifi;
