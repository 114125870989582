import React from "react";
const Home = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M19 21H5a1 1 0 01-1-1v-9h-.413c-.914 0-1.35-1.125-.673-1.74l8.413-7.648a1 1 0 011.346 0l8.413 7.648c.676.615.241 1.74-.673 1.74H20v9c0 .552-1 1-1 1zm-6-2h5V9.157l-6-5.454-6 5.454V19h5v-5a1 1 0 112 0v5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Home;
