const Usuario = {
  UsuarioId: "",
  AccessToken: "",
  OrigenLoginTipoId: "",
  TipoAsociarCredencial: "",
  xToken: "",
  xValue: "",
  xKey: "", // Este par�metro solo va para requests de token temporal
  Retorno: "",
  UsuarioUsuario: "",
  Avatar: "avatar-0.jpg",
  Email: "",
  EmailCodificado: "",
  UsuarioDetalleNombre: "",
  UsuarioDetalleApellido: "",
  Clave: "",
  Credencial: "",
  Credenciales: [],
  tipoDocumento: "",
  dni: "",
  tipoParticipacion: "",
  UserAgent: "",
  Recaptcha: "",
  Codigo: "",
  SitioId: 1,
  EstadoId: "",
  Ip: "",
  Guid: "",
  UsuarioFacebookId: "",
  FbName: "",
  FbFirstName: "",
  FbLastName: "",
  FbGender: "",
  FbLocale: "",
  FbAgeRange: "",
  FbEmail: "",
  FbBirthday: "",
  FbHometown: "",
  FbLocation: "",
  UsuarioGmailId: "", 
  GName: "",
  GFamilyName: "",
  GGivenName: "",
  GBirthday: "",
  GGender: "",
  GCurrentLocation: "",
  GMail: "",
  IsNewUser: false,
  NeedRecaptchaValidation: false,
  HayCookie: 0,
  Experiments: [],
  Provisorio: false,
  AppleId: false,
  isRecoveringPw: false,
};

export default Usuario;
