import setTokenXvalue from "../Helpers/setTokenXvalue";
import { useAuth0 } from '@auth0/auth0-react';
import ApiService from '../Services/ApiService';
import { useDispatch, useStore } from '../Store/storeProvider';
import Me from '../Services/UsuarioService';
import useMeHandler from "./useMeHandler";
import useLogout from '../Services/_LogoutService';
import { getAuth0TokensFromCache } from '../Helpers/Auth0CookieManager'
import { trackCustomEvent } from "../Config/DataDog";
import { deleteCallbackEmailVerification, getCallbackEmailVerification } from "../Helpers/Auth0StorageManager";

const useLoginAuth0Handler = () => {
    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const { REACT_APP_APILOGIN } = process.env;
    const { Config, User } = useStore();
    const [MeHandler] = useMeHandler();
    const handleLogout = useLogout();

    const loginAuth0Handler = async () => {
        try {
            //Obtengo tokens pegandole a Auth0
            const claims = await getIdTokenClaims();
            const tokens = getAuth0TokensFromCache();

            const getResponseValues = (res) => {
                if (!res) return false

                let arr = res.split("|")

                let responseValues = {
                    token: arr[0],
                    xtoken: arr[1],
                    redirectValue: arr[2]
                }
                return responseValues;
            };

            //Armo payload  para pegarle al AuthenticateUser de API-LOGIN
            const authenticateUserPayload = {
                id_token: claims.__raw,
                refresh_token: tokens.finalRefreshToken
            };

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAccessTokenSilently()}`,
                'X-Token': tokens.idToken
            };
            const responseService = await ApiService.postWithHeaders(
                `${REACT_APP_APILOGIN}Auth0/AuthenticateUser`,
                authenticateUserPayload,
                headers
            )

            if (responseService?.code === "0000") {
                let userCallbackEmailVerification = getCallbackEmailVerification();
                if (userCallbackEmailVerification == null) {
                    userCallbackEmailVerification = Config.URLCallback
                } else {
                    deleteCallbackEmailVerification();
                }
                //Obtengo los tokens de la respuesta de API-LOGIN
                let responseValues = getResponseValues(responseService.response);
                //Los seteo en el storage  y cookies
                setTokenXvalue(responseValues?.token, responseValues?.xtoken, dispatch);
                //Le vuelvo  a pegar a API-LOGIN para el /Me()
                const responseMe = await Me();
                await MeHandler(responseMe, User, userCallbackEmailVerification);
            }
            else {
                trackCustomEvent("logout", true, { message: `useLoginAuth0Handler - ${error.message}` })
                handleLogout();
            }

        } catch (error) {
            trackCustomEvent("logout", true, { message: `useLoginAuth0Handler - ${error.message}` })
            handleLogout();
        }
    };
    return [loginAuth0Handler];
};
export default useLoginAuth0Handler;