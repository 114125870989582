import React from "react";
const FlagFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M5 16v5a1 1 0 11-2 0V4a1 1 0 011-1h8.382a1 1 0 01.894.553L14 5h6a1 1 0 011 1v11a1 1 0 01-1 1h-6.382a1 1 0 01-.894-.553L12 16H5z"
      ></path>
    </svg>
  );
};

export default FlagFilled;
