import React from "react";
const EmailFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 18"
      {...r}
      style={{ backgroundColor: "white" }}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M1 0h18a1 1 0 011 1v16a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm3.41 3.885a1 1 0 00-1.294 1.524l6.304 5.353a1 1 0 001.301-.006l6.177-5.345a1 1 0 10-1.309-1.512l-5.528 4.784L4.41 3.885z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default EmailFilled;
