import React from "react";
const SettingsFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M5.334 4.545a9.99 9.99 0 013.542-2.048A3.993 3.993 0 0012 3.999a3.993 3.993 0 003.124-1.502 9.99 9.99 0 013.542 2.048 3.993 3.993 0 00.262 3.454 3.993 3.993 0 002.863 1.955c.28 1.349.28 2.741 0 4.09-1.16.178-2.23.86-2.863 1.955a3.993 3.993 0 00-.262 3.455 9.99 9.99 0 01-3.542 2.047A3.993 3.993 0 0012 20a3.993 3.993 0 00-3.124 1.502 9.99 9.99 0 01-3.542-2.047A3.993 3.993 0 005.072 16a3.993 3.993 0 00-2.863-1.954c-.28-1.35-.28-2.742 0-4.091A3.993 3.993 0 005.072 8a3.993 3.993 0 00.262-3.454v-.001zM13.5 14.597a3 3 0 10-2.966-5.216 3 3 0 002.966 5.216z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default SettingsFilled;
