import React from "react";
const Swap = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M15.293 1.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L17.586 7H7.556c-.638 0-1.276.284-1.767.836C5.295 8.392 5 9.169 5 10v2a1 1 0 11-2 0v-2c0-1.29.455-2.549 1.294-3.493C5.137 5.56 6.307 5 7.556 5h10.03l-2.293-2.293a1 1 0 010-1.414zm-6.586 12a1 1 0 010 1.414L6.414 17h10.03c.638 0 1.276-.284 1.767-.836.494-.556.789-1.333.789-2.164v-2a1 1 0 112 0v2c0 1.29-.455 2.549-1.294 3.493-.843.948-2.013 1.507-3.262 1.507H6.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Swap;
