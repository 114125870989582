import React from "react";
const SectionFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M5.002 3H9a2 2 0 012 2v4a2 2 0 01-2 2l-4.058-.001-.175-.012A2 2 0 013 9l.001-4a2 2 0 012-2zm0 10H9a2 2 0 012 2v4a2 2 0 01-2 2l-4.058-.001-.175-.012A2 2 0 013 19l.001-4a2 2 0 012-2zM19 3h-3.998a2 2 0 00-2 2L13 9a2 2 0 001.767 1.987l.175.012L19 11a2 2 0 002-2V5a2 2 0 00-2-2zm-3.998 10H19a2 2 0 012 2v4a2 2 0 01-2 2l-4.058-.001-.175-.012A2 2 0 0113 19l.001-4a2 2 0 012-2z"
      ></path>
    </svg>
  );
};

export default SectionFilled;
