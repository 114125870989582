import React from "react";
import { Footer } from "@lana-ui/login";
import { Text, Link } from "@lana-ui/common";

const TerminosYCondiciones = ({text, privacy}) => {
  return (
    <Footer>
      <Text as="p" size="--fivexs">
        {text} <br />
        <Link
          href="https://www.contacto.lanacion.com.ar/tyc"
          text="términos y condiciones"
        />{" "}
        {privacy && <>
        y la{" "}
        <Link
          href="https://www.contacto.lanacion.com.ar/privacidad"
          text="política de privacidad"
        /></>}
      </Text>
    </Footer>
  );
};

export default TerminosYCondiciones;
