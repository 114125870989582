import React from "react";
const Forbid = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16.001A8 8 0 0012 20zm4.353-12.13c.337.355.285.906-.06 1.251L9.12 16.293c-.345.345-.896.397-1.25.06a6.045 6.045 0 01-.224-.224c-.337-.354-.285-.905.06-1.25l7.172-7.172c.345-.345.896-.397 1.25-.06.077.072.152.147.224.223z"
      ></path>
    </svg>
  );
};

export default Forbid;
