import React from "react";
const StormCloudy = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M22 10.35c.103.147.196.301.28.46A5.31 5.31 0 0123 13a5.452 5.452 0 01-1.44 4.19 5.4 5.4 0 01-1.84 1.33 5.511 5.511 0 01-2.22.48H17v-2h.5a3.551 3.551 0 003.124-1.95 3.5 3.5 0 00-2.474-5 3.5 3.5 0 00-3.15 1V11A6 6 0 003.55 8.62a6 6 0 00.86 6.17 6 6 0 002.65 1.87v2.12a8 8 0 01-3.54-2 8.11 8.11 0 01-2.11-3.45 8 8 0 015-9.89 7.88 7.88 0 014-.31c.24.04.47.09.7.15A6.5 6.5 0 1122 10.35zm-3.27-2.21a5.57 5.57 0 011.82.79h.02A4.5 4.5 0 1013 4.06c.38.224.74.478 1.08.76a7.94 7.94 0 012.37 3.27 5.65 5.65 0 012.28.05zM9 19.91L13.38 14v4.09H16L11.63 24v-4.09H9z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default StormCloudy;
