import React from 'react';
import './BuscarEmailConDni/_loginDni.scss';

export const TermsLogin = () => {
    return (
        <footer className="container-terms">
            <span>Al continuar, confirmo que he leído y aceptado los </span>
            <a
                id="target-blank"
                target="_blank"
                href="https://www.contacto.lanacion.com.ar/tyc"
                title="Ir a Términos y Condiciones"
            >
                Términos y Condiciones
            </a>
            <span> y las </span>
            <a
                id="target-blank"
                target="_blank"
                href="https://www.contacto.lanacion.com.ar/privacidad"
                title="Ir a Políticas de Privacidad"
            >
                Políticas de Privacidad
            </a>
            <span> de LA NACION y Club LA NACION.</span>
        </footer>
    );
};

