import React from "react";
const DecoHome = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 19"
      {...r}
    >
      <path
        fill="#272727"
        d="M16 17V7.799L9 2.277 2 7.799V17h14zm2 1a1 1 0 01-1 1H1a1 1 0 01-1-1V7.314a1 1 0 01.38-.785l8-6.311a1 1 0 011.24 0l8 6.31a1 1 0 01.38.786V18zM4 10h2a3 3 0 106 0h2a5 5 0 01-10 0z"
      ></path>
    </svg>
  );
};

export default DecoHome;
