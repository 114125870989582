import React from "react";
const CheckboxBlank = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M18 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2zM6 2a4 4 0 00-4 4v12a4 4 0 004 4h12a4 4 0 004-4V6a4 4 0 00-4-4H6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default CheckboxBlank;
