import React from "react";
const EmailOpen = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M.243 5.854L9.49.31a1 1 0 011.029 0l9.238 5.545a.5.5 0 01.243.429V19a1 1 0 01-1 1H1a1 1 0 01-1-1V6.283a.5.5 0 01.243-.429zM2 7.133V18h16V7.132l-7.996-4.8L2 7.132v.001zm8.06 5.565l5.296-4.463 1.288 1.53-6.57 5.537-6.71-5.53 1.272-1.544 5.424 4.47z"
      ></path>
    </svg>
  );
};

export default EmailOpen;
