import React from "react";
const MediumFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
      {...r}
    >
      <path
        fill="#272727"
        d="M1 0h16a1 1 0 011 1v16a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm13.3 12.94c-.1-.05-.15-.2-.15-.301V5.006c0-.1.05-.25.15-.351l.955-1.105V3.5H11.84L9.28 9.978 6.366 3.5H2.852v.05l.903 1.256c.201.2.251.502.251.753v5.523c.05.302 0 .653-.15.954L2.5 13.894v.05h3.616v-.05L4.76 12.087a1.546 1.546 0 01-.15-.954V6.11c.05.1.1.1.15.301l3.414 7.633h.05L11.54 5.76c-.05.3-.05.652-.05.904v5.925c0 .15-.05.25-.15.351l-1.005.954v.05h4.921v-.05l-.954-.954H14.3z"
      ></path>
    </svg>
  );
};

export default MediumFilled;
