import React from "react";

const ConfirmacionSinTarjetaClub = () => {
  return (
    <svg
      width="199"
      height="161"
      viewBox="0 0 199 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1748_33261)">
        <path
          d="M142.547 145.732L157.741 14.791"
          stroke="#0250C9"
          stroke-miterlimit="10"
        />
        <path
          d="M150.686 39.9028C150.501 39.9081 150.317 39.8725 150.147 39.7986C149.977 39.7247 149.826 39.6143 149.704 39.4752C149.582 39.3361 149.492 39.1719 149.44 38.9941C149.389 38.8164 149.378 38.6296 149.408 38.447L150.491 29.6026C150.548 29.2062 150.744 28.8428 151.044 28.5764C151.344 28.3101 151.728 28.158 152.129 28.1468V28.1468C152.314 28.1424 152.497 28.1786 152.666 28.2528C152.836 28.327 152.987 28.4374 153.109 28.5763C153.231 28.7151 153.32 28.879 153.372 29.0564C153.424 29.2337 153.435 29.4202 153.407 29.6026L152.323 38.447C152.266 38.8434 152.07 39.2069 151.771 39.4732C151.471 39.7395 151.087 39.8917 150.686 39.9028V39.9028Z"
          stroke="#0250C9"
          stroke-miterlimit="10"
        />
        <path
          d="M148.799 55.9028C148.614 55.9072 148.43 55.8711 148.261 55.7969C148.091 55.7226 147.94 55.6122 147.818 55.4734C147.697 55.3345 147.607 55.1706 147.555 54.9933C147.504 54.816 147.492 54.6295 147.52 54.4471L148.609 45.6027C148.666 45.2062 148.862 44.8428 149.162 44.5765C149.461 44.3101 149.845 44.158 150.246 44.1468V44.1468C150.431 44.1424 150.615 44.1786 150.784 44.2528C150.954 44.327 151.105 44.4375 151.226 44.5763C151.348 44.7152 151.438 44.879 151.49 45.0564C151.541 45.2337 151.553 45.4202 151.525 45.6027L150.436 54.4471C150.382 54.8447 150.187 55.2098 149.886 55.4767C149.586 55.7436 149.2 55.8947 148.799 55.9028V55.9028Z"
          stroke="#0250C9"
          stroke-miterlimit="10"
        />
        <g filter="url(#filter0_d_1748_33261)">
          <path
            d="M127.773 153.284H73.4997C69.5053 153.284 66.6893 149.894 67.1786 145.706L81.5184 21.2863C82.0177 16.9888 85.6326 13.125 89.612 12.6513L143.885 6.22491C147.88 5.75128 150.701 9.05175 150.212 13.5936L135.867 145.258C135.382 149.695 131.757 153.284 127.773 153.284Z"
            fill="#0250C9"
          />
        </g>
        <path
          d="M130.969 100.408L79.7708 101.903C78.2729 101.943 77.2094 100.682 77.3891 99.0816L78.6524 88.1133C78.7388 87.3374 79.0877 86.6141 79.6414 86.063C80.1952 85.5118 80.9206 85.1658 81.698 85.0821L132.896 82.8386C134.394 82.7688 135.462 84.085 135.277 85.7701L134.019 97.2918C133.834 98.9669 132.471 100.368 130.969 100.408Z"
          fill="#85B4FE"
        />
        <path
          d="M83.6615 91.6303L83.6659 91.6082L83.6683 91.5859C83.7152 91.1422 83.9137 90.7282 84.2304 90.4133C84.5438 90.1018 84.9542 89.9061 85.3939 89.8585L97.8253 89.3606L97.8261 89.3605C98.5432 89.3307 99.1236 89.9251 99.021 90.8552L99.0205 90.86C98.9754 91.3076 98.7773 91.7262 98.4592 92.0453C98.1449 92.3605 97.7324 92.5595 97.2898 92.6097L84.8685 93.0626C84.6891 93.0666 84.5111 93.0306 84.3475 92.9571C84.1832 92.8832 84.0376 92.7735 83.9215 92.6359L83.5394 92.9584L83.9215 92.6359C83.8054 92.4983 83.7218 92.3366 83.6768 92.1626C83.6319 91.9886 83.6266 91.8067 83.6615 91.6303Z"
          fill="#126FFF"
          stroke="#126FFF"
        />
        <path
          d="M83.0362 96.7346L93.3167 96.3906"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M130.744 91.6695L128.797 88.7031"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M130.744 91.668L128.128 94.8039"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M105.397 90.6109L105.397 90.6109L105.396 90.6181C105.353 91.0588 105.159 91.4714 104.847 91.7866C104.539 92.0971 104.136 92.2945 103.702 92.3469C103.005 92.3591 102.445 91.7735 102.546 90.8676L102.546 90.8676L102.547 90.8615C102.591 90.4215 102.785 90.0097 103.097 89.6953C103.405 89.385 103.809 89.1883 104.243 89.1373C104.933 89.1177 105.499 89.7181 105.397 90.6109Z"
          fill="#126FFF"
          stroke="#126FFF"
        />
        <path
          d="M128.667 121.363L77.4691 121.962C75.9712 121.962 74.9027 120.7 75.0874 119.105L76.3457 108.171C76.4264 107.396 76.7752 106.674 77.3321 106.128C77.8889 105.582 78.6189 105.247 79.3964 105.18L130.594 103.829C132.092 103.789 133.161 105.12 132.976 106.82L131.718 118.287C131.553 119.972 130.165 121.343 128.667 121.363Z"
          fill="#85B4FE"
        />
        <path
          d="M81.3555 111.759L81.3593 111.739L81.3615 111.719C81.4087 111.28 81.6078 110.871 81.9245 110.562C82.2379 110.257 82.6472 110.07 83.0832 110.031L95.5044 109.752C95.6867 109.752 95.8668 109.793 96.0319 109.87C96.1983 109.949 96.3452 110.063 96.462 110.205C96.5787 110.346 96.6625 110.512 96.7072 110.69C96.752 110.868 96.7567 111.054 96.7209 111.233L96.7161 111.257L96.7137 111.282C96.6699 111.723 96.4724 112.136 96.1554 112.447C95.8419 112.756 95.4312 112.946 94.9932 112.985L82.5709 113.219C82.3897 113.22 82.2107 113.181 82.0464 113.104C81.8812 113.028 81.7351 112.915 81.6187 112.776C81.5023 112.636 81.4184 112.473 81.3729 112.297C81.3274 112.121 81.3214 111.937 81.3555 111.759Z"
          fill="#1076E6"
          stroke="#126FFF"
        />
        <path
          d="M80.7344 116.856L99.1335 116.557"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M128.442 112.633L126.495 109.637"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M128.442 112.633L125.821 115.714"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M103.095 111.143L103.095 111.143L103.094 111.15C103.054 111.585 102.861 111.992 102.55 112.299C102.244 112.602 101.844 112.789 101.416 112.831C101.243 112.827 101.072 112.787 100.916 112.712L100.714 113.137L100.916 112.712C100.753 112.635 100.61 112.522 100.496 112.383L100.109 112.7L100.496 112.383C100.382 112.244 100.3 112.081 100.256 111.907C100.213 111.732 100.208 111.551 100.243 111.374L100.248 111.35L100.25 111.325C100.292 110.89 100.485 110.483 100.796 110.176C101.103 109.872 101.506 109.683 101.936 109.643C102.625 109.635 103.193 110.235 103.095 111.143Z"
          fill="#1076E6"
          stroke="#126FFF"
        />
        <path
          d="M126.37 142.196L75.1722 141.897C74.8156 141.891 74.4646 141.808 74.1435 141.653C73.8223 141.499 73.5386 141.276 73.3121 141.001C73.0856 140.726 72.9217 140.405 72.8316 140.061C72.7415 139.716 72.7276 139.356 72.7905 139.006L74.0488 128.117C74.1299 127.351 74.4803 126.638 75.0379 126.105C75.5954 125.572 76.324 125.253 77.0944 125.206L128.292 124.752C129.79 124.752 130.859 126.083 130.674 127.743L129.416 139.175C129.231 140.875 127.868 142.201 126.37 142.196Z"
          fill="#85B4FE"
        />
        <path
          d="M79.0594 131.757L79.0628 131.738L79.0648 131.719C79.1096 131.285 79.3076 130.881 79.6234 130.58C79.9361 130.281 80.3442 130.102 80.7761 130.073L93.1985 130.014C93.3823 130.017 93.5632 130.061 93.7286 130.141C93.8955 130.222 94.0427 130.338 94.1597 130.482L94.5471 130.165L94.1597 130.482C94.2767 130.625 94.361 130.792 94.4065 130.971C94.452 131.151 94.4577 131.338 94.4232 131.519L94.4186 131.544L94.4164 131.569C94.3778 132.008 94.1826 132.419 93.8661 132.727C93.5532 133.032 93.1421 133.216 92.7061 133.246H80.2842C80.1013 133.243 79.9213 133.2 79.7566 133.121C79.5908 133.041 79.4443 132.927 79.3276 132.785C79.2108 132.643 79.1263 132.478 79.08 132.3C79.0337 132.123 79.0267 131.937 79.0594 131.757Z"
          fill="#1076E6"
          stroke="#126FFF"
        />
        <path
          d="M78.4327 136.863L91.7589 136.878"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M126.14 133.483L124.193 130.457"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M126.141 133.482L123.524 136.509"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M100.799 131.518L100.795 131.542L100.792 131.566C100.752 131.995 100.56 132.397 100.25 132.697C99.9456 132.993 99.5468 133.173 99.1234 133.206C98.9482 133.199 98.7763 133.155 98.6189 133.078C98.455 132.998 98.3107 132.883 98.1959 132.742C98.081 132.6 97.9984 132.435 97.9538 132.259C97.9091 132.083 97.9034 131.899 97.9371 131.72L97.9413 131.698L97.9434 131.675C97.9851 131.246 98.178 130.845 98.488 130.544C98.7927 130.248 99.1911 130.068 99.6145 130.034C99.7897 130.04 99.9616 130.083 100.119 130.16C100.283 130.24 100.428 130.355 100.542 130.496C100.657 130.638 100.74 130.802 100.784 130.979C100.828 131.155 100.834 131.339 100.799 131.518Z"
          fill="#1076E6"
          stroke="#126FFF"
        />
        <path
          d="M110.742 14.9115L122.52 13.6602"
          stroke="#85B4FE"
          stroke-width="1.56"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M137.3 69.8071L141.933 31.6674C142.677 26.0637 138.378 21.7262 132.327 22.1949L88.9229 25.6848L83.0411 72.0506"
          fill="#85B4FE"
        />
        <path
          d="M85.6674 39.1263L115.92 37.1121L116.045 36.2695L85.7922 38.2987L85.6674 39.1263Z"
          fill="#C8DFF9"
        />
        <path
          d="M121.667 36.837L135.687 35.79L135.802 34.9375L121.776 35.9944L121.667 36.837Z"
          fill="#126FFF"
        />
        <path
          d="M86.3864 34.4339L116.639 32.33L116.769 31.4824L86.5162 33.6013L86.3864 34.4339Z"
          fill="#C8DFF9"
        />
        <path
          d="M122.306 32.0461L133.959 31.1338L134.074 30.2812L122.421 31.1986L122.306 32.0461Z"
          fill="#126FFF"
        />
        <path
          d="M91.2296 42.481L79.9555 43.2638L80.0703 42.4362L91.3444 41.6484L91.2296 42.481Z"
          fill="#C8DFF9"
        />
        <path
          d="M107.746 41.8125L135.108 40.0576L135.232 39.2051L107.871 40.975L107.746 41.8125Z"
          fill="#126FFF"
        />
        <path
          d="M84.3942 47.3963L134.469 44.3252L134.598 43.4727L84.519 46.5687L84.3942 47.3963Z"
          fill="#126FFF"
        />
        <path
          d="M83.7552 51.5303L133.835 48.5889L133.959 47.7363L83.8851 50.7027L83.7552 51.5303Z"
          fill="#126FFF"
        />
        <path
          d="M111.306 58.1249L132.446 56.8436L132.561 55.9961L111.421 57.2923L111.306 58.1249Z"
          fill="#126FFF"
        />
        <path
          d="M115.276 61.5915L127.463 60.8836L127.578 60.041L115.385 60.7539L115.276 61.5915Z"
          fill="#126FFF"
        />
        <path
          d="M91.1099 30.9134C91.6741 27.952 94.1805 25.2997 96.687 24.9956C106.049 23.8439 124.053 22.6523 124.053 22.6523C121.557 22.9714 119.645 25.8082 118.956 28.9591C115.51 44.5091 113.039 59.8348 111.037 74.946C110.537 78.7899 107.572 81.9258 104.386 81.9258C92.5679 81.9258 83.6405 71.4212 85.7924 59.9295C87.52 50.7062 89.2475 40.2714 91.1099 30.9134Z"
          fill="#DEECFB"
        />
        <g filter="url(#filter1_d_1748_33261)">
          <path
            d="M45.353 83.8562L103.561 81.827C101.964 81.5878 100.509 80.7802 99.4616 79.5534C98.4146 78.3266 97.8472 76.7631 97.8639 75.1513C98.0836 53.3444 100.575 27.2898 104.385 4.79492C84.3729 7.95245 64.3612 11.0402 44.3494 14.0581C39.8058 33.1678 36.9449 54.5859 37.8586 76.4177C37.8586 76.5174 37.8586 76.6121 37.8586 76.7068C37.9436 78.6335 38.7706 80.4529 40.1671 81.7851C41.5636 83.1173 43.4216 83.8593 45.353 83.8562Z"
            fill="url(#paint0_linear_1748_33261)"
          />
        </g>
        <path
          d="M43.6418 72.6728C51.2977 72.3006 58.9535 71.9267 66.6094 71.5511V70.4941L43.6418 71.6707V72.6728Z"
          fill="#C8DFF9"
        />
        <path
          d="M43.6768 68.3716L77.7937 66.3525C77.7937 65.9935 77.7937 65.6296 77.7937 65.2656C66.4264 65.9736 55.054 66.6749 43.6768 67.3695C43.6768 67.7035 43.6768 68.0376 43.6768 68.3716Z"
          fill="#C8DFF9"
        />
        <path
          d="M43.8914 59.7407C60.1685 58.4511 76.4472 57.1465 92.7276 55.827C92.7276 55.4481 92.7526 55.0742 92.7625 54.6953C76.4855 56.058 60.2084 57.4025 43.9314 58.7286C43.9164 59.0676 43.9064 59.4017 43.8914 59.7407Z"
          fill="#C8DFF9"
        />
        <path
          d="M53.9622 51.4542L70.2042 49.8738C70.2042 49.5148 70.2492 49.1558 70.2692 48.7969L54.0271 50.4172C54.0071 50.7612 53.9822 51.1351 53.9622 51.4542Z"
          fill="#C8DFF9"
        />
        <path
          d="M54.3615 48.2992C63.2623 47.3719 72.1631 46.4362 81.0638 45.4923C81.1038 44.8442 81.1487 44.1911 81.1937 43.543C72.2962 44.5201 63.3971 45.4923 54.4963 46.4595L54.3615 48.2992Z"
          fill="#C8DFF9"
        />
        <path
          d="M70.9083 71.3614L92.5128 70.2945V69.1328L70.9083 70.2546V71.3614Z"
          fill="#C8DFF9"
        />
        <path
          d="M43.6768 76.447L74.6331 75.2155V74.1387L43.6768 75.4449C43.6768 75.7789 43.6768 76.113 43.6768 76.447Z"
          fill="#C8DFF9"
        />
        <path
          d="M78.947 75.0675L92.5528 74.5191V73.3574L78.947 73.9407V75.0675Z"
          fill="#C8DFF9"
        />
        <path
          d="M82.6866 66.0323L92.5279 65.444C92.5279 65.0551 92.5279 64.6662 92.5279 64.2773L82.6866 64.8955C82.6916 65.2744 82.6866 65.6534 82.6866 66.0323Z"
          fill="#C8DFF9"
        />
        <path
          d="M43.7466 64.1438C60.0303 63.0271 76.3123 61.8953 92.5927 60.7487C92.5927 60.3598 92.5927 59.9709 92.6177 59.582C76.3407 60.7686 60.0603 61.9419 43.7766 63.1018L43.7466 64.1438Z"
          fill="#C8DFF9"
        />
        <path
          d="M46.4429 80.1565L95.4437 78.6608L95.4139 77.4941L46.4179 79.1294C46.4279 79.4585 46.4379 79.8075 46.4429 80.1565Z"
          fill="#C8DFF9"
        />
        <path
          d="M43.6768 49.8144C43.567 51.3449 44.8601 52.4567 46.5527 52.3072C47.366 52.235 48.1304 51.8887 48.7202 51.3251C49.3101 50.7615 49.6902 50.0142 49.7982 49.2061C49.908 47.6506 48.6348 46.5388 46.9472 46.7133C45.2596 46.8878 43.7667 48.2838 43.6768 49.8144Z"
          fill="#C8DFF9"
        />
        <path
          d="M147.4 17.1332C147.201 18.8832 145.733 20.4437 144.12 20.6231C142.507 20.8026 141.359 19.5064 141.554 17.7614C141.748 16.0165 143.221 14.456 144.839 14.2715C146.457 14.087 147.6 15.3733 147.4 17.1332Z"
          stroke="#85B4FE"
          stroke-width="0.7"
          stroke-miterlimit="10"
        />
        <path
          d="M145.353 16.8942C145.331 17.1386 145.228 17.3688 145.061 17.5491C144.894 17.7293 144.672 17.8496 144.43 17.8913C144.324 17.9024 144.218 17.8884 144.119 17.8504C144.02 17.8123 143.931 17.7514 143.861 17.6725C143.79 17.5936 143.739 17.4991 143.712 17.3967C143.685 17.2943 143.683 17.187 143.706 17.0836C143.729 16.8391 143.831 16.609 143.998 16.4287C144.165 16.2485 144.387 16.1282 144.629 16.0865C144.735 16.0742 144.842 16.0874 144.942 16.1251C145.041 16.1627 145.13 16.2237 145.201 16.3029C145.272 16.382 145.323 16.4771 145.349 16.58C145.376 16.6829 145.377 16.7906 145.353 16.8942V16.8942Z"
          stroke="#85B4FE"
          stroke-width="0.7"
          stroke-miterlimit="10"
        />
        <path
          d="M144.21 19.7957C144.775 19.73 145.303 19.4773 145.708 19.0778C145.571 18.856 145.372 18.6786 145.137 18.5671C144.901 18.4556 144.638 18.4148 144.379 18.4496C144.08 18.4945 143.794 18.5994 143.536 18.7579C143.279 18.9164 143.056 19.1252 142.882 19.3719C143.06 19.5321 143.271 19.6527 143.5 19.7258C143.729 19.7988 143.971 19.8227 144.21 19.7957Z"
          stroke="#85B4FE"
          stroke-width="0.7"
          stroke-miterlimit="10"
        />
        <path
          d="M50.1675 30.1969C50.1675 30.0773 50.1675 30.0124 50.1675 29.8928L50.8665 29.8031C50.9441 28.5155 51.1278 27.2365 51.4158 25.9791L50.7018 26.0788C50.7018 25.9592 50.7417 25.8993 50.7667 25.7747L53.6076 25.3809C53.6076 25.5005 53.5728 25.5653 53.5478 25.685L52.7639 25.7946C52.4759 27.0565 52.2823 28.338 52.1847 29.6285H52.3495C53.2632 29.5089 54.0021 29.13 54.1469 28.1329C54.1469 28.0581 54.1469 28.0182 54.1769 27.9384L54.5264 27.8936C54.4165 28.6065 54.3667 28.9605 54.3167 29.6734L50.1675 30.1969Z"
          fill="#1076E6"
        />
        <path
          d="M59.744 28.6365C59.1149 27.0661 58.8752 26.2833 58.3759 24.7129L57.8467 24.7877C56.8481 26.403 56.2889 27.2107 55.3952 28.826C55.3369 28.9507 55.2476 29.0584 55.1356 29.1388C55.0237 29.2191 54.893 29.2693 54.756 29.2846H54.6162C54.6162 29.4093 54.6163 29.4691 54.5864 29.5937L56.2589 29.3744C56.2589 29.2497 56.259 29.1899 56.2889 29.0653L55.9893 29.1052C55.8145 29.1052 55.6598 29.1052 55.6848 28.9157C55.6995 28.8443 55.7283 28.7765 55.7696 28.7163C55.8895 28.4969 55.9494 28.3872 56.0743 28.1679L57.8966 27.9236C58.0265 28.2826 58.0913 28.457 58.2311 28.811L57.5621 28.9008C57.5621 29.0254 57.5621 29.0852 57.5272 29.2099L60.2883 28.8509C60.2883 28.7263 60.2883 28.6664 60.3233 28.5418L59.744 28.6365ZM56.2489 27.8588C56.6334 27.2107 56.8381 26.8866 57.2475 26.2385H57.3075C57.4872 26.8118 57.5821 27.096 57.7768 27.6693L56.2489 27.8588Z"
          fill="#1076E6"
        />
        <path
          d="M62.9496 28.5269C62.9496 28.4022 62.9495 28.3374 62.9894 28.2128H63.1043H63.1892C63.3293 28.2102 63.463 28.1539 63.5625 28.0555C63.662 27.957 63.7196 27.8241 63.7234 27.6843C63.8981 26.4977 64.008 25.9044 64.2227 24.6929C64.2384 24.6327 64.2381 24.5695 64.222 24.5094C64.2058 24.4493 64.1742 24.3945 64.1304 24.3502C64.0866 24.306 64.032 24.2739 63.9721 24.2571C63.9121 24.2402 63.8488 24.2392 63.7884 24.2542H63.6685C63.6685 24.1296 63.6984 24.0648 63.7234 23.9401L65.7206 23.6559C66.4196 24.6181 66.7641 25.1017 67.4781 26.0689C67.6079 25.2513 67.6779 24.8425 67.8027 24.0298C67.8427 23.7756 67.6778 23.6809 67.3333 23.7307L67.1286 23.7606L67.1786 23.4416L68.8712 23.1973C68.8712 23.3269 68.8463 23.3867 68.8263 23.5163H68.6615C68.5366 23.519 68.4159 23.5617 68.317 23.6379C68.2182 23.7142 68.1464 23.8201 68.1122 23.9401C67.8626 25.5205 67.7128 26.3132 67.5131 27.8937L66.9389 27.9685C65.9404 26.6523 65.441 25.9742 64.5123 24.668C64.3076 25.8446 64.1978 26.4329 64.013 27.6095C63.9581 27.9834 64.1279 28.0233 64.5623 27.9635H64.6971C64.6971 28.0881 64.6971 28.1529 64.6571 28.2776L62.9496 28.5269Z"
          fill="#1076E6"
        />
        <path
          d="M73.2351 26.8662C72.6259 25.2359 72.3664 24.4233 71.7822 22.793L71.2829 22.8677C70.3792 24.5379 69.8799 25.3605 68.9462 27.0457C68.8869 27.1715 68.7968 27.2805 68.6843 27.3624C68.5717 27.4443 68.4403 27.4965 68.3021 27.5143H68.1573C68.1573 27.6439 68.1323 27.7038 68.1173 27.8334L69.79 27.614C69.79 27.4894 69.7899 27.4246 69.8299 27.2949L69.5254 27.3348C69.3506 27.3598 69.2008 27.3348 69.2307 27.1354C69.2484 27.0625 69.2789 26.9933 69.3207 26.931L69.6352 26.3626L71.4327 26.1233C71.5575 26.4873 71.6174 26.6717 71.7472 27.0407L71.0831 27.1304C71.0831 27.26 71.0832 27.3248 71.0432 27.4495L73.7794 27.0905C73.7794 26.9659 73.8044 26.9011 73.8193 26.7715L73.2351 26.8662ZM69.815 26.0435C70.1994 25.3755 70.3891 25.0464 70.7686 24.3684H70.8284C71.0232 24.9617 71.123 25.2608 71.3277 25.8641L69.815 26.0435Z"
          fill="#1076E6"
        />
        <path
          d="M78.5526 21.8066C78.4927 22.4797 78.4527 22.8038 78.3828 23.4918L78.0483 23.5416C77.9984 22.8736 77.7387 22.1158 76.9249 22.2354C75.9013 22.385 75.5868 23.3871 75.427 24.5836C75.2672 25.7801 75.3521 26.6825 76.3906 26.5479C76.7869 26.4786 77.149 26.28 77.42 25.9832C77.6911 25.6863 77.8558 25.3081 77.8885 24.9077L78.243 24.8578C78.1959 25.3758 77.9717 25.8618 77.608 26.234C77.2443 26.6063 76.7632 26.8422 76.2458 26.9019C75.947 26.9612 75.6384 26.9487 75.3453 26.8655C75.0523 26.7823 74.7831 26.6307 74.5603 26.4233C74.3374 26.2159 74.1672 25.9585 74.0635 25.6725C73.9599 25.3864 73.9257 25.0799 73.964 24.7781C74.1002 24.0914 74.4325 23.4587 74.9206 22.9563C75.4087 22.4539 76.0319 22.1031 76.7151 21.9463C77.1694 21.8854 77.6318 21.9545 78.0483 22.1457C78.2181 22.1207 78.263 21.9861 78.2829 21.8415L78.5526 21.8066Z"
          fill="#1076E6"
        />
        <path
          d="M78.7222 26.4626C78.7222 26.333 78.7223 26.2632 78.7572 26.1335L79.4462 26.0438C79.6209 24.3986 79.7258 23.5759 79.8706 21.9307L79.1965 22.0254C79.1965 21.8958 79.1966 21.831 79.2265 21.7014L81.8578 21.3125V21.6416L81.1789 21.7413C81.0391 23.3915 80.9442 24.2341 80.7744 25.8693L81.4634 25.7796C81.4634 25.9092 81.4635 25.974 81.4285 26.1086L78.7222 26.4626Z"
          fill="#1076E6"
        />
        <path
          d="M84.9287 20.723C84.2338 20.8711 83.6005 21.2273 83.1135 21.7439C82.6264 22.2605 82.3086 22.9131 82.2024 23.6146C82.1695 23.9191 82.2073 24.227 82.3129 24.5146C82.4184 24.8021 82.5888 25.0615 82.8109 25.2727C83.033 25.4838 83.3009 25.6411 83.5936 25.7322C83.8864 25.8234 84.1963 25.846 84.4992 25.7983C85.2058 25.6647 85.853 25.3143 86.3506 24.796C86.8482 24.2776 87.1715 23.6172 87.2753 22.9067C87.3049 22.5985 87.2629 22.2876 87.153 21.9982C87.043 21.7087 86.8678 21.4484 86.641 21.2373C86.4142 21.0262 86.1418 20.8701 85.8449 20.7809C85.5479 20.6917 85.2345 20.6719 84.9287 20.723ZM84.5342 25.4693C83.5356 25.6039 83.5356 24.532 83.6105 23.4202C83.6854 22.3084 83.9251 21.1966 84.9137 21.052C85.9023 20.9074 85.9572 21.9694 85.8623 23.1061C85.7674 24.2428 85.5428 25.3347 84.5342 25.4693Z"
          fill="#1076E6"
        />
        <path
          d="M87.475 25.3054L87.5 24.9714H87.6148H87.6996C87.7692 24.9675 87.8373 24.9501 87.9 24.9199C87.9628 24.8898 88.0191 24.8475 88.0655 24.7957C88.1118 24.7438 88.1475 24.6833 88.1704 24.6176C88.1933 24.5519 88.203 24.4824 88.1989 24.413C88.3038 23.1466 88.3537 22.5135 88.4486 21.2521C88.4636 21.1835 88.4612 21.1123 88.4414 21.0449C88.4216 20.9775 88.3852 20.9162 88.3355 20.8666C88.2857 20.8169 88.2243 20.7805 88.1569 20.7608C88.0894 20.741 88.018 20.7386 87.9493 20.7536H87.8345C87.8345 20.6189 87.8345 20.5492 87.8345 20.4195L89.8316 20.1304L91.8288 22.7229C91.8937 21.8554 91.9237 21.4216 91.9886 20.5541C91.9886 20.2849 91.8289 20.1852 91.4893 20.2301L91.2797 20.26L91.3045 19.926L93.0172 19.6816V20.0207H92.8474C92.7209 20.0232 92.5992 20.0692 92.5028 20.1509C92.4064 20.2327 92.3411 20.3452 92.3181 20.4694C92.1883 22.1545 92.1284 22.9921 91.9936 24.6772L91.4244 24.752L88.7532 21.2172C88.6633 22.4686 88.6134 23.0968 88.5086 24.3482C88.4786 24.742 88.6433 24.7869 89.0777 24.732H89.2075V25.071L87.475 25.3054Z"
          fill="#1076E6"
        />
      </g>
      <path
        d="M23.4268 4.79303L18.7592 9.45703L23.4268 14.121L28.0944 9.45701L23.4268 4.79303Z"
        fill="#0250C9"
      />
      <path
        d="M20.9737 33.2755L16.306 37.9395L20.9737 42.6034L25.6413 37.9395L20.9737 33.2755Z"
        fill="#0250C9"
      />
      <path
        d="M182.62 9.51587L180.419 11.7148L182.62 13.9138L184.821 11.7148L182.62 9.51587Z"
        fill="#0250C9"
      />
      <path
        d="M169.776 24.9299L167.576 27.1289L169.776 29.3279L171.977 27.1289L169.776 24.9299Z"
        fill="#0250C9"
      />
      <path
        d="M2.60372 19.5965L0.552124 21.6465L2.60374 23.6965L4.65533 21.6465L2.60372 19.5965Z"
        fill="#0250C9"
      />
      <path
        d="M39.9397 0.000742125L37.8881 2.05078L39.9397 4.1008L41.9913 2.05076L39.9397 0.000742125Z"
        fill="#0250C9"
      />
      <path
        d="M188.568 159.866H186.866C186.866 159.866 190.847 151.394 197.448 147.382C189.16 146.098 188.106 149.268 188.106 149.268C191.921 136.407 182.619 116.303 174.974 130.799C163.082 117.863 150.046 118.761 157.139 139.261C162.661 155.171 182.619 159.866 182.619 159.866H108.146"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M178.965 131.797C178.965 131.797 179.969 152.161 183.423 156.987C170.532 151.529 162.646 133.974 162.646 133.974"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M174.974 130.803C174.974 130.803 171.817 137.534 175.476 145.645"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M167.302 128.436C164.345 135.488 171.248 147.385 171.248 147.385"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M183.421 141.482C181.474 143.062 180.911 149.272 180.911 149.272L183.421 141.482Z"
        fill="#126FFF"
      />
      <path
        d="M183.421 141.482C181.474 143.062 180.911 149.272 180.911 149.272"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M165.692 151.262C156.777 150.981 154.935 155.591 154.935 155.591C154.935 155.591 167.138 159.87 173.006 159.87"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M173.03 157.618C158.904 150.927 154.958 155.612 154.958 155.612"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M102.874 159.959H99.4857"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path d="M94.3537 160H49.4818" stroke="#0250C9" stroke-miterlimit="10" />
      <defs>
        <filter
          id="filter0_d_1748_33261"
          x="63.1234"
          y="6.17969"
          width="91.1432"
          height="155.105"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1748_33261"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1748_33261"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1748_33261"
          x="33.6888"
          y="4.79492"
          width="74.6959"
          height="87.0605"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1748_33261"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1748_33261"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1748_33261"
          x1="50.7204"
          y1="88.1238"
          x2="100.166"
          y2="2.36344"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.11" stop-color="#EDF6FF" />
          <stop offset="0.32" stop-color="white" />
        </linearGradient>
        <clipPath id="clip0_1748_33261">
          <rect
            width="120.315"
            height="148.49"
            fill="white"
            transform="translate(37.6752 4.79492)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConfirmacionSinTarjetaClub;
