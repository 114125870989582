import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Button } from '@lana-ui/login';
import { getAuth0TokensFromCache } from '../../Helpers/Auth0CookieManager';
import { Image, Text } from '@lana-ui/common';
import useInterval from '../../Hooks/UseInterval';
import ApiService from '../../Services/ApiService';
import ErrorImage from '@images/loginImg/email-ok.png';

import '../../Statics/Scss/Components/Auth0ResendSuspendEmail.scss';

function ResendEmail(props) {
    const { userBloquedForResendEmail, onLogout } = props;
    const { REACT_APP_APILOGIN } = process.env;
    const { getAccessTokenSilently } = useAuth0();
    const [CDTime, setCDTime] = useState(30);
    const [counterResend, setCounterResend] = useState(0);
    const [timerRun, setTimerRun] = useState(null);
    const [disableResendEmail, setDisableResendEmail] = useState(false);

    useEffect(()=>{
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({event: 'trackPage', pageName: "crear_cuenta_confirmacion_envio_mail"});
    }, []);

    const sendEmailVerification = async () => {
        if (counterResend < 3) {
            setCDTime(30);
            setTimerRun(1000);
            setDisableResendEmail(true);
            const { userId, idToken } = getAuth0TokensFromCache();
            const body = { userId: userId };
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}`,
                'X-token': idToken,
            };
            const response = await ApiService.postWithHeaders(
                `${REACT_APP_APILOGIN}Auth0V1/ResendVerificationEmail`,
                body,
                headers
            );
            if (response.code === '0001' && response.message === '0002') {
                userBloquedForResendEmail(true);
            }
        } else {
            userBloquedForResendEmail(true);
        }
    };
    useInterval(() => {
        if (CDTime > 0) {
            setCDTime(CDTime - 1);
        } else if (CDTime === 0) {
            setDisableResendEmail(false);
            setTimerRun(null);
            setCounterResend(counterResend + 1);
        }
    }, timerRun);

    const className = `${disableResendEmail ? '--disabled' : '--not-disabled'}`;
    return (
        <>
            <Image src={ErrorImage} className="img-Auth0Email" />
            <Text
                as="p"
                size="--l"
                className="--sueca --font-bold --error-title text-verify-account"
            >
                Por favor verificá tu email para activar tu cuenta.
            </Text>
            <div className="counter-text">
                {disableResendEmail
                    ? `Debe esperar ${CDTime} segundos para pedir otro email.`
                    : ''}
            </div>
            <div className='container-button'>
                <Button
                    className={className}
                    onClick={sendEmailVerification}
                    disabled={disableResendEmail}
                >
                    REENVIAR EMAIL
                </Button>
                <Button
                    className="--text-blue"
                    label="Cambiar de cuenta"
                    aria-label="Cambiar de cuenta"
                    onClick={onLogout}
                ></Button>
            </div>
        </>
    );
}

export default ResendEmail;
