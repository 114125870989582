import React from "react";
const VerticalLine = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path fill="#CCC" d="M11.5 4h1v16h-1z"></path>
    </svg>
  );
};

export default VerticalLine;
