export const StepsTrackeoRedimirPin = {
    1: "visualizacion-redimir-pin",
    2: "login-redimir-pin",
    3: "redimio-pin",
    4: "activo-suscripcion",
    5: "click-finalizar-pin",
    //errores
    6: "no-existe-pin",
    7: "pin-ya-utilizado",
    8: "pin-vencido",
    9: "pin-asociado-a-otro-crm",
}


