import React from "react";
const Entertainment = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 18"
      {...r}
    >
      <path
        fill="#272727"
        d="M0 .993A1 1 0 01.992 0h18.016c.548 0 .992.445.992.993v16.014a1 1 0 01-.992.993H.992A.993.993 0 010 17.007V.993zM6 2v14h8V2H6zM2 2v2h2V2H2zm14 0v2h2V2h-2zM2 6v2h2V6H2zm14 0v2h2V6h-2zM2 10v2h2v-2H2zm14 0v2h2v-2h-2zM2 14v2h2v-2H2zm14 0v2h2v-2h-2z"
      ></path>
    </svg>
  );
};

export default Entertainment;
