import { useHistory } from 'react-router';
import { useStore } from '../Store/storeProvider';

const useValidarCredencialBlack = () => {
    const history = useHistory();
    const { Config } = useStore();
    /**
     * Recibe el tipo de credencial
     * @param {String} userClubValue
     */
    const handleValidarCredBlack = (userClubData) => {
        let userClubValue = userClubData || '';
        switch (userClubValue) {
            case 'B':
                // Credencial Black. Voy a url de callback.
                window.location = Config.URLCallback;
                break;
            case 'P':
            case 'C':
            case 'S':
            case 'D':
                //  credencial no black / redirect a page info.
                history.push('/login/no-sos-black');
                break;
            case '':
            case '0':
                // No tengo credencial asignada. Pido asignar.
                history.push('/login/vincular-black');
                break;
            default:
                throw new Error();
        }
    };
    return [handleValidarCredBlack];
};

export default useValidarCredencialBlack;
