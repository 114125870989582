import React from "react";
const PinterestFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 21 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M11.37.094A10.003 10.003 0 006.002 19.17a7.757 7.757 0 01.163-2.293c.185-.839 1.296-5.463 1.296-5.463a3.739 3.739 0 01-.324-1.577c0-1.485.857-2.593 1.923-2.593a1.334 1.334 0 011.342 1.508c0 .9-.578 2.262-.88 3.54a1.544 1.544 0 001.575 1.923c1.898 0 3.17-2.431 3.17-5.301 0-2.2-1.457-3.848-4.143-3.848a4.746 4.746 0 00-4.93 4.794 2.96 2.96 0 00.648 1.97.48.48 0 01.162.554c-.046.184-.162.623-.208.784a.354.354 0 01-.51.254c-1.384-.554-2.036-2.077-2.036-3.816 0-2.847 2.384-6.255 7.154-6.255 3.796 0 6.32 2.777 6.32 5.747 0 3.909-2.177 6.848-5.394 6.848A2.86 2.86 0 018.876 14.7s-.578 2.316-.692 2.754a8.026 8.026 0 01-1.019 2.131c.923.28 1.882.42 2.846.416a9.987 9.987 0 009.996-10.003A10.002 10.002 0 0011.372.095L11.37.094z"
      ></path>
    </svg>
  );
};

export default PinterestFilled;
