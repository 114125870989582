import { Image, Text } from '@lana-ui/common';
import { Button } from '@lana-ui/login';
import ErrorImage from '@images/error-not-found.png';
import './_error-404.scss';

const ErrorNotFound = ({ buttonText, handleClickButton }) => {
    return (
        <div className="error404">
            <Image className="--error-image" src={ErrorImage} />
            <Text as="p" size="--xl" className="--sueca --error-title">
                La página que buscas no está disponible
            </Text>
            <Text as="p" size="2xs" className="--roboto">
                Seguí navegando y encontrá lo que necesitás
            </Text>
            <Button
                className="--not-disabled"
                label={buttonText ? buttonText : 'Ir a la página principal'}
                aria-label="Ir a la página principal."
                onClick={handleClickButton}
            ></Button>
        </div>
    );
};

export default ErrorNotFound;
