import React from "react";

const ConfirmacionConTarjetaClub = () => {
  return (
    <svg
      width="204"
      height="160"
      viewBox="0 0 204 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1748_33047)">
        <path
          d="M147.683 144.504L162.749 14.666"
          stroke="#0250C9"
          stroke-miterlimit="10"
        />
        <path
          d="M155.753 39.5674C155.57 39.5727 155.387 39.5374 155.219 39.4641C155.051 39.3908 154.901 39.2813 154.78 39.1434C154.658 39.0056 154.569 38.8427 154.519 38.6664C154.468 38.4902 154.457 38.305 154.486 38.1239L155.56 29.354C155.617 28.961 155.811 28.6006 156.109 28.3365C156.406 28.0724 156.787 27.9216 157.184 27.9105V27.9105C157.367 27.9061 157.55 27.942 157.717 28.0156C157.885 28.0892 158.035 28.1987 158.156 28.3363C158.277 28.474 158.366 28.6365 158.417 28.8124C158.468 28.9882 158.48 29.1731 158.452 29.354L157.377 38.1239C157.32 38.517 157.126 38.8774 156.829 39.1414C156.532 39.4055 156.151 39.5564 155.753 39.5674V39.5674Z"
          stroke="#0250C9"
          stroke-miterlimit="10"
        />
        <path
          d="M153.882 55.4308C153.699 55.4351 153.517 55.3992 153.349 55.3257C153.181 55.2521 153.031 55.1426 152.91 55.0049C152.789 54.8672 152.7 54.7047 152.649 54.5289C152.598 54.353 152.586 54.1681 152.615 53.9872L153.694 45.2173C153.751 44.8243 153.945 44.4639 154.242 44.1998C154.539 43.9357 154.92 43.7848 155.318 43.7738V43.7738C155.501 43.7694 155.683 43.8053 155.851 43.8789C156.019 43.9525 156.169 44.062 156.29 44.1997C156.41 44.3373 156.5 44.4998 156.551 44.6757C156.602 44.8515 156.614 45.0364 156.585 45.2173L155.506 53.9872C155.452 54.3815 155.258 54.7436 154.961 55.0082C154.663 55.2728 154.281 55.4227 153.882 55.4308V55.4308Z"
          stroke="#0250C9"
          stroke-miterlimit="10"
        />
        <g filter="url(#filter0_d_1748_33047)">
          <path
            d="M133.034 151.992H79.2176C75.2568 151.992 72.4646 148.631 72.9498 144.478L87.1687 21.1063C87.6638 16.845 91.2482 13.0137 95.1941 12.5441L149.01 6.1718C152.971 5.70216 155.768 8.97482 155.283 13.4784L141.059 144.033C140.579 148.433 136.985 151.992 133.034 151.992Z"
            fill="#0250C9"
          />
        </g>
        <path
          d="M136.202 99.5624L85.4359 101.045C83.9506 101.085 82.896 99.8344 83.0743 98.2475L84.3269 87.3716C84.4125 86.6022 84.7585 85.885 85.3076 85.3385C85.8567 84.792 86.576 84.4489 87.3468 84.3659L138.113 82.1413C139.599 82.0721 140.658 83.3772 140.475 85.0481L139.227 96.4727C139.044 98.1338 137.693 99.5229 136.202 99.5624Z"
          fill="#85B4FE"
        />
        <path
          d="M103.964 91.2674C103.653 91.5793 103.245 91.7762 102.807 91.8258L90.4906 92.2749C90.3133 92.2789 90.1374 92.2433 89.9758 92.1707C89.8134 92.0977 89.6696 91.9893 89.5549 91.8533C89.4401 91.7174 89.3575 91.5575 89.3131 91.3856C89.2687 91.2137 89.2635 91.034 89.298 90.8597L89.3023 90.8377L89.3047 90.8153C89.3512 90.3764 89.5475 89.9667 89.8609 89.6552C90.171 89.347 90.577 89.1534 91.0119 89.1062L103.338 88.6125L103.339 88.6125C104.047 88.583 104.621 89.1697 104.52 90.0899L104.519 90.0947C104.475 90.5376 104.279 90.9517 103.964 91.2674Z"
          fill="#126FFF"
          stroke="#126FFF"
        />
        <path
          d="M88.6738 95.9192L98.8677 95.5781"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M135.98 90.8965L134.049 87.9551"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M135.979 90.8965L133.385 94.006"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M110.842 89.8458L110.842 89.8458L110.842 89.853C110.798 90.289 110.606 90.6972 110.298 91.009C109.993 91.3162 109.594 91.5115 109.165 91.5634C108.477 91.5753 107.924 90.9973 108.024 90.1012L108.024 90.1012L108.024 90.0952C108.068 89.6598 108.26 89.2523 108.568 88.9413C108.873 88.6343 109.273 88.4397 109.703 88.3892C110.383 88.37 110.943 88.9625 110.842 89.8458Z"
          fill="#126FFF"
          stroke="#126FFF"
        />
        <path
          d="M133.92 120.341L83.1535 120.934C81.6683 120.934 80.6088 119.683 80.792 118.101L82.0396 107.26C82.1197 106.491 82.4656 105.775 83.0177 105.233C83.5698 104.692 84.2937 104.36 85.0646 104.294L135.831 102.954C137.316 102.914 138.376 104.234 138.193 105.92L136.945 117.29C136.782 118.961 135.405 120.321 133.92 120.341Z"
          fill="#85B4FE"
        />
        <path
          d="M102.729 110.394L102.232 110.344C102.189 110.781 101.993 111.189 101.68 111.498C101.369 111.802 100.963 111.99 100.53 112.03L88.2123 112.262C88.0333 112.262 87.8564 112.224 87.6941 112.148C87.5308 112.072 87.3864 111.961 87.2714 111.824C87.1565 111.686 87.0735 111.524 87.0285 111.35C86.9836 111.176 86.9777 110.995 87.0113 110.819L87.0151 110.799L87.0173 110.778C87.0641 110.344 87.261 109.94 87.5744 109.634C87.8844 109.333 88.2893 109.147 88.7206 109.108L101.037 108.832C101.217 108.832 101.395 108.872 101.558 108.949C101.723 109.026 101.868 109.139 101.983 109.279C102.099 109.419 102.181 109.583 102.226 109.759C102.27 109.935 102.274 110.118 102.239 110.296L102.729 110.394Z"
          fill="#1076E6"
          stroke="#126FFF"
        />
        <path
          d="M86.3914 115.871L104.635 115.574"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M133.697 111.684L131.766 108.713"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M133.697 111.684L131.098 114.739"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M108.559 110.206L108.559 110.206L108.559 110.213C108.518 110.643 108.328 111.046 108.02 111.35C107.718 111.649 107.322 111.835 106.898 111.875C106.727 111.872 106.559 111.832 106.405 111.758C106.244 111.682 106.102 111.571 105.99 111.433C105.877 111.296 105.796 111.135 105.753 110.963C105.71 110.79 105.705 110.611 105.74 110.436L105.745 110.412L105.747 110.387C105.788 109.957 105.979 109.554 106.287 109.25C106.591 108.949 106.989 108.763 107.415 108.723C108.094 108.716 108.657 109.307 108.559 110.206Z"
          fill="#1076E6"
          stroke="#126FFF"
        />
        <path
          d="M131.643 140.999L80.876 140.702C80.5225 140.696 80.1745 140.614 79.856 140.46C79.5376 140.307 79.2563 140.086 79.0317 139.813C78.8071 139.541 78.6445 139.222 78.5552 138.881C78.4659 138.539 78.452 138.182 78.5145 137.835L79.7621 127.038C79.8426 126.278 80.19 125.571 80.7428 125.043C81.2957 124.514 82.0182 124.198 82.7821 124.151L133.549 123.701C135.034 123.701 136.093 125.021 135.91 126.667L134.662 138.003C134.479 139.689 133.128 141.004 131.643 140.999Z"
          fill="#85B4FE"
        />
        <path
          d="M98.262 132.119H85.945C85.7643 132.116 85.5864 132.074 85.4236 131.995C85.2598 131.916 85.1151 131.803 84.9997 131.663C84.8842 131.523 84.8008 131.359 84.755 131.184C84.7093 131.008 84.7023 130.825 84.7347 130.647L84.2427 130.558L84.74 130.609C84.7844 130.18 84.9802 129.78 85.2926 129.482C85.602 129.186 86.0057 129.009 86.4329 128.981L98.7505 128.922C98.9321 128.926 99.1108 128.968 99.2743 129.048C99.4393 129.128 99.5847 129.243 99.7003 129.384C99.816 129.526 99.8992 129.691 99.9442 129.868C99.9892 130.046 99.9948 130.23 99.9608 130.41L99.9561 130.435L99.9539 130.459C99.9157 130.894 99.7226 131.301 99.4096 131.605C99.1 131.907 98.6933 132.089 98.262 132.119Z"
          fill="#1076E6"
          stroke="#126FFF"
        />
        <path
          d="M84.109 135.709L97.3229 135.724"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M131.415 132.358L129.484 129.357"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M131.415 132.357L128.821 135.358"
          stroke="#126FFF"
          stroke-width="1.56"
          stroke-miterlimit="10"
        />
        <path
          d="M106.774 130.503L106.276 130.456C106.237 130.881 106.047 131.278 105.74 131.576C105.439 131.869 105.044 132.046 104.625 132.079C104.452 132.072 104.282 132.029 104.127 131.953C103.965 131.873 103.823 131.76 103.709 131.62C103.595 131.48 103.514 131.318 103.47 131.143C103.426 130.969 103.42 130.787 103.453 130.61L103.457 130.588L103.46 130.566C103.501 130.141 103.692 129.744 103.998 129.447C104.3 129.154 104.694 128.976 105.113 128.942C105.286 128.948 105.455 128.991 105.611 129.067C105.773 129.146 105.916 129.259 106.029 129.399C106.143 129.539 106.224 129.701 106.268 129.876C106.312 130.05 106.317 130.232 106.283 130.409L106.774 130.503Z"
          fill="#1076E6"
          stroke="#126FFF"
        />
        <path
          d="M116.146 14.7838L127.825 13.543"
          stroke="#85B4FE"
          stroke-width="1.56"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M142.48 69.2182L147.074 31.3998C147.812 25.8433 143.549 21.5424 137.549 22.0071L94.5108 25.4676L88.6786 71.4428"
          fill="#85B4FE"
        />
        <path
          d="M91.2828 38.7956L121.28 36.7984L121.404 35.9629L91.4065 37.9749L91.2828 38.7956Z"
          fill="#C8DFF9"
        />
        <path
          d="M126.979 36.5261L140.881 35.4879L140.995 34.6426L127.088 35.6906L126.979 36.5261Z"
          fill="#126FFF"
        />
        <path
          d="M91.9957 34.1434L121.993 32.0572L122.122 31.2168L92.1245 33.3178L91.9957 34.1434Z"
          fill="#C8DFF9"
        />
        <path
          d="M127.612 31.7754L139.168 30.8707L139.282 30.0254L127.726 30.935L127.612 31.7754Z"
          fill="#126FFF"
        />
        <path
          d="M96.7981 42.1225L85.619 42.8986L85.7329 42.078L96.912 41.2969L96.7981 42.1225Z"
          fill="#C8DFF9"
        />
        <path
          d="M113.176 41.4605L140.307 39.7204L140.43 38.875L113.299 40.63L113.176 41.4605Z"
          fill="#126FFF"
        />
        <path
          d="M90.0203 46.9961L139.673 43.9508L139.802 43.1055L90.1441 46.1754L90.0203 46.9961Z"
          fill="#126FFF"
        />
        <path
          d="M89.3867 51.0941L139.044 48.1774L139.168 47.332L89.5154 50.2735L89.3867 51.0941Z"
          fill="#126FFF"
        />
        <path
          d="M116.706 57.6363L137.668 56.3658L137.782 55.5254L116.82 56.8107L116.706 57.6363Z"
          fill="#126FFF"
        />
        <path
          d="M120.642 61.0706L132.727 60.3687L132.841 59.5332L120.75 60.2401L120.642 61.0706Z"
          fill="#126FFF"
        />
        <path
          d="M96.6794 30.6524C97.2389 27.716 99.7242 25.086 102.21 24.7844C111.492 23.6425 129.345 22.4609 129.345 22.4609C126.87 22.7773 124.974 25.5902 124.291 28.7146C120.874 44.1336 118.424 59.3301 116.438 74.3141C115.943 78.1256 113.003 81.2351 109.844 81.2351C98.1251 81.2351 89.273 70.819 91.4068 59.424C93.1198 50.2784 94.8328 39.9315 96.6794 30.6524Z"
          fill="#DEECFB"
        />
        <g filter="url(#filter1_d_1748_33047)">
          <path
            d="M51.308 83.1491L109.025 81.1371C107.443 80.8998 105.999 80.0991 104.961 78.8826C103.923 77.6661 103.36 76.1158 103.377 74.5176C103.594 52.8944 106.065 27.0593 109.842 4.75391C89.9992 7.88484 70.1561 10.9466 50.3129 13.9391C45.8076 32.8878 42.9707 54.1254 43.8767 75.7733C43.8767 75.8722 43.8767 75.9661 43.8767 76.06C43.961 77.9704 44.7811 79.7745 46.1658 81.0955C47.5505 82.4165 49.3929 83.1522 51.308 83.1491V83.1491Z"
            fill="url(#paint0_linear_1748_33047)"
          />
        </g>
        <path
          d="M49.6113 72.0607C57.2026 71.6916 64.794 71.3208 72.3854 70.9484V69.9004L49.6113 71.0671V72.0607Z"
          fill="#C8DFF9"
        />
        <path
          d="M49.6459 67.7947L83.4754 65.7925C83.4754 65.4366 83.4754 65.0757 83.4754 64.7148C72.2038 65.4168 60.9273 66.1122 49.6459 66.801C49.6459 67.1322 49.6459 67.4635 49.6459 67.7947Z"
          fill="#C8DFF9"
        />
        <path
          d="M49.8588 59.2373C65.9987 57.9585 82.1403 56.6649 98.2835 55.3565C98.2835 54.9808 98.3083 54.6101 98.3182 54.2344C82.1783 55.5856 66.0383 56.9187 49.8984 58.2337C49.8836 58.5699 49.8736 58.9011 49.8588 59.2373Z"
          fill="#C8DFF9"
        />
        <path
          d="M59.8447 51.0217L75.9499 49.4545C75.9499 49.0986 75.9945 48.7427 76.0143 48.3867L59.909 49.9934C59.8892 50.3345 59.8645 50.7053 59.8447 51.0217Z"
          fill="#C8DFF9"
        />
        <path
          d="M60.2407 47.8919C69.0665 46.9724 77.8922 46.0447 86.718 45.1087C86.7576 44.466 86.8022 43.8184 86.8468 43.1758C78.0243 44.1447 69.2001 45.1087 60.3743 46.0678L60.2407 47.8919Z"
          fill="#C8DFF9"
        />
        <path
          d="M76.648 70.7606L98.0705 69.7026V68.5508L76.648 69.6631V70.7606Z"
          fill="#C8DFF9"
        />
        <path
          d="M49.6459 75.8026L80.3414 74.5815V73.5137L49.6459 74.8089C49.6459 75.1401 49.6459 75.4713 49.6459 75.8026Z"
          fill="#C8DFF9"
        />
        <path
          d="M84.619 74.4339L98.1102 73.8901V72.7383L84.619 73.3167V74.4339Z"
          fill="#C8DFF9"
        />
        <path
          d="M88.3271 65.4765L98.0855 64.8931C98.0855 64.5075 98.0855 64.1219 98.0855 63.7363L88.3271 64.3493C88.3321 64.725 88.3271 65.1008 88.3271 65.4765Z"
          fill="#C8DFF9"
        />
        <path
          d="M49.7151 63.6015C65.8617 62.4941 82.0066 61.372 98.1498 60.2349C98.1498 59.8493 98.1498 59.4637 98.1746 59.0781C82.0346 60.2547 65.8914 61.4181 49.7449 62.5683L49.7151 63.6015Z"
          fill="#C8DFF9"
        />
        <path
          d="M52.3887 79.4817L100.977 77.9986L100.947 76.8418L52.364 78.4633C52.3739 78.7896 52.3838 79.1356 52.3887 79.4817Z"
          fill="#C8DFF9"
        />
        <path
          d="M49.6459 49.3935C49.537 50.9112 50.8193 52.0136 52.4976 51.8653C53.304 51.7938 54.062 51.4504 54.6469 50.8916C55.2317 50.3327 55.6087 49.5917 55.7157 48.7904C55.8246 47.248 54.5622 46.1456 52.8888 46.3187C51.2154 46.4917 49.735 47.8759 49.6459 49.3935Z"
          fill="#C8DFF9"
        />
        <path
          d="M152.496 16.9898C152.298 18.725 150.842 20.2724 149.243 20.4503C147.644 20.6283 146.505 19.343 146.698 17.6127C146.891 15.8825 148.352 14.3351 149.956 14.1522C151.56 13.9693 152.694 15.2447 152.496 16.9898Z"
          stroke="#85B4FE"
          stroke-width="0.7"
          stroke-miterlimit="10"
        />
        <path
          d="M150.466 16.7525C150.443 16.995 150.341 17.2232 150.176 17.4019C150.01 17.5807 149.79 17.6999 149.55 17.7413C149.446 17.7523 149.34 17.7384 149.242 17.7007C149.144 17.663 149.056 17.6025 148.986 17.5243C148.916 17.4461 148.865 17.3524 148.839 17.2509C148.812 17.1493 148.81 17.0429 148.832 16.9404C148.855 16.698 148.957 16.4697 149.122 16.291C149.288 16.1123 149.508 15.993 149.748 15.9517C149.853 15.9395 149.959 15.9526 150.058 15.99C150.156 16.0273 150.245 16.0878 150.315 16.1663C150.385 16.2448 150.436 16.339 150.462 16.441C150.488 16.543 150.49 16.6499 150.466 16.7525V16.7525Z"
          stroke="#85B4FE"
          stroke-width="0.7"
          stroke-miterlimit="10"
        />
        <path
          d="M149.332 19.63C149.893 19.5648 150.416 19.3142 150.817 18.9181C150.682 18.6982 150.485 18.5222 150.251 18.4117C150.017 18.3011 149.757 18.2607 149.5 18.2952C149.204 18.3397 148.919 18.4437 148.664 18.6009C148.409 18.758 148.188 18.9651 148.015 19.2097C148.192 19.3686 148.401 19.4882 148.628 19.5606C148.855 19.633 149.095 19.6567 149.332 19.63Z"
          stroke="#85B4FE"
          stroke-width="0.7"
          stroke-miterlimit="10"
        />
        <path
          d="M56.082 29.9415C56.082 29.8228 56.082 29.7586 56.082 29.6399L56.7751 29.551C56.852 28.2743 57.0342 27.006 57.3198 25.7592L56.6118 25.8581C56.6118 25.7395 56.6514 25.6801 56.6761 25.5565L59.4931 25.166C59.4931 25.2847 59.4586 25.3489 59.4338 25.4676L58.6565 25.5763C58.3709 26.8275 58.179 28.0983 58.0822 29.3779H58.2456C59.1516 29.2593 59.8843 28.8836 60.0279 27.8948C60.0279 27.8207 60.0279 27.7811 60.0576 27.702L60.4042 27.6576C60.2952 28.3645 60.2458 28.7155 60.1963 29.4224L56.082 29.9415Z"
          fill="#1076E6"
        />
        <path
          d="M65.5778 28.3945C64.9539 26.8373 64.7163 26.0611 64.2212 24.5039L63.6964 24.5781C62.7062 26.1798 62.1518 26.9806 61.2655 28.5823C61.2078 28.706 61.1192 28.8128 61.0082 28.8925C60.8972 28.9721 60.7676 29.0219 60.6318 29.0371H60.4932C60.4932 29.1607 60.4933 29.2201 60.4636 29.3436L62.122 29.1261C62.122 29.0025 62.1221 28.9432 62.1518 28.8196L61.8547 28.8592C61.6814 28.8592 61.528 28.8592 61.5527 28.6713C61.5673 28.6005 61.5959 28.5333 61.6369 28.4736C61.7557 28.2561 61.8151 28.1473 61.9389 27.9298L63.7459 27.6876C63.8747 28.0435 63.939 28.2165 64.0776 28.5675L63.4143 28.6565C63.4143 28.7801 63.4142 28.8394 63.3796 28.963L66.1174 28.6071C66.1174 28.4835 66.1175 28.4241 66.1521 28.3006L65.5778 28.3945ZM62.1121 27.6233C62.4933 26.9806 62.6963 26.6593 63.1023 26.0166H63.1618C63.34 26.5851 63.434 26.8669 63.6271 27.4354L62.1121 27.6233Z"
          fill="#1076E6"
        />
        <path
          d="M68.7563 28.2847C68.7563 28.1611 68.7563 28.0968 68.7959 27.9732H68.9097H68.994C69.1328 27.9707 69.2654 27.9149 69.3641 27.8173C69.4628 27.7197 69.5198 27.5879 69.5236 27.4492C69.6969 26.2727 69.8058 25.6844 70.0187 24.4831C70.0343 24.4234 70.034 24.3607 70.018 24.3011C70.002 24.2415 69.9707 24.1871 69.9272 24.1433C69.8838 24.0994 69.8297 24.0676 69.7702 24.0509C69.7107 24.0342 69.648 24.0332 69.5881 24.048H69.4692C69.4692 23.9244 69.4989 23.8602 69.5236 23.7366L71.504 23.4548C72.1971 24.4089 72.5387 24.8884 73.2467 25.8475C73.3754 25.0367 73.4448 24.6314 73.5686 23.8256C73.6082 23.5734 73.4447 23.4795 73.1031 23.529L72.9002 23.5586L72.9497 23.2422L74.628 23C74.628 23.1285 74.6034 23.1878 74.5836 23.3164H74.4201C74.2963 23.3191 74.1766 23.3613 74.0786 23.437C73.9805 23.5126 73.9093 23.6176 73.8755 23.7366C73.6279 25.3037 73.4794 26.0897 73.2814 27.6568L72.7121 27.731C71.7219 26.4259 71.2268 25.7536 70.3059 24.4583C70.1029 25.625 69.994 26.2084 69.8108 27.3751C69.7564 27.7458 69.9247 27.7854 70.3555 27.7261H70.4892C70.4892 27.8497 70.4891 27.9139 70.4495 28.0375L68.7563 28.2847Z"
          fill="#1076E6"
        />
        <path
          d="M78.9552 26.6385C78.3512 25.022 78.0938 24.2162 77.5146 22.5996L77.0195 22.6737C76.1234 24.3298 75.6283 25.1455 74.7025 26.8165C74.6437 26.9413 74.5544 27.0493 74.4427 27.1305C74.3311 27.2117 74.2008 27.2635 74.0638 27.2812H73.9202C73.9202 27.4097 73.8954 27.469 73.8806 27.5976L75.5392 27.38C75.5392 27.2564 75.5391 27.1922 75.5787 27.0636L75.2767 27.1032C75.1035 27.1279 74.9549 27.1032 74.9846 26.9054C75.0021 26.8332 75.0324 26.7646 75.0738 26.7028L75.3856 26.1392L77.168 25.9019C77.2918 26.2628 77.3511 26.4457 77.4799 26.8115L76.8214 26.9005C76.8214 27.029 76.8214 27.0933 76.7818 27.2169L79.4949 26.861C79.4949 26.7374 79.5197 26.6731 79.5346 26.5446L78.9552 26.6385ZM75.5639 25.8228C75.9451 25.1604 76.1332 24.8341 76.5095 24.1618H76.5689C76.7619 24.7501 76.861 25.0467 77.0639 25.6448L75.5639 25.8228Z"
          fill="#1076E6"
        />
        <path
          d="M84.2279 21.6211C84.1685 22.2885 84.1289 22.6098 84.0595 23.292L83.7279 23.3415C83.6784 22.679 83.4209 21.9276 82.6139 22.0463C81.599 22.1946 81.2871 23.1882 81.1286 24.3747C80.9702 25.5611 81.0543 26.4559 82.0841 26.3224C82.4771 26.2537 82.8361 26.0568 83.1049 25.7624C83.3737 25.4681 83.537 25.093 83.5694 24.696L83.9209 24.6466C83.8743 25.1602 83.6519 25.6421 83.2913 26.0112C82.9306 26.3803 82.4536 26.6142 81.9405 26.6734C81.6442 26.7322 81.3382 26.7199 81.0477 26.6374C80.7571 26.5548 80.4902 26.4046 80.2692 26.1989C80.0483 25.9932 79.8795 25.738 79.7767 25.4544C79.6739 25.1707 79.64 24.8667 79.6779 24.5675C79.813 23.8866 80.1425 23.2593 80.6265 22.7611C81.1105 22.2629 81.7284 21.9151 82.4059 21.7595C82.8564 21.6992 83.3149 21.7677 83.7279 21.9573C83.8962 21.9325 83.9407 21.7991 83.9605 21.6557L84.2279 21.6211Z"
          fill="#1076E6"
        />
        <path
          d="M84.3961 26.2395C84.3961 26.111 84.3962 26.0418 84.4308 25.9132L85.114 25.8243C85.2873 24.1929 85.3913 23.3772 85.5349 21.7458L84.8664 21.8397C84.8664 21.7112 84.8665 21.647 84.8962 21.5184L87.5053 21.1328V21.4591L86.8321 21.558C86.6934 23.1943 86.5993 24.0298 86.431 25.6512L87.1142 25.5623C87.1142 25.6908 87.1143 25.755 87.0796 25.8885L84.3961 26.2395Z"
          fill="#1076E6"
        />
        <path
          d="M90.5503 20.5489C89.8612 20.6958 89.2333 21.0489 88.7504 21.5612C88.2674 22.0735 87.9522 22.7206 87.847 23.4162C87.8144 23.7181 87.8519 24.0235 87.9565 24.3086C88.0611 24.5937 88.2301 24.8509 88.4504 25.0603C88.6706 25.2697 88.9362 25.4256 89.2265 25.516C89.5168 25.6064 89.8241 25.6288 90.1244 25.5815C90.825 25.449 91.4668 25.1016 91.9602 24.5876C92.4537 24.0736 92.7742 23.4187 92.8772 22.7142C92.9064 22.4086 92.8649 22.1004 92.7558 21.8134C92.6467 21.5263 92.4731 21.2682 92.2481 21.0589C92.0232 20.8496 91.7531 20.6948 91.4587 20.6064C91.1643 20.5179 90.8535 20.4983 90.5503 20.5489V20.5489ZM90.1591 25.2552C89.169 25.3887 89.1689 24.3258 89.2432 23.2234C89.3174 22.121 89.5551 21.0186 90.5354 20.8752C91.5157 20.7318 91.5701 21.7848 91.476 22.912C91.382 24.0391 91.1592 25.1217 90.1591 25.2552"
          fill="#1076E6"
        />
        <path
          d="M93.0751 25.092L93.0999 24.7608H93.2138H93.2979C93.3669 24.757 93.4344 24.7397 93.4966 24.7098C93.5588 24.6799 93.6146 24.638 93.6606 24.5866C93.7066 24.5351 93.742 24.4751 93.7647 24.41C93.7874 24.3449 93.797 24.2759 93.793 24.2071C93.897 22.9514 93.9465 22.3236 94.0405 21.0729C94.0554 21.0049 94.053 20.9342 94.0334 20.8674C94.0138 20.8006 93.9777 20.7398 93.9284 20.6906C93.8791 20.6413 93.8182 20.6053 93.7513 20.5857C93.6844 20.5661 93.6136 20.5636 93.5454 20.5785H93.4316C93.4316 20.445 93.4316 20.3758 93.4316 20.2473L95.4119 19.9606L97.3923 22.5312C97.4567 21.671 97.4864 21.2409 97.5508 20.3808C97.5508 20.1138 97.3923 20.0149 97.0557 20.0594L96.8478 20.0891L96.8724 19.7579L98.5707 19.5156V19.8518H98.4023C98.2769 19.8543 98.1562 19.8999 98.0606 19.981C97.965 20.062 97.9003 20.1736 97.8775 20.2967C97.7488 21.9676 97.6894 22.7982 97.5557 24.4691L96.9913 24.5433L94.3426 21.0383C94.2535 22.2791 94.204 22.902 94.1 24.1428C94.0703 24.5334 94.2336 24.5778 94.6644 24.5235H94.7931V24.8596L93.0751 25.092Z"
          fill="#1076E6"
        />
      </g>
      <path
        d="M29.5654 4.75029L24.9371 9.375L29.5654 13.9997L34.1937 9.37498L29.5654 4.75029Z"
        fill="#0250C9"
      />
      <path
        d="M27.1337 32.9944L22.5054 37.6191L27.1337 42.2438L31.7621 37.6191L27.1337 32.9944Z"
        fill="#0250C9"
      />
      <path
        d="M187.416 9.43479L185.234 11.6152L187.416 13.7957L189.598 11.6152L187.416 9.43479Z"
        fill="#0250C9"
      />
      <path
        d="M174.68 24.7199L172.498 26.9004L174.68 29.0809L176.862 26.9004L174.68 24.7199Z"
        fill="#0250C9"
      />
      <path
        d="M8.91669 19.4302L6.88239 21.4629L8.91671 23.4956L10.951 21.4629L8.91669 19.4302Z"
        fill="#0250C9"
      />
      <path
        d="M45.9382 0.000434951L43.9039 2.0332L45.9382 4.06595L47.9726 2.03318L45.9382 0.000434951Z"
        fill="#0250C9"
      />
      <path
        d="M47.3043 140.394L47.8319 140.32L49.7382 135.873H51.1319L53.0035 140.349L53.5012 140.399V141.2H50.9379V140.399H51.565L51.2514 139.583H49.2903L48.9716 140.399H49.6387V141.2H47.3192L47.3043 140.394ZM50.9179 138.778L50.4202 137.554L50.2658 137.057H50.2409L50.0668 137.554L49.569 138.778H50.9179Z"
        fill="#0250C9"
      />
      <path
        d="M54.117 140.393L54.7142 140.329V136.713H54.107V135.912H56.984C58.0691 135.912 59.0596 136.216 59.0596 137.484C59.0596 138.479 58.3827 139.085 56.9691 139.085H56.083V140.393H56.994V141.194H54.117V140.393ZM56.8396 138.27C56.9481 138.282 57.0581 138.27 57.1613 138.235C57.2645 138.199 57.3585 138.14 57.4359 138.063C57.5134 137.986 57.5725 137.893 57.6089 137.79C57.6452 137.687 57.658 137.577 57.6461 137.469C57.6461 136.922 57.3125 136.713 56.7301 136.713H56.083V138.265L56.8396 138.27Z"
        fill="#0250C9"
      />
      <path
        d="M59.8591 140.393L60.4564 140.329V136.713H59.8492V135.912H62.7262C63.8113 135.912 64.8018 136.216 64.8018 137.484C64.8018 138.479 64.1249 139.085 62.7112 139.085H61.8252V140.393H62.7362V141.194H59.8591V140.393ZM62.5768 138.265C62.6854 138.277 62.7953 138.265 62.8985 138.23C63.0017 138.194 63.0957 138.135 63.1731 138.058C63.2506 137.981 63.3097 137.888 63.3461 137.785C63.3824 137.682 63.3952 137.572 63.3833 137.464C63.3833 136.917 63.0497 136.708 62.4674 136.708H61.8203V138.265H62.5768Z"
        fill="#0250C9"
      />
      <path
        d="M17.9644 156.34C17.9644 154.594 18.9051 153.53 20.7119 153.53C21.3152 153.521 21.9172 153.588 22.5038 153.729V155.112H21.5083L21.4138 154.47C21.1996 154.438 20.9833 154.421 20.7667 154.42C19.821 154.42 19.4128 155.097 19.4128 156.131C19.4128 157.454 19.796 158.076 20.8463 158.076C21.0368 158.076 21.2269 158.058 21.4138 158.021L21.5233 157.405H22.5188V158.787C21.9108 158.921 21.2896 158.984 20.6672 158.976C18.8802 158.976 17.9644 157.982 17.9644 156.34Z"
        fill="#0250C9"
      />
      <path
        d="M23.3561 158.1L23.9534 158.036V154.42H23.3511V153.619H25.9942V154.42H25.3222V158.021H26.9847L27.0793 157.389H28.1196L28.0548 158.881H23.3561V158.1Z"
        fill="#0250C9"
      />
      <path
        d="M29.4047 157.056V154.42H28.8023V153.619H31.4255V154.42H30.7835V157.026C30.7692 157.164 30.7864 157.303 30.834 157.433C30.8816 157.563 30.9583 157.68 31.0581 157.776C31.1579 157.872 31.2783 157.944 31.4101 157.986C31.5419 158.029 31.6816 158.04 31.8187 158.021C31.9568 158.041 32.0975 158.029 32.2305 157.987C32.3635 157.945 32.4854 157.874 32.5868 157.778C32.6883 157.683 32.7668 157.565 32.8165 157.435C32.8662 157.305 32.8859 157.165 32.874 157.026V154.42H32.3513V153.619H34.6658V154.42H34.0685V157.061C34.0685 158.493 32.8889 158.991 31.6992 158.991C30.5096 158.991 29.4047 158.583 29.4047 157.056Z"
        fill="#0250C9"
      />
      <path
        d="M35.4167 158.1L36.0139 158.036V154.42H35.4117V153.619H38.4281C39.3589 153.619 40.2747 153.923 40.2747 154.987C40.2575 155.277 40.1424 155.554 39.948 155.77C39.7537 155.987 39.4917 156.132 39.2046 156.181V156.215C39.5005 156.233 39.7801 156.356 39.9929 156.563C40.2057 156.769 40.3375 157.044 40.3644 157.339C40.3644 158.473 39.553 158.901 38.448 158.901H35.4167V158.1ZM38.1543 155.808C38.6172 155.808 38.9159 155.614 38.9159 155.101C38.9159 154.589 38.4529 154.455 38.1344 154.455H37.3827V155.808H38.1543ZM38.1543 158.066C38.6172 158.066 38.9457 157.877 38.9457 157.354C38.9457 156.832 38.5724 156.638 38.1642 156.638H37.3927V158.066H38.1543Z"
        fill="#0250C9"
      />
      <path
        d="M12.5877 121.239L13.1849 121.174V117.559H12.5777V116.758H15.2258V117.559H14.5538V121.159H16.2163L16.3108 120.528H17.351L17.2864 122.02H12.5877V121.239Z"
        fill="#0250C9"
      />
      <path
        d="M19.9905 118.868C19.8899 118.711 19.8048 118.544 19.7367 118.371V118.371C19.7616 118.575 19.7716 118.982 19.7716 119.062V121.206H20.3888V122.016H18.1589V121.216L18.7561 121.151V117.535H18.1539V116.734H20.1997L22.0164 119.554C22.1236 119.726 22.2201 119.903 22.3051 120.087V120.087V119.375V117.535H21.7028V116.734H23.9029V117.535H23.3056V122.011H22.0064L19.9905 118.868Z"
        fill="#0250C9"
      />
      <path
        d="M35.3933 120.027H26.7026C26.5943 120.027 26.4905 119.984 26.4139 119.907C26.3374 119.831 26.2945 119.727 26.2945 119.619C26.2945 119.511 26.3374 119.407 26.4139 119.33C26.4905 119.254 26.5943 119.211 26.7026 119.211H35.3933C35.5015 119.211 35.6053 119.254 35.6819 119.33C35.7584 119.407 35.8014 119.511 35.8014 119.619C35.8014 119.727 35.7584 119.831 35.6819 119.907C35.6053 119.984 35.5015 120.027 35.3933 120.027V120.027Z"
        fill="#0250C9"
      />
      <path
        d="M32.3748 123.343C32.294 123.343 32.215 123.319 32.1479 123.274C32.0807 123.23 32.0283 123.166 31.9974 123.091C31.9665 123.017 31.9585 122.935 31.9742 122.856C31.99 122.776 32.0289 122.704 32.086 122.647L35.1124 119.623L32.086 116.594C32.0483 116.556 32.0183 116.512 31.9978 116.463C31.9773 116.414 31.9668 116.361 31.9668 116.308C31.9668 116.255 31.9773 116.202 31.9978 116.153C32.0183 116.104 32.0483 116.059 32.086 116.022C32.1239 115.984 32.1689 115.954 32.2185 115.933C32.268 115.913 32.3211 115.902 32.3748 115.902C32.4284 115.902 32.4815 115.913 32.531 115.933C32.5806 115.954 32.6255 115.984 32.6634 116.022L35.9785 119.334C36.0164 119.372 36.0464 119.417 36.0669 119.467C36.0875 119.516 36.098 119.569 36.098 119.623C36.098 119.676 36.0875 119.729 36.0669 119.779C36.0464 119.828 36.0164 119.873 35.9785 119.911L32.6634 123.224C32.6255 123.262 32.5804 123.292 32.5309 123.312C32.4814 123.333 32.4284 123.343 32.3748 123.343Z"
        fill="#0250C9"
      />
      <path
        d="M193.312 158.516H191.625C191.625 158.516 195.572 150.116 202.118 146.137C193.9 144.864 192.854 148.007 192.854 148.007C196.637 135.255 187.414 115.32 179.833 129.694C168.042 116.867 155.115 117.757 162.148 138.085C167.624 153.861 187.414 158.516 187.414 158.516H113.568"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M183.789 130.686C183.789 130.686 184.784 150.878 188.209 155.663C175.427 150.252 167.607 132.844 167.607 132.844"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M179.833 129.701C179.833 129.701 176.702 136.376 180.33 144.418"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M172.228 127.354C169.296 134.346 176.14 146.144 176.14 146.144"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M188.209 140.289C186.278 141.856 185.72 148.013 185.72 148.013Z"
        fill="#126FFF"
      />
      <path
        d="M188.209 140.289C186.278 141.856 185.72 148.013 185.72 148.013"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M170.629 149.987C161.789 149.708 159.962 154.279 159.962 154.279C159.962 154.279 172.063 158.522 177.881 158.522"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M177.905 156.289C163.898 149.654 159.986 154.3 159.986 154.3"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path
        d="M108.342 158.611H104.982"
        stroke="#0250C9"
        stroke-miterlimit="10"
      />
      <path d="M99.8938 158.652H55.4" stroke="#0250C9" stroke-miterlimit="10" />
      <path
        d="M41.2886 142.339H32.0553C31.5123 142.358 30.9711 142.267 30.4638 142.073C29.9565 141.878 29.4935 141.584 29.1026 141.206C28.7117 140.829 28.4009 140.377 28.1885 139.878C27.9762 139.378 27.8668 138.84 27.8668 138.297C27.8668 137.755 27.9762 137.217 28.1885 136.717C28.4009 136.218 28.7117 135.766 29.1026 135.389C29.4935 135.011 29.9565 134.717 30.4638 134.522C30.9711 134.328 31.5123 134.237 32.0553 134.256H41.2886C41.8317 134.237 42.373 134.328 42.8803 134.522C43.3876 134.717 43.8504 135.011 44.2413 135.389C44.6322 135.766 44.9432 136.218 45.1555 136.717C45.3679 137.217 45.4772 137.755 45.4772 138.297C45.4772 138.84 45.3679 139.378 45.1555 139.878C44.9432 140.377 44.6322 140.829 44.2413 141.206C43.8504 141.584 43.3876 141.878 42.8803 142.073C42.373 142.267 41.8317 142.358 41.2886 142.339ZM32.0553 135.072C31.1973 135.072 30.3743 135.413 29.7676 136.019C29.1608 136.625 28.82 137.448 28.82 138.305C28.82 139.162 29.1608 139.985 29.7676 140.591C30.3743 141.197 31.1973 141.538 32.0553 141.538H41.2886C42.1467 141.538 42.9696 141.197 43.5764 140.591C44.1831 139.985 44.524 139.162 44.524 138.305C44.524 137.448 44.1831 136.625 43.5764 136.019C42.9696 135.413 42.1467 135.072 41.2886 135.072H32.0553Z"
        fill="#0250C9"
      />
      <path
        d="M41.1686 140.717C42.5047 140.717 43.5877 139.635 43.5877 138.3C43.5877 136.965 42.5047 135.883 41.1686 135.883C39.8326 135.883 38.7496 136.965 38.7496 138.3C38.7496 139.635 39.8326 140.717 41.1686 140.717Z"
        fill="#0250C9"
      />
      <path
        d="M13.7275 158.895H4.76798C4.68866 158.903 4.60864 158.894 4.53308 158.868C4.45753 158.843 4.38807 158.802 4.32917 158.749C4.27027 158.695 4.2232 158.63 4.191 158.557C4.15881 158.484 4.14215 158.405 4.14215 158.326C4.14215 158.246 4.15881 158.168 4.191 158.095C4.2232 158.022 4.27027 157.957 4.32917 157.903C4.38807 157.849 4.45753 157.809 4.53308 157.783C4.60864 157.758 4.68866 157.749 4.76798 157.756H13.7275C13.8068 157.749 13.8867 157.758 13.9623 157.783C14.0378 157.809 14.1073 157.849 14.1662 157.903C14.2251 157.957 14.2721 158.022 14.3043 158.095C14.3365 158.168 14.3532 158.246 14.3532 158.326C14.3532 158.405 14.3365 158.484 14.3043 158.557C14.2721 158.63 14.2251 158.695 14.1662 158.749C14.1073 158.802 14.0378 158.843 13.9623 158.868C13.8867 158.894 13.8068 158.903 13.7275 158.895V158.895Z"
        fill="#0250C9"
      />
      <path
        d="M8.49892 156.388H4.71598C4.57401 156.374 4.44208 156.309 4.34607 156.203C4.25006 156.098 4.19684 155.961 4.19684 155.818C4.19684 155.676 4.25006 155.538 4.34607 155.433C4.44208 155.328 4.57401 155.262 4.71598 155.249H8.49892C8.57824 155.241 8.65814 155.25 8.7337 155.276C8.80925 155.301 8.87871 155.342 8.93761 155.395C8.99651 155.449 9.04358 155.514 9.07578 155.587C9.10797 155.66 9.12463 155.738 9.12463 155.818C9.12463 155.898 9.10797 155.976 9.07578 156.049C9.04358 156.122 8.99651 156.187 8.93761 156.241C8.87871 156.295 8.80925 156.335 8.7337 156.36C8.65814 156.386 8.57824 156.395 8.49892 156.388Z"
        fill="#0250C9"
      />
      <path
        d="M2.00718 160C1.9019 159.996 1.79955 159.964 1.71164 159.906C1.62374 159.848 1.55374 159.767 1.50943 159.671C1.46961 159.592 1.43479 159.512 1.39994 159.433C1.05838 158.617 0.882385 157.743 0.882385 156.859C0.882385 155.975 1.05838 155.1 1.39994 154.285C1.90663 153.087 2.75516 152.066 3.8396 151.347C4.92404 150.629 6.19631 150.246 7.49738 150.246C8.79845 150.246 10.0706 150.629 11.155 151.347C12.2395 152.066 13.0881 153.087 13.5948 154.285C13.7336 154.607 13.8467 154.94 13.9332 155.28C13.9575 155.422 13.9266 155.569 13.8468 155.69C13.7671 155.811 13.6445 155.897 13.5037 155.931C13.3628 155.965 13.2142 155.945 13.088 155.874C12.9617 155.803 12.8671 155.686 12.8233 155.548C12.7569 155.262 12.6653 154.983 12.5495 154.713C12.2732 154.063 11.8743 153.472 11.3747 152.972C10.478 152.066 9.29314 151.502 8.02441 151.376C6.75569 151.25 5.48275 151.57 4.42509 152.281C3.36743 152.993 2.59144 154.051 2.2309 155.273C1.87036 156.495 1.94786 157.804 2.45013 158.975C2.45013 159.04 2.50485 159.104 2.53969 159.174C2.57159 159.242 2.5899 159.315 2.5934 159.389C2.5969 159.464 2.58554 159.539 2.5601 159.609C2.53466 159.679 2.49563 159.744 2.44514 159.799C2.39466 159.854 2.33381 159.899 2.26602 159.93C2.18608 159.972 2.0976 159.996 2.00718 160Z"
        fill="#0250C9"
      />
      <defs>
        <filter
          id="filter0_d_1748_33047"
          x="68.895"
          y="6.12695"
          width="90.4428"
          height="153.865"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1748_33047"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1748_33047"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1748_33047"
          x="39.7084"
          y="4.75391"
          width="74.134"
          height="86.3945"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1748_33047"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1748_33047"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1748_33047"
          x1="56.6302"
          y1="87.3808"
          x2="105.659"
          y2="2.34291"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.11" stop-color="#EDF6FF" />
          <stop offset="0.32" stop-color="white" />
        </linearGradient>
        <clipPath id="clip0_1748_33047">
          <rect
            width="119.302"
            height="147.239"
            fill="white"
            transform="translate(43.6949 4.75391)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConfirmacionConTarjetaClub;
