import React from "react";
const UserUnlock = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M14 14.252v2.09A6 6 0 006 22l-2-.001a8 8 0 0110-7.747zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
      ></path>
      <path
        fill="#272727"
        stroke="#272727"
        d="M16.778 17.577h5.778a.443.443 0 01.444.447v4.47a.448.448 0 01-.444.447h-7.112a.443.443 0 01-.444-.447v-4.47a.448.448 0 01.444-.447h.445v-.447c0-.705.236-1.39.671-1.942a3.095 3.095 0 013.759-.893 3.12 3.12 0 011.464 1.436l-.795.4a2.228 2.228 0 00-1.046-1.026 2.21 2.21 0 00-2.685.637 2.25 2.25 0 00-.48 1.388v.447zm-.89.894v3.576h6.223v-3.576H15.89z"
      ></path>
    </svg>
  );
};

export default UserUnlock;
