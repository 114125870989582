import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorLogin } from '@lana-ui/login';
import { useDispatch, useStore } from '../Store/storeProvider';
import ErrorGral from '../Statics/Imgs/loginImg/error-gral.png';
import UserError from '../Statics/Imgs/loginImg/user-error.png';
import CodeError from '../Statics/Imgs/loginImg/code-error.png';
import CredencialError from '../Statics/Imgs/loginImg/credencial-error.png';
import EmailError from '../Statics/Imgs/loginImg/email-error.png';

const useHandleErrorPageView = (config) => {
    const [data, setData] = useState(null);
    const { User } = useStore();
    const dispatch = useDispatch();
    let history = useHistory();

    const handleLocationInicio = () => {
        dispatch({
            type: 'ERROR PAGE COMPONENT',
            value: false,
        });
        history.push('/login/ingresar');
    };

    useEffect(() => {
        const defaultElemnt = (
            <ErrorLogin
                title="Ha ocurrido un error"
                text="Por favor, intentalo nuevamente"
                buttonText="Ingresar"
                imageUrl={ErrorGral}
                onClick={handleLocationInicio}
                imageWidth="220px"
            ></ErrorLogin>
        );

        const caseElement = {
            'error generico': (
                <ErrorLogin
                    title="Ha ocurrido un error"
                    text="Por favor, intentalo nuevamente"
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                    imageUrl={ErrorGral}
                    imageWidth="220px"
                ></ErrorLogin>
            ),

            'no existe usuario': (
                <ErrorLogin
                    title="Tu usuario fue suspendido"
                    text="Para ingresar registrate con otro email"
                    buttonText="Registrate"
                    imageUrl={UserError}
                    imageWidth="220px"
                ></ErrorLogin>
            ),

            'reenvio de cod limite': (
                <ErrorLogin
                    title="Alcanzaste el límite de intentos"
                    text="Podrás volver a intentarlo dentro de 24 horas"
                    buttonText="VOLVER A HOME"
                    onClick={() => {
                        window.location.href = `${process.env.REACT_APP_LNREDIRECT}`;
                    }}
                    imageUrl={CodeError}
                    imageWidth="300px"
                ></ErrorLogin>
            ),

            'superaste limite de intentos': (
                <ErrorLogin
                    title="Alcanzaste el límite de intentos"
                    text="Volvé a intentarlo en unos minutos."
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                    imageUrl={ErrorGral}
                    imageWidth="300px"
                ></ErrorLogin>
            ),

            'error mail ya vinculado': (
                <ErrorLogin
                    title={`La credencial está vinculada con el email ${config?.email}`}
                    text="Si es tuyo, ingresá con ese email"
                    buttonText="Volver"
                    onClick={handleLocationInicio}
                    imageUrl={CredencialError}
                    imageWidth="220px"
                ></ErrorLogin>
            ),

            'error vinculacion': (
                <ErrorLogin
                    title="No se pudo vincular tu credencial correctamente"
                    text="Por favor, intentalo nuevamente"
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                    imageUrl={CredencialError}
                    imageWidth="220px"
                ></ErrorLogin>
            ),

            'limite de intentos codigo': (
                <ErrorLogin
                    title="Alcanzaste el límite de intentos"
                    text="Podrás volver a intentarlo dentro de 24 horas"
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                    imageUrl={CodeError}
                    imageWidth="300px"
                ></ErrorLogin>
            ),

            'email ya registrado': (
                <ErrorLogin
                    title="Bienvenido a LA NACION"
                    text={`Ya estas registrado con la cuenta  ${User.Email}`}
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                ></ErrorLogin>
            ),

            'falta campo usuario': (
                <ErrorLogin
                    title="Ha ocurrido un error"
                    text="Debe completar todos los datos para darse de alta."
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                ></ErrorLogin>
            ),

            'error recaptcha': (
                <ErrorLogin
                    title="Ha ocurrido un error"
                    text="Captcha inválido."
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                ></ErrorLogin>
            ),

            'email ya existe': (
                <ErrorLogin
                    title="Ha ocurrido un error"
                    text="el email ya está registrado."
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                ></ErrorLogin>
            ),

            'email no se envio': (
                <ErrorLogin
                    title="Ha ocurrido un error"
                    text="al enviar el email al nuevo usuario registrado."
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                    imageUrl={EmailError}
                    imageWidth="220px"
                ></ErrorLogin>
            ),

            'recuperar pass no se envio mail': (
                <ErrorLogin
                    title="Ha ocurrido un error"
                    text="al enviar el email al querer recuperar contraseña."
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                    imageUrl={EmailError}
                    imageWidth="220px"
                ></ErrorLogin>
            ),

            'codigo invalido': (
                <ErrorLogin
                    title="Ha ocurrido un error"
                    text="Codigo verificacion invalido."
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                    imageUrl={CodeError}
                    imageWidth="300px"
                ></ErrorLogin>
            ),

            'enlace caducado': (
                <ErrorLogin
                    title="El link ya no se encuentra vigente"
                    text=""
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                ></ErrorLogin>
            ),

            'no se actualiza pass': (
                <ErrorLogin
                    title="No se pudo actualizar la contraseña"
                    text=""
                    buttonText="Ingresar"
                    onClick={handleLocationInicio}
                ></ErrorLogin>
            ),
        };

        const Element =
            caseElement[config?.typeError] ||
            caseElement[config] ||
            defaultElemnt;

        setData(Element);
    }, []);

    return [data];
};

export default useHandleErrorPageView;
