import React from "react";
const Shop = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 23 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M20 11.242V18h1v2H1v-2h1v-6.758A4.496 4.496 0 010 7.5c0-.827.224-1.624.633-2.303L3.345.5a1 1 0 01.866-.5H17.79a1 1 0 01.866.5l2.702 4.682A4.496 4.496 0 0120 11.242zm-2 .73a4.498 4.498 0 01-3.75-1.36 4.497 4.497 0 01-6.5.002A4.495 4.495 0 014 11.973V18h14v-6.028zM4.789 2L2.356 6.213a2.5 2.5 0 004.466 2.216c.335-.837 1.52-.837 1.856 0a2.5 2.5 0 004.644 0c.335-.837 1.52-.837 1.856 0a2.5 2.5 0 104.457-2.232L17.21 2H4.789z"
      ></path>
    </svg>
  );
};

export default Shop;
