import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import { useStore } from '../../Store/storeProvider';

const Auth0LogoutCallback = () => {
    const { Config } = useStore();
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        location.href = Config.URLCallback
        setIsLoading(false)
    }, []);

    return (
        <div className='callback-view'>
            <LoadingSpinner isLoading={isLoading}>
            </LoadingSpinner>
        </div>
    );
}

export default Auth0LogoutCallback;