import React from 'react';
import { Modal, Text } from '@lana-ui/common';
import { Button } from '@lana-ui/login';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_modal-login.scss';

const propTypes = {
	/**
	 * Texto del modal
	 */
	text: PropTypes.string,
	/**
	 * Email de la persona
	 */
	email: PropTypes.string,
	/**
	 * Clase del primer botón
	 */
	firstButtonClassname: PropTypes.string,
	/**
	 * Clase del segundo botón
	 */
	secondButtonClassname: PropTypes.string,
	/**
	 * Texto del primer botón
	 */
	firstButtonText: PropTypes.string,
	/**
	 * Texto del segundo botón
	 */
	secondButtonText: PropTypes.string,
	/**
	 * Nombre del ícono en el caso de tratarse de un botón de red social
	 */
	icon: PropTypes.string,
	/**
	 * Debe ir en true en el caso de tratarse de un botón de red social
	 */
	isFirstPositioned: PropTypes.bool,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const ModalLogin = ({
	text,
	email,
	secondButtonClassname,
	secondButtonText,
	setShowModal,
	emailOnTitle,
	buttonsDisplayFlex,
	subText,
	children,
	className,
	width,
	secondButtonOnClick,
}) => {
	const classes = classNames('lgn-modal', className);

	return (
		<Modal className={classes} closeModal={() => setShowModal(false)} style={{width:width}}>
			<Text
				className={
					buttonsDisplayFlex
						? '--sueca modal-text --font-bold'
						: subText
						? '--sueca modal-text'
						: '--sueca modal-text-without-subtext'
				}
				size="l"
			>
				{text} {emailOnTitle && email && <strong>{email}</strong>}
			</Text>

			{subText && (
				<Text size="2xs" className={!buttonsDisplayFlex && 'modal-subtext'}>
					{subText} {email && <strong>{email}</strong>}
				</Text>
			)}

			<div className={buttonsDisplayFlex ? 'modal-buttons' : 'modal-buttons-grid'}>
				{children}

				<Button
					className={secondButtonClassname ? secondButtonClassname : ''}
					label={secondButtonText}
					onClick={secondButtonOnClick ? secondButtonOnClick : () => setShowModal(false) }
				/>
			</div>
		</Modal>
	);
};

ModalLogin.propTypes = propTypes;

export default ModalLogin;
