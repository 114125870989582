import {
  DefaultExpirationMinutes,
  DiccionarioCookiesAGuardarBorrar,
  ExpirationDays,
  Map,
} from "../Config/Cookies";

let domain = process.env.REACT_APP_COOKIE_DOMAIN || "localhost";

function borrarCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (DiccionarioCookiesAGuardarBorrar.indexOf(name.trim()) > -1) {
      removeCookie(name);
    }
  }
}

function removeCookie(name) {
  var cookie = readCookie(name);
  if (!cookie) {
    name = name.trim();
    cookie = readCookie(name);
  }
  if (cookie) {
    if (document.domain === "localhost") {
      document.cookie =
        name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    } else {
      document.cookie =
        name +
        "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
        domain;
    }
  }
}

function readCookie(nombrecookie) {
  var nameEQ = nombrecookie + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function borrarAlgunasCookies(arr) {
  DiccionarioCookiesAGuardarBorrar.forEach((cookieABorrar) => {
    if (arr.find((x) => x == cookieABorrar)) {
      removeCookie(cookieABorrar);
      //Cookies.remove(cookieABorrar, { path: '/', domain });
    }
  });
}

function borrarCustomCookies(arr) {
  arr.forEach((cookie) => {
    removeCookie(cookie);
    //Cookies.remove(cookie, { path: '/', domain })
  });
}

function setupCookies(user) {
  let keys = Object.keys(user);
  for (let index = 0; index < keys.length; index++) {
    let map = Map(keys[index]);
    if (DiccionarioCookiesAGuardarBorrar.find((x) => x == map)) {
      createCookie(map, user[keys[index]]);
    }
  }
}

function createCookie(name, value, expires = ExpirationDays) {
  const expiration = new Date();
  expiration.setDate(expiration.getDate() + expires);
  createCookieGeneric(name, value, expiration);
}

function createCookieByMinutes(name, value, expires = DefaultExpirationMinutes) {
  const expiration = new Date();
  expiration.setTime(expiration.getTime() + expires * 60 * 1000);
  createCookieGeneric(name, value, expiration);
}

const createCookieGeneric = (name, value, expiration) => {
  if (document.domain === "localhost") {
    document.cookie =
      name + "=" + value + ";expires=" + expiration.toUTCString() + "; path=/";
  } else {
    document.cookie =
      name +
      "=" +
      value +
      ";expires=" +
      expiration.toUTCString() +
      "; path=/; domain=" +
      domain;
  }
}
export {
  borrarCookies,
  borrarAlgunasCookies,
  setupCookies,
  createCookie,
  createCookieByMinutes,
  borrarCustomCookies,
  readCookie,
};
