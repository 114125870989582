import React from "react";
const Lock = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 19"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M15.111 7.6h.945a.95.95 0 01.944.95v9.5c0 .252-.1.494-.277.672a.942.942 0 01-.667.278H.944a.94.94 0 01-.667-.278A.953.953 0 010 18.05v-9.5c0-.252.1-.494.277-.672A.942.942 0 01.944 7.6h.945v-.95a6.684 6.684 0 011.936-4.702 6.578 6.578 0 019.35 0A6.654 6.654 0 0115.11 6.65v.95zM1.89 9.5v7.6h13.22V9.5H1.89zm5.667 1.9h1.888v3.8H7.556v-3.8zm5.666-3.8v-.95c0-1.26-.497-2.468-1.383-3.359A4.708 4.708 0 008.5 1.9a4.71 4.71 0 00-3.34 1.391A4.764 4.764 0 003.779 6.65v.95h9.444z"
      ></path>
    </svg>
  );
};

export default Lock;
