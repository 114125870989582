import React from "react";
const EmailOpenFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M.243 5.854L9.49.31a1 1 0 011.029 0l9.238 5.545a.5.5 0 01.243.429V19a1 1 0 01-1 1H1a1 1 0 01-1-1V6.283a.5.5 0 01.243-.429zm16.103 1.39l-6.285 5.439-6.414-5.445-1.294 1.524 7.72 6.555 7.581-6.56-1.308-1.513z"
      ></path>
    </svg>
  );
};

export default EmailOpenFilled;
