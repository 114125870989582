import React from 'react';
import { Title } from '@lana-ui/common';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_textLogin.scss';

const propTypes = {
	/**
	 * Título de la página
	 */
	title: PropTypes.string,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const TextLogin = ({ title, children, className }) => {
	const classes = classNames('lgn-text', className);

	return (
		<div className={classes}>
			<Title className="--sueca --font-bold" size="l">
				{title}
			</Title>
			{children}
		</div>
	);
};

TextLogin.propTypes = propTypes;

export default TextLogin;
