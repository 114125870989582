import React from "react";
const Unlock = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 19"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M3.778 7.601h12.278a.953.953 0 01.944.95v9.499c0 .252-.1.494-.277.672a.942.942 0 01-.667.278H.944a.94.94 0 01-.667-.278A.953.953 0 010 18.05V8.551c0-.252.1-.493.277-.671a.942.942 0 01.667-.28h.945v-.95c0-1.498.502-2.952 1.426-4.127A6.607 6.607 0 016.981.178a6.577 6.577 0 014.322.45 6.63 6.63 0 013.111 3.05l-1.69.849a4.735 4.735 0 00-2.222-2.18 4.698 4.698 0 00-3.087-.32 4.72 4.72 0 00-2.619 1.676A4.767 4.767 0 003.778 6.65v.95zm-1.89 1.9v7.6h13.223V9.5H1.89zm4.723 2.85h3.778v1.9H6.61v-1.9z"
      ></path>
    </svg>
  );
};

export default Unlock;
