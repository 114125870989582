import React from "react";
const Cursor = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M15.388 13.498l2.552 7.014-4.698 1.71-2.553-7.014-3.899 2.445 1.62-16.02 11.537 11.232-4.558.633h-.001zm-.011 5.818l-2.715-7.46 2.96-.41-5.64-5.49-.79 7.83 2.53-1.587 2.715 7.46.94-.343z"
      ></path>
    </svg>
  );
};

export default Cursor;
