import React from "react";
const Sections = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M5.002 3H9a2 2 0 012 2v4a2 2 0 01-2 2l-4.058-.001-.175-.012A2 2 0 013 9l.001-4a2 2 0 012-2zM9 5H5.002L5 9h4V5zm-3.998 8H9a2 2 0 012 2v4a2 2 0 01-2 2l-4.058-.001-.175-.012A2 2 0 013 19l.001-4a2 2 0 012-2zM9 15H5.002L5 19h4v-4zM19 3h-3.998a2 2 0 00-2 2L13 9a2 2 0 001.767 1.987l.175.012L19 11a2 2 0 002-2V5a2 2 0 00-2-2zm-3.998 2H19v4h-4l.002-4zm0 8H19a2 2 0 012 2v4a2 2 0 01-2 2l-4.058-.001-.175-.012A2 2 0 0113 19l.001-4a2 2 0 012-2zM19 15h-3.998L15 19h4v-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Sections;
