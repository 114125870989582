import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import * as storage from '../../Helpers/Auth0StorageManager';
import { useStore } from '../../Store/storeProvider';

const Auth0CallbackResetPassword = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { Config } = useStore();

    useEffect(() => {
        const urlCallbackResetPassword = storage.getCallbackResetPassword();
        storage.deleteCallbackResetPassword();
        window.location.href = `${window.location.origin}/auth0-login/${Config.Modo}/${Config.SitioId}/?callback=${window.btoa(urlCallbackResetPassword)}`
        setIsLoading(false)
    }, []);

    return (
        <div className='callback-view'>
            <LoadingSpinner isLoading={isLoading}>
            </LoadingSpinner>
        </div>
    );
}
export default Auth0CallbackResetPassword;