import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Divider } from '@lana-ui/common';
import './_divider.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const DividerLogin = ({ children, className }) => {
	const classes = classNames('--divider', className);

	return (
		<div className={classes}>
			{children ? (
				<>
					<Divider width="45" />
					{children} <Divider width="45" />
				</>
			) : (
				<Divider width="100" />
			)}
		</div>
	);
};

DividerLogin.propTypes = propTypes;

export default DividerLogin;
