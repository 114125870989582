import React from "react";
const Tiktok = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M17.5 8.202a8.208 8.208 0 01-4.798-1.534v6.983a6.351 6.351 0 11-5.479-6.293v3.512a2.916 2.916 0 102.041 2.781V0h3.438c-.002.29.022.58.073.866a4.774 4.774 0 002.107 3.135 4.744 4.744 0 002.617.787v3.414z"
      ></path>
    </svg>
  );
};

export default Tiktok;
