export const steps = {
  step_1: {
    number: 1,
    text: "Completá tus datos",
  },
  step_2: {
    number: 2,
    text: "Activá tu cuenta",
  },
  step_3: {
    number: 3,
    text: "Vinculá Club LA NACION",
  },
};
export const descriptions = [
  {
    status: "",
    text: "Debe tener al menos 8 caracteres.",
  },
  {
    status: "",
    text: "Debe incluir al menos un número. ",
  },
  {
    status: "",
    text: "Debe incluir al menos una letra. ",
  },
  {
    status: "",
    text: "No debe incluir comillas simples o dobles",
  },
];
export const stateEmail = (state, emailError) => ({
  status: `${state}`, // 'error' ó ''
  msgs: [
    {
      status: "error", // 'error' ó ''
      text: ` ${emailError} `,
    },
  ],
});
export const statePassword = (state) => ({
  msgs: [
    {
      status: "error", // 'error' ó 'success'
      text: "Debe tener al menos 8 caracteres",
    },
    {
      status: "success", // 'error' ó 'success'
      text: "Debe incluir al menos un número.",
    },
    {
      status: "error", // 'error' ó 'success'
      text: "Debe incluir al menos una letra.",
    },
    {
      status: "success", // 'error' ó 'success'
      text: "NO debe incluir caracteres especiales.",
    },
  ],
  status: `${state}`, // 'error' ó 'success'
});
export const stateCode = (state, textCodeError) => ({
  status: `${state}`, // 'error' ó ''
  msgs: [
    {
      status: "error", // 'error' ó ''
      text: ` ${textCodeError} `,
    },
  ],
});
export const stateNroDocumento = (state, documentoError) => ({
  status: `${state}`, // 'error' ó 'success'
  msgs: [
    {
      status: "error", // 'error' ó 'success'
      text: ` ${documentoError} `,
    },
  ],
});
export const stateFirstInput = (state, textError) => ({
  status: `${state}`, // 'error' ó 'success'
  msgs: [
    {
      status: "error", // 'error' ó 'success'
      text: ` ${textError} `,
    },
  ],
});
export const stateSecondInput = (state, textError) => ({
  status: `${state}`, // 'error' ó 'success'
  msgs: [
    {
      status: "error", // 'error' ó 'success'
      text: ` ${textError} `,
    },
  ],
});
export const stateThirdInput = (state, textError) => ({
  status: `${state}`, // 'error' ó 'success'
  msgs: [
    {
      status: "error", // 'error' ó 'success'
      text: ` ${textError} `,
    },
  ],
});
