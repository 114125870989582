import React from "react";
const Twitch = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 19"
      {...r}
    >
      <path
        fill="#272727"
        d="M18 0v11.74l-4.696 4.695H9.391l-2.437 2.348H3.913v-2.348H0V3.13L1.227 0H18zm-1.565 1.565H3.13v11.74h3.13v2.347l2.349-2.348h4.695l3.13-3.13V1.565h.001zm-3.13 3.13v4.696h-1.566V4.696h1.565l.001-.001zm-3.914 0v4.696H7.826V4.696h1.565v-.001z"
      ></path>
    </svg>
  );
};

export default Twitch;
