import React from "react";
const ClearNight = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 22"
      {...r}
    >
      <path
        fill="#272727"
        d="M8 6a8 8 0 0011.955 6.956C19.474 18.03 15.2 22 10 22 4.477 22 0 17.523 0 12c0-5.2 3.97-9.474 9.044-9.955A7.963 7.963 0 008 6zm-6 6a8 8 0 0014.957 3.955c-.316.03-.636.045-.957.045-5.523 0-10-4.477-10-10 0-.321.015-.64.045-.957A8.006 8.006 0 002 12zm14.164-9.709L17 2.5v1l-.836.209a2 2 0 00-1.455 1.455L14.5 6h-1l-.209-.836a2 2 0 00-1.455-1.455L11 3.5v-1l.836-.209A2 2 0 0013.29.836L13.5 0h1l.209.836a2 2 0 001.455 1.455zm5 5L22 7.5v1l-.836.209a2 2 0 00-1.455 1.455L19.5 11h-1l-.209-.836a2 2 0 00-1.455-1.455L16 8.5v-1l.836-.209a2 2 0 001.455-1.455L18.5 5h1l.209.836a2 2 0 001.455 1.455z"
      ></path>
    </svg>
  );
};

export default ClearNight;
