// modo (char) = (D) - default / (S) - signwall / (B) - black / (P) - paywall

const ModosUrl = {
    default: 'D',
    signwall: 'S',
    black: 'B',
    paywall: 'P',
};

export default ModosUrl;
