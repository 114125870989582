import React from "react";
const Comment = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M16.709 20.824L22 22l-1.176-5.291A9.955 9.955 0 0022 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10a9.955 9.955 0 004.709-1.176zm-.29-2.113l-.653.35A7.956 7.956 0 0112 20a8 8 0 118-8 7.946 7.946 0 01-.94 3.766l-.349.653.655 2.947-2.947-.655z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Comment;
