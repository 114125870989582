import React from "react";
const Facebook = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M11 17.938A8.001 8.001 0 0010 2a8 8 0 00-1 15.938V12H7v-2h2V8.346c0-1.337.14-1.822.4-2.311A2.72 2.72 0 0110.536 4.9c.382-.205.857-.328 1.687-.381.329-.021.755.005 1.278.08v1.9H13c-.917 0-1.296.043-1.522.164a.727.727 0 00-.314.314c-.12.226-.164.45-.164 1.368V10h2.5l-.5 2h-2v5.938zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"
      ></path>
    </svg>
  );
};

export default Facebook;
