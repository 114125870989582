import { useEffect, useState } from 'react';
import { trackCustomEvent } from '../Config/DataDog';
import { useAuth0 } from '@auth0/auth0-react';
import { DeleteSession } from '@ln/user.client.libs'

const useVerifySessionAuth0 = (callbackFunc) => {
    const [isLoadingProcess, setIsLoadingProcess] = useState(false);
    const { isAuthenticated, getAccessTokenSilently, isLoading, getIdTokenClaims } = useAuth0();

    useEffect(async () => {
        try {
            setIsLoadingProcess(true);

            if (!isLoading) {
                let isVerified = false;
                if (isAuthenticated) {
                    await getAccessTokenSilently({ ignoreCache: true });
                    const idTokenClaims = await getIdTokenClaims();
                    isVerified = idTokenClaims.email_verified;
                }
                await callbackFunc(isVerified);
                setIsLoadingProcess(false);
            }

        } catch (error) {
            trackCustomEvent("logout", true, { message: `useVerifySessionAuth0 - ${error}` })
            DeleteSession();
        }
    }, [isLoading]);

    return [isLoadingProcess]
}

export default useVerifySessionAuth0;