
const removeItem = function (key) {
    window.localStorage.removeItem(key);
}

const setExpiredItem = function (key, value) {
    let item = {
        'value': value,
        'expiration': new Date()
    };

    window.localStorage.setItem(key, JSON.stringify(item));
}
/**
 * 
 * @param {number} expirationDays 1 por defecto
 */
const setExpiredItemByDays = function (key, value, expirationDays = 1) {
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + expirationDays);

    let item = {
        'value': value,
        'expiration': expiration
    };

    window.localStorage.setItem(key, JSON.stringify(item));
}

const setItem = function (key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
}
const getItem = function (key) {
    return JSON.parse(window.localStorage.getItem(key));
}

const renew = function (key) {
    let item = getItem(key);
    if (item.expiration) {
        item.expiration = new Date();

        window.localStorage.setItem(key, JSON.stringify(item));
    }
}
/**
 * 
 * @param {*} expiration Expiracion por la que queremos comprar
 * @returns Si la expiracion todavia es valida retorna el item, sino null
 */
const checkExpiration = function (key, expiration) {
    let item = getItem(key);
    if (item.expiration) {
        let itemexpiration = new Date(item.expiration);

        let lifetime = expiration;

        if ((new Date() - itemexpiration) < lifetime) {
            return item;
        } else {
            return null;
        }
    }
    return null;
}

const checkIfExists = function (key) {
    return (window.localStorage.getItem(key) != null);
}

export {
    removeItem,
    setExpiredItem,
    setItem,
    getItem,
    renew,
    checkExpiration,
    checkIfExists,
    setExpiredItemByDays
}