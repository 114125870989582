import React from 'react';

const Club = (props) => {
	const { className, ...r } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			width="49"
			height="40"
			viewBox="0 0 49 40"
			className={className}
			{...r}
		>
			<path
				d="M7.46842 23.9793C7.45759 19.9362 9.52652 17.2228 13.9921 17.2119C15.3975 17.2092 16.6297 17.3473 17.8618 17.6804L17.8672 20.5645L16.4239 20.5699L16.2262 18.8124C15.6467 18.7068 14.8343 18.6066 14.0977 18.6066C10.7397 18.612 9.56443 20.7107 9.57256 23.6272C9.58068 27.4402 10.9401 29.0027 14.16 28.9973C14.8261 28.9946 15.5817 28.9079 16.2478 28.7617L16.5268 27.0042L17.9837 27.0014L17.8862 29.8313C16.6568 30.1671 15.4598 30.3648 13.9839 30.3675C9.55631 30.3757 7.47654 28.1849 7.46842 23.9793Z"
				fill="#126FFF"
			/>
			<path
				d="M19.9227 28.981L20.9788 28.9079L20.9544 17.9891L19.6519 17.9918L19.6491 16.7786L22.6009 16.5782L22.7607 16.7191L22.7878 28.9756L23.9495 28.9729L23.9522 30.1698L19.9254 30.178L19.9227 28.981Z"
				fill="#126FFF"
			/>
			<path
				d="M26.1268 27.4402L26.1511 22.3084L25.0056 22.3111L25.0002 21.1332L27.7786 20.9842L27.9384 21.1061L27.9357 27.1369C27.9384 28.7535 29.065 29.1056 29.9072 29.1029C30.8577 29.1002 31.6132 28.6588 32.1034 28.3257L32.1088 22.2949L30.947 22.2976L30.9443 21.1196L33.739 20.9923L33.915 21.1142L33.8961 28.87L35.0578 28.9187L35.0605 30.1157L32.4744 30.243L32.3335 30.0859L32.209 29.2085C31.2964 29.9153 30.2077 30.3729 29.1164 30.3757C27.0962 30.3757 26.1295 29.1462 26.1268 27.4402Z"
				fill="#126FFF"
			/>
			<path
				d="M37.3109 29.8963L37.2838 17.9566L35.9975 17.9593L35.9948 16.7461L38.933 16.5457L39.0901 16.6865L39.1036 21.7154C39.786 21.2387 40.7176 20.8488 41.8441 20.8488C44.517 20.8434 45.806 22.6496 45.8114 25.3062C45.8168 28.4529 43.7506 30.3215 40.918 30.3269C39.7563 30.3296 38.4347 30.2104 37.3109 29.8963ZM43.9104 25.4849C43.9077 23.5162 43.0438 22.1811 41.2836 22.1838C40.352 22.1866 39.5965 22.4709 39.1063 22.804L39.1199 28.7644C39.61 28.9025 40.2464 29.027 40.9667 29.0243C43.0248 29.0189 43.9158 27.3508 43.9104 25.4849Z"
				fill="#126FFF"
			/>
			<path
				d="M5.35889 40C5.30744 40 5.25599 39.9973 5.20453 39.9892C4.90665 39.9458 4.63585 39.7779 4.46253 39.5315C4.27297 39.258 4.08612 38.9764 3.90739 38.6947C2.10113 35.8432 0.901476 32.6964 0.34633 29.3412C-0.211524 25.986 -0.0950785 22.6199 0.692958 19.3377C1.45391 16.1693 2.80522 13.2122 4.70896 10.5502C6.61271 7.88819 8.97411 5.65407 11.7255 3.9101C14.5743 2.10113 17.721 0.904184 21.079 0.34633C24.4342 -0.211524 27.8003 -0.0950785 31.0825 0.692958C34.2509 1.45391 37.208 2.80522 39.87 4.70896C42.532 6.61271 44.7661 8.97411 46.5101 11.7255C47.2359 12.8683 47.8695 14.0787 48.3976 15.3163C48.5303 15.625 48.5113 15.9717 48.3516 16.2668C48.1891 16.562 47.9074 16.7624 47.5743 16.8193C47.0734 16.9032 46.5859 16.6297 46.3855 16.1585C45.9062 15.032 45.3267 13.9325 44.6659 12.8926C43.0736 10.3796 41.0318 8.22128 38.5999 6.48272C36.1654 4.74417 33.4655 3.50931 30.5706 2.81605C27.5756 2.09572 24.5019 1.9901 21.4338 2.49921C18.3683 3.00832 15.4923 4.10237 12.8899 5.75156C10.3769 7.34388 8.21857 9.38573 6.48002 11.8175C4.74146 14.2494 3.5066 16.952 2.81064 19.8441C2.09572 22.8419 1.9901 25.9155 2.49921 28.9837C3.00832 32.0492 4.10237 34.9224 5.75156 37.5276C5.91675 37.7875 6.08735 38.0448 6.25796 38.2939C6.58021 38.7597 6.48814 39.4069 6.04402 39.7644C5.84904 39.916 5.60803 40 5.35889 40Z"
				fill="#126FFF"
			/>
		</svg>
	);
};

export default Club;
