import { LogoutAsync } from '@ln/user.client.libs'
const useLogout = () => {

    const logoutHandler = async (isVoluntary) => {
        return await LogoutAsync({ embedShortCircuit: true, redirect: "login", isVoluntary })
    };

    return logoutHandler;
}

export default useLogout;
