import React from "react";
const WhatsappFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      {...r}
      style={{ backgroundColor: "white" }}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M.004 20l1.352-4.968A9.954 9.954 0 010 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10a9.953 9.953 0 01-5.03-1.355L.004 20zM6.391 5.308a.961.961 0 00-.371.1 1.293 1.293 0 00-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 004.9 7.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 003.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 00.833-.231 4.83 4.83 0 00.383-.22s.043-.028.125-.09c.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 00-.177-.041.482.482 0 00-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 01-.368.13 1.413 1.413 0 01-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.012 6.012 0 01-1.57-1c-.126-.11-.243-.23-.363-.346a6.297 6.297 0 01-1.02-1.268l-.059-.095a.923.923 0 01-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41c.094-.12.182-.244.263-.373.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249a4.439 4.439 0 00-.162-.016 3.385 3.385 0 00-.403.004v.001z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default WhatsappFilled;
