import React from "react";
const Cloudy = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 23 17"
      {...r}
    >
      <path
        fill="#272727"
        d="M8.5 2a6.5 6.5 0 100 13h7a4.5 4.5 0 10-.957-8.898A6.502 6.502 0 008.5 2zm7 15h-7a8.5 8.5 0 117.215-12.997A6.5 6.5 0 0115.5 17z"
      ></path>
    </svg>
  );
};

export default Cloudy;
