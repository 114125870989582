import { LocalStorageCache } from "@auth0/auth0-react";
import { borrarAlgunasCookies, borrarCookies, createCookie, createCookieByMinutes, readCookie } from "../Helpers/Cookies";
import { isCheckoutUrl } from "./UrlMatch";
import { Origins } from "../Config/Origin";
import { ACCESS_TOKEN_EXPIRATION, ID_TOKEN_EXPIRATION, REFRESH_TOKEN_EXPIRATION } from "../Config/Cookies";

const getAuth0Cookies = () => {
    let token = readCookie('token');
    let xvalue = readCookie('xvalue');
    if (token == null && xvalue == null) return null;
    return [token, xvalue]
};

const getAuth0TokensFromCache = () => {
    const refresh_token = new LocalStorageCache;
    const key = refresh_token.allKeys().find(key => key.includes('auth0spa'));
    const refresh_token_value = refresh_token.get(key);
    const tokens = {};
    tokens.finalRefreshToken = refresh_token_value?.body?.refresh_token
    tokens.idToken = refresh_token_value?.body?.id_token
    tokens.userId = refresh_token_value?.body?.decodedToken.claims.sub;
    tokens.accessToken = refresh_token_value?.body?.access_token;
    return tokens;
};

const deleteAuth0Cookies = async () => {
    borrarCookies();
};

const deleteOriginCookie = () => {
    borrarAlgunasCookies(['OriginId']);
};

const setOriginIdCookie = (sitioId, callback) => {
    let originId = sitioId;
    if (callback && isCheckoutUrl(callback)) {
        switch (sitioId) {
            case Origins.foodit:
                originId = Origins.firstStepCheckoutFoodit
                break;
            default:
                originId = Origins.firstStepCheckout
                break;
        }
    }
    deleteOriginCookie();
    createCookieByMinutes("OriginId", originId, 5);
    const originIdCookie = readCookie('OriginId');
    return originIdCookie != null;
};

const getOriginIdCookie = () => {
    const OriginId = readCookie('OriginId');
    return OriginId != null;
}

const setAuth0TokensCookies = (access_token, refresh_token, id_token) => {
    createCookieByMinutes("token", id_token, ID_TOKEN_EXPIRATION);
    createCookieByMinutes("xvalue", refresh_token, REFRESH_TOKEN_EXPIRATION);
    createCookieByMinutes("access-token", access_token, ACCESS_TOKEN_EXPIRATION);
}

export {
    getAuth0Cookies,
    deleteAuth0Cookies,
    getAuth0TokensFromCache,
    setOriginIdCookie,
    getOriginIdCookie,
    setAuth0TokensCookies,
    deleteOriginCookie,
};