import { readCookie } from './Cookies';

const email = readCookie('usuarioemail');

DD_LOGS.onReady(() => {
    console.log(`DLOG :: context :: email=${email}`);
    DD_LOGS.logger.addContext('user.email', email);
    DD_LOGS.logger.addContext('ingresarVersion', 2);
});

function getGoogleAnaliticsId() {
    return new Promise(function (resolve, reject) {
        if (!ga) return resolve(); // No esta cargado GTM.
        ga(function () {
            const gaid = ga.getAll()[0].get('clientId');
            if (gaid) resolve(gaid);
            else reject('No se pudo obtener el GaId');
        });
    });
}

function initGoogleAnalitics() {
    getGoogleAnaliticsId().then(function (gaId) {
        if (!gaId) return;
        DD_LOGS.onReady(() => {
            console.log(`DLOG :: context :: gaId=${gaId}`);
            DD_LOGS.logger.addContext('user.gaId', gaId);
        });
        DD_RUM.onReady(function () {
            DD_RUM.setUser({
                gaId: gaId,
            });
        });
    });
}

initGoogleAnalitics();
