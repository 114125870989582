const FacebookButton = ({ ...r }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...r}>
			<path
				fill="#FEFEFE"
				d="M12.5 2c-5.523 0-10 4.477-10 10 0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989c4.78-.75 8.437-4.889 8.437-9.879 0-5.523-4.477-10-10-10z"
			></path>
		</svg>
	);
};

export default FacebookButton;
