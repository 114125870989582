import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import useLoginAuth0Handler from '../../Hooks/useLoginAuth0Handler';
import { useHistory } from 'react-router';
import useVerifySessionAuth0 from '../../Hooks/useVerifySessionAuth0';
import { analyzeUrlCallbackAuth0 } from '../../Helpers/Auth0MobileUtils';
import {
    deleteOriginCookie,
    getAuth0TokensFromCache,
    setAuth0TokensCookies
  } from '../../Helpers/Auth0CookieManager';
import * as storage from '../../Helpers/Auth0StorageManager';
import { useStore } from '../../Store/storeProvider';
import LoginTypes from '../../Models/LoginTypes';
import { comeFromSignwall, updateURLToThankWall } from '../../Helpers/SignwallHelper';
import { trackUserHandler } from '../../Helpers/trackGTMHandler';

const Auth0Callback = () => {
    const { isAuthenticated, error, user } = useAuth0();
    const [loginAuth0Handler] = useLoginAuth0Handler();
    const history = useHistory();
    const { Config } = useStore();
    storage.deleteModo();
    storage.deleteSitioId();

    const callbackHandler = async (isVerified) => {
        deleteOriginCookie();
        await analyzeUrlCallbackAuth0(window.location);
        if (isAuthenticated && !error) {
            trackUserHandler(user, Config.SitioId);
            const isFromSignwall = comeFromSignwall( Config.Modo , Config.SitioId );

            if (isVerified) {
                const { finalRefreshToken, idToken, accessToken } = getAuth0TokensFromCache();
                setAuth0TokensCookies(accessToken, finalRefreshToken, idToken)

                const isSocialLogin = LoginTypes[user.connection_type] !== LoginTypes['Username-Password-Authentication'];

                if ( isSocialLogin && isFromSignwall && user.is_registration === true) {
                    Config.URLCallback = updateURLToThankWall(Config.URLCallback);
                }

                await loginAuth0Handler();
            } else if (isVerified === false) {
                let urlCallback = Config.URLCallback;

                if ( isFromSignwall ) {
                    urlCallback = updateURLToThankWall(urlCallback);
                }

                storage.setCallbackEmailVerification(urlCallback);
                history.push("/auth0-emailConfirmation");
            }
        } else {
            history.push("/auth0-login")
        }
    }
    const [isLoadingProcess] = useVerifySessionAuth0(callbackHandler);

    return (
        <div className='callback-view containerSpinner'>
            <LoadingSpinner isLoading={isLoadingProcess}>
            </LoadingSpinner>
        </div>
    );
}

export default Auth0Callback;
