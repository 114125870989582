import React from "react";
const StarFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 19 19"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M9.95.298l2.416 5.72 6.187.532c.43.037.604.573.278.854l-4.693 4.066 1.406 6.05a.487.487 0 01-.726.527L9.5 14.84l-5.318 3.207a.487.487 0 01-.726-.528l1.406-6.048L.17 7.402a.488.488 0 01.278-.854l6.187-.531L9.051.298a.487.487 0 01.898 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default StarFilled;
