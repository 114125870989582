import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  getFontFamily,
  getFontSize,
  getFontWeight,
} from "../helpers/getFontData";

import "./_link.scss";

const propTypes = {
  /**
   * href link.
   */
  href: PropTypes.string,
  /**
   * Texto dentro del link.
   */
  text: PropTypes.string,
  /**
   * Target.
   */
  target: PropTypes.string,
  /**
   * Atributo rel asociado.
   */
  rel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Title del elemento
   */
  title: PropTypes.string,
  /**
   * Clases adicionales.
   */
  className: PropTypes.string,
  /**
   * Tamaño de la fuente.
   */
  size: PropTypes.string,
  /**
   * Flag, ¿display block?
   */
  block: PropTypes.bool,
};

const Link = ({
  href,
  children,
  text,
  rel,
  target,
  title,
  onClick,
  className,
  font,
  size,
  weight,
  bold,
  block,
}) => {
  const _font = getFontFamily(font);
  const _size = getFontSize(size);
  const _weight = getFontWeight(weight);

  const classes = classNames("link", className, {
    [_font]: font,
    [_size]: size,
    [_weight]: weight,
    "--font-bold": bold,
    "--block": block,
  });
  const content = children || text;

  return (
    <a
      className={classes}
      href={href}
      target={target}
      rel={rel ? rel.toString() : target === "_blank" ? "nofollow" : undefined}
      title={title || text}
      onClick={onClick}
    >
      {content}
    </a>
  );
};

Link.propTypes = propTypes;

export default Link;
