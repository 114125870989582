import React from 'react';
import PropTypes from 'prop-types';
import {Spinner} from '@lana-ui/common';

const LoadingSpinner = ({isLoading, children}) => {
	return (
		<React.Fragment>{isLoading ? <Spinner /> : children}</React.Fragment>
	);
};
LoadingSpinner.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

export default LoadingSpinner;
