import React from "react";
const UserIcon = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M5 22c-.552 0-1.006-.45-.938-.997a8 8 0 0115.876 0c.069.547-.386.997-.938.997-.552 0-.991-.45-1.083-.995a6 6 0 00-11.834 0C5.992 21.549 5.553 22 5 22zm7-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
      ></path>
    </svg>
  );
};

export default UserIcon;
