const GoogleButton = ({ ...r }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 24" {...r}>
			<path
				fill="#4285F4"
				fillRule="evenodd"
				d="M23 12.233c0-.864-.072-1.494-.226-2.147h-10.05v3.897h5.9c-.12.968-.762 2.427-2.19 3.407l-.02.13 3.178 2.415.22.021C21.834 18.125 23 15.43 23 12.233z"
				clipRule="evenodd"
			></path>
			<path
				fill="#34A853"
				fillRule="evenodd"
				d="M12.714 22.5c2.893 0 5.322-.933 7.095-2.544l-3.38-2.566c-.905.618-2.12 1.05-3.715 1.05a6.438 6.438 0 01-6.095-4.364l-.126.01-3.307 2.51-.043.117c1.761 3.43 5.38 5.787 9.571 5.787z"
				clipRule="evenodd"
			></path>
			<path
				fill="#FBBC04"
				fillRule="evenodd"
				d="M6.619 14.07a6.336 6.336 0 01-.357-2.075c0-.722.13-1.422.345-2.074l-.006-.14-3.349-2.545-.11.05A10.32 10.32 0 002 11.997c0 1.69.417 3.286 1.143 4.708l3.476-2.634z"
				clipRule="evenodd"
			></path>
			<path
				fill="#EA4335"
				fillRule="evenodd"
				d="M12.714 5.56c2.012 0 3.37.852 4.143 1.563l3.024-2.893c-1.857-1.692-4.274-2.73-7.167-2.73-4.19 0-7.81 2.357-9.571 5.787l3.464 2.636a6.465 6.465 0 016.107-4.363z"
				clipRule="evenodd"
			></path>
		</svg>
	);
};

export default GoogleButton;
