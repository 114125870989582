import React from "react";
const Zoom = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M16 5a1 1 0 011-1h5v4a1 1 0 11-2 0V6h-3a1 1 0 01-1-1zM7 4a1 1 0 010 2H4v2a1 1 0 01-2 0V4h5zm10 17a1 1 0 110-2h3v-2a1 1 0 112 0v4h-5zm-9-1a1 1 0 01-1 1H2v-4a1 1 0 112 0v2h3a1 1 0 011 1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Zoom;
