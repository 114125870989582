import React, { useEffect } from 'react';
import { TextLogin, Button, MainLogin } from '@lana-ui/login';
import { Formik, Form } from 'formik';
import { Text, Label, Wizard, Paragraph } from '@lana-ui/common';
import { useDispatch, useStore } from '../../Store/storeProvider';
import { stateCode } from '../../Config/Registracion';
import TerminosYcondiciones from '../Common/banner/TerminosYCondiciones';
import InputFormik from '../Common/Formik/Input';
import useValidarPinHandler from '../../Hooks/useValidarPinHandler';
import ActivarPinService from '../../Services/ActivarPinService';
import { trackCustomEvent } from '../../Config/DataDog';
import { StepsTrackeoRedimirPin } from '../../Config/StepsTrackeoRedimirPin';
import { readCookie } from '../../../src/Helpers/Cookies';
import { steps } from '../../Config/CheckoutSteps';
import LoadingSpinner from '@helpers/LoadingSpinner';
import '../../../src/Statics/Scss/Pages/PinActivacion.scss';
import Progress from '../../Components/Common/common/src/progress/Progress';
import useLogout from '../../../src/Services/_LogoutService';
import getUserMailAndCrmIdFromCookies from '../../Helpers/GetUserMailAndCrmId';

const PinActivacion = ({ title, setIsLoading, isLoading }) => {
    const { Config, HandleError } = useStore();
    const callbackUrl = `${process.env.REACT_APP_LOGIN}login/validar-pin/${
        Config?.Pin ? `?pin=${Config?.Pin}` : ''
    }`;
    const urlToLogin = `${process.env.REACT_APP_LOGIN}login/ingresar/${
        callbackUrl ? `?callback=${window.btoa(callbackUrl)}` : ''
    }`;
    const handleLogout = useLogout();
    const hasUsuario = readCookie('usuarioemail') || null;
    const handleClick = async () => {
        if (!!hasUsuario) {
            await handleLogout(true);
        } else {
            window.location = urlToLogin;
        }
    };
    const dispatch = useDispatch();
    const [handlerPin] = useValidarPinHandler();
    let url = new URL(document.location);
    let urlPin = url?.searchParams.get('pin');
    const isError = true;

    const handleSeteoKey = () => {
        //siu existe en el store o no hay en la url no seteamos
        //()
        if (Config.Pin || !urlPin) return;
        dispatch({
            type: 'SETEO_PIN',
            value: urlPin,
        });
    };
    useEffect(() => {
        handleSeteoKey();
        //Viene de logeo desp de rebotar en view pin
        //Ejecutamos automatico el send
        if (Config.ActionAuto) handleSend(Config.Pin);
        //Seteo en false para evitar el loop infinito
        dispatch({
            type: 'SETEO_ACTIONAUTO',
            value: false,
        });
        trackCustomEvent(StepsTrackeoRedimirPin[1], !isError, getUserMailAndCrmIdFromCookies());
    }, []);

    let handleSend = async (pinValue) => {
        trackCustomEvent(StepsTrackeoRedimirPin[3], !isError, getUserMailAndCrmIdFromCookies());
        setIsLoading(true);
        try {
            let dataValidar = {
                Pin: pinValue,
            };
            const resp = await ActivarPinService(dataValidar);
            handlerPin(resp);
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'ERROR PAGE COMPONENT',
                value: 'error generico',
            });
        }
        setIsLoading(false);
    };

    return (
        <LoadingSpinner isLoading={isLoading}>
            <div className="activar-suscripcion">
                <div className="progress-steps">
                    <Wizard activeStep={2} steps={steps} />
                    <div className="progress-steps__container">
                        <Progress
                            currentNumber={steps.step_2.number}
                            amountTotalNumbers={Object.values(steps).length}
                        />
                        <div className="progress-steps__container__data">
                            <Paragraph
                                size="4xs"
                                className="--arial --font-bold"
                            >
                                {steps.step_2.text}
                            </Paragraph>
                            <Paragraph size="5xs">
                                Próximo paso: {steps.step_3.text}
                            </Paragraph>
                        </div>
                    </div>
                </div>
                {title && (
                    <TextLogin
                        size="sizeL"
                        title={'Activá tu suscripción de LA NACION'}
                    />
                )}
                <Paragraph as="p" size="2xs" className="--fourxs text">
                    Tu suscripción quedará activa en la cuenta{' '}
                    <span className="--arial --font-bold">{hasUsuario}</span>.
                    <br />
                    Para cambiar de cuenta hacé{' '}
                    <button
                        onClick={handleClick}
                        className="link user-links --arial --font-bold"
                    >
                        click acá
                    </button>
                </Paragraph>
            </div>
            <Formik
                initialValues={{
                    inputPin: urlPin || '',
                }}
                //validationSchema={}
                onSubmit={async (values) => {
                    await handleSend(values.inputPin);
                }}
            >
                {({ values }) => {
                    return (
                        <Form className="login-form" noValidate>
                            <MainLogin>
                                <Label text={'Código de activación'}></Label>
                                <InputFormik
                                    disabled={Boolean(urlPin)}
                                    name="inputPin"
                                    className="--mb-24"
                                    state={
                                        HandleError?.Input &&
                                        stateCode('error', HandleError.Input)
                                    }
                                />
                                <Paragraph as="p" size="2xs" className="--fourxs text --mb-8">
                                    Para activar tu suscripción de LA NACION,
                                    hacé click a continuación y comenzá a
                                    disfrutar de nuestro contenido.
                                </Paragraph>

                                <Button
                                    as="input"
                                    label="ACTIVAR SUSCRIPCIÓN"
                                    className={'--not-disabled --mb-24'}
                                />
                            </MainLogin>
                        </Form>
                    );
                }}
            </Formik>
            <TerminosYcondiciones text="Al hacer click en Activar confirmo que he leído y aceptado los" />
        </LoadingSpinner>
    );
};

export default PinActivacion;
