import { isInAppBrowser } from '../../../Helpers/RedirectMeta';
import GetParamURL from "../../../Helpers/GetParamURL";

export const RedireccionIOS = () => {
    if(!isInAppBrowser())
    {
        const { URLCallback } = GetParamURL();
        window.location = URLCallback;
    }

    return (
        <div className="redirection-wrapped">
            <div className="container-redirection">
                <header className="redirection-text-container">
                    <div className="redirection-icon">
                        <svg viewBox="0  0 32 32" xmlns="http://www.w3.org/2000/svg" fill="none"><rect width="32" height="32" rx="16" fill="#006998" /><path fillRule="evenodd" clipRule="evenodd" d="M9.333 16a6.666 6.666 0 1 0 13.333 0 6.666 6.666 0 0 0-13.333 0zM16 14a.667.667 0 1 1 0-1.333A.667.667 0 0 1 16 14zm.667 2v2.667a.667.667 0 0 1-1.334 0V16a.667.667 0 1 1 1.334 0z" fill="#FEFEFE" /></svg>
                    </div>
                    <div>
                        <h1>Estas intentando iniciar sesión</h1>
                        <p>
                            ¿Cómo continuar?
                        </p>
                    </div>
                </header>
                <main className="redirection-steps">
                    <dl className="redirection-list">
                        <div className="description-one">
                            <dt className="redirection-icon-one">PASO 1</dt>
                            <dd>Ir al menú ubicado en la esquina superior derecha.</dd>
                        </div>
                        <div className="description-two">
                            <dt className="redirection-icon-two">PASO 2</dt>
                            <dd>Seleccionar la opción: <br /><strong>Abrir en navegador externo</strong></dd>
                        </div>
                    </dl>
                </main>
            </div>
        </div>
    )
}