import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_paragraph.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Tamaño del texto.
	 */
	size: PropTypes.string,
};

const defaultProps = {
	size: 'fourxs',
};

const Paragraph = ({ children, size, className, ...r }) => {
	const classes = classNames('paragraph', className, `--${size}`);

	return (
		<p className={classes} {...r}>
			{children}
		</p>
	);
};

Paragraph.propTypes = propTypes;
Paragraph.defaultProps = defaultProps;

export default Paragraph;
