import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import './ModalPortalBG.scss';

const ModalPortalBG = ({ children }) => {
	// CAMBIAR, O ELIMINAR, CLASE AL UTILIZAR ESTE COMPONENTE!
	const body = document.querySelector('body');

	useEffect(() => {
		body?.classList.add('modal-active');
		return () => {
			body?.classList.remove('modal-active');
		};
	});

	return ReactDOM.createPortal(
		<>{children}</>,

		document.body,
	);
};

export default ModalPortalBG;
