import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './_spinner.scss';
import Simple from './SimpleSpinner';
import { Logo } from '@lana-ui/common';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Nombre del logo a utilizarse.
	 */
	logoName: PropTypes.string,
	/**
	 * Tamaño del spinner.
	 */
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
	/**
	 * Flag, ¿forzar a que cubra toda
	 * la pantalla?
	 */
	full: PropTypes.bool,
};

const defaultProps = {
	logoName: 'ln',
	size: 'md',
};

const Spinner = ({ className, color, logoName, size, full, ...r }) => {
	const classes = classNames('spinner', className, {
		'--club': logoName.includes('club'),
		'--full': full,
	});

	// TODO: Agregar los respectivos tamaños.
	const sizes = {
		xs: { svg: { width: '75', height: '75' }, circle: { cx: '37.5', cy: '37.5', r: '33.5' } },
		sm: { svg: { width: '100', height: '100' }, circle: { cx: '50', cy: '50', r: '46' } },
		md: { svg: { width: '125', height: '125' }, circle: { cx: '62.5', cy: '62.5', r: '58.5' } },
		lg: { svg: { width: '170', height: '170' }, circle: { cx: '85', cy: '85', r: '81' } },
	};

	return (
		<div className={classes} {...r}>
			<svg {...sizes[size].svg}>
				<circle {...sizes[size].circle} strokeWidth="4" stroke="#006998" />
			</svg>
			<Logo className="logo" name={logoName} size={size} />
		</div>
	);
};

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;
Spinner.Simple = Simple;

export default Spinner;
