import React from "react";
const SnowCloudy = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <g clipPath="url(#a)">
        <path
          fill="#272727"
          fillRule="evenodd"
          d="M21.94 10.308c.103.147.197.3.28.46a5.29 5.29 0 01.72 2.19 5.45 5.45 0 01-1.44 4.19 5.402 5.402 0 01-1.84 1.33 5.511 5.511 0 01-2.22.48h-.5v-2h.5a3.551 3.551 0 003.125-1.95 3.5 3.5 0 00-2.475-5 3.5 3.5 0 00-3.15 1v-.05a6.001 6.001 0 00-6.42-6 6 6 0 00-5.03 3.61 6 6 0 00.86 6.18A6.001 6.001 0 007 16.618v2.12a8 8 0 01-3.54-2 8.11 8.11 0 01-2.11-3.45 8 8 0 015-9.89 7.88 7.88 0 014-.31c.24.04.47.09.7.15a6.498 6.498 0 0111.024-.353 6.5 6.5 0 01-.134 7.423zm-3.27-2.21a5.57 5.57 0 011.82.79h.02a4.5 4.5 0 00-7.57-4.87c.38.224.741.478 1.08.76a7.94 7.94 0 012.37 3.27 5.65 5.65 0 012.28.05zm-5.77 10.13l1.97-1.14 1 1.74-1.97 1.13 1.97 1.13-1 1.74-1.97-1.14v2.27h-2v-2.27l-1.96 1.14-1-1.74 1.96-1.13-1.96-1.13 1-1.74 1.96 1.14v-2.27h2v2.27z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SnowCloudy;
