import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_layout.scss';
import { useStore } from '../../../../../Store/storeProvider';
import ModosUrl from '../../../../../Models/ModosUrl';

const propTypes = {
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
    /**
     * Tipo de componente a utilizar.
     */
    as: PropTypes.string,
    /**
     * Flag, ¿dark mode?
     */
    darkMode: PropTypes.bool,
    /**
     * Tipo de layout.
     */
    type: PropTypes.oneOf(['full-width', 'wrapped', 'aside', 'wrapped-extra-small']),
    /**
     * Flag, ¿está centrado?
     */
    centered: PropTypes.bool,
};

const defaultProps = {
    as: 'main',
};

const Layout = forwardRef(
    (
        {
            children,
            className,
            wrapperClassName,
            as: Component,
            type,
            darkMode,
            centered,
            ...r
        },
        ref
    ) => {
        const { Config } = useStore();
        const black = Config.Modo === ModosUrl.black;
        const classes = classNames('lay', className, {
            [`--${type}`]: type,
            'mode:dark': darkMode,
            [`--${centered}`]: centered,
            '--black': black,
        });

        return (
            <Component className={classes} ref={ref} {...r}>
                {children}
            </Component>
        );
    }
);

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
