import Usuario from '../Models/Usuario';
import GetParamURL from '../Helpers/GetParamURL';
let URLParams = GetParamURL();
import * as storage from '../Helpers/Auth0StorageManager';

const initialState = {
    User: { ...Usuario, SitioId: URLParams?.SitioId },
    HandleError: {
        PageElement: false,
        Input: false,
    },

    HandleModal: {
        Value: null,
    },
    Config: {
        SitioId: storage.getSitioId() ?? URLParams?.SitioId,
        Modo: storage.getModo() ?? URLParams?.Modo,
        URLCallback: URLParams?.URLCallback,
        Data: false,
        Pin: null,
        ActionAuto: false,
        IsUserModule: false,
    },
    HandleToast: false,
    RecaptchaRef: null,
};

const storeReducer = (state, action) => {
    switch (action.type) {
        case 'USER_ADD_VALUES': {
            return {
                ...state,
                User: {
                    ...state.User,
                    ...action.value,
                },
            };
        }

        case 'ERROR PAGE COMPONENT': {
            return {
                ...state,
                HandleError: {
                    PageElement: action.value,
                },
            };
        }

        case 'SETEO DATA': {
            return {
                ...state,
                Config: {
                    Data: action.value,
                },
            };
        }

        case 'ERROR INPUT': {
            return {
                ...state,
                HandleError: {
                    Input: action.value,
                },
            };
        }

        case 'ERROR INPUT EMAIL': {
            return {
                ...state,
                HandleErrorEmail: {
                    Input: action.value,
                },
            };
        }

        case 'SHOW MODAL': {
            return {
                ...state,
                HandleModal: {
                    Value: action.value,
                },
            };
        }

        case 'SHOW TOAST': {
            return {
                ...state,
                HandleToast: action.value,
            };
        }

        case 'SETEO_PIN': {
            return {
                ...state,
                Config: {
                    ...state.Config,
                    Pin: action.value,
                },
            };
        }

        case 'SETEO_USERMODULE': {
            return {
                ...state,
                Config: {
                    ...state.Config,
                    IsUserModule: action.value,
                },
            };
        }

        case 'SETEO_ACTIONAUTO': {
            return {
                ...state,
                Config: {
                    ...state.Config,
                    ActionAuto: action.value,
                },
            };
        }

        case 'RESET_EMAIL_ERRORINPUT': {
            return {
                ...state,
                User: {
                    ...state.User,
                    Email: '',
                },
                HandleError: {
                    Input: false,
                },
            };
        }

        case 'SET_RECAPTCHA': {
            return {
                ...state,
                RecaptchaRef: action.value,
            };
        }
        case 'SETEO_CALLBACK': {
            return {
                ...state,
                Config: {
                    ...state.Config,
                    URLCallback: action.value,
                },
            };
        }
    }
    return state;
};
export { initialState, storeReducer };
