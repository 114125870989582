import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_progress.scss';

const propTypes = {
	/**
	 * Número actual.
	 */
	currentNumber: PropTypes.number,
	/**
	 * Cantidad de números totales.
	 */
	amountTotalNumbers: PropTypes.number,
	/**
	 * Clases adicionales
	 */
	className: PropTypes.string,
};

const Progress = ({ currentNumber, amountTotalNumbers, className }) => {
	const progress = () => {
		return (currentNumber * 100) / amountTotalNumbers;
	};
	const progressClass = progress() >= 50 ? ' over50' : '';
	const classes = classNames(
		'progress-circle' + `${progressClass} p${Math.round(progress())}`,
		className
	);
	return (
		<div className={classes}>
			<span>
				{currentNumber} de {amountTotalNumbers}
			</span>
			<div className="left-half-clipper">
				<div className="first50-bar"></div>
				<div className="value-bar"></div>
			</div>
		</div>
	);
};

Progress.propTypes = propTypes;

export default Progress;
