import React from 'react';
import { TermsLogin } from '../TermsLogin';

export const FormularioDni = ( {handleChange, handleSubmit, user, formError} ) => {
  return (
    <div className="container-login">
            <header className="login-dni">
                <h1 className="login-dni-title">¿Olvidaste tu email?</h1>
                <p className="login-dni-text">
                    Si sos suscriptor, ingresá tu número de DNI y te enviaremos
                    los pasos a seguir para recuperar tu email.
                </p>
            </header>
            <form className="form-login" onSubmit={(e) => handleSubmit(e)}>
                <div
                    className={
                        'form-input ' +
                        (!!formError ? 'show-error' : 'show-succes')
                    }
                >
                    <input
                        id="formDni"
                        className="form-dni"
                        type="text"
                        placeholder="Número de DNI"
                        required
                        name="dni"
                        title="Completá este campo"
                        value={user.dni}
                        onChange={handleChange}
                    />
                    <label htmlFor="dni">Número de DNI</label>
                    <div className="form-error">
                        <i className="icon-alert-dni"></i>
                        <span>{formError}</span>
                    </div>
                </div>
                <button 
                    className="ln-button --primary button-dni" 
                    type="submit">
                        {'Continuar'}
                </button>
            </form>
            <TermsLogin />
        </div>
  )
}