import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_button.scss';

import { Button } from '@lana-ui/common';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const ButtonLogin = forwardRef(({ children, className, ...r }, ref) => {
	const classes = classNames('lgn-button', className);

	return <Button className={classes} ref={ref} label={children} {...r} />;
});

ButtonLogin.propTypes = propTypes;

export default ButtonLogin;
