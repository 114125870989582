import React from "react";
const CommentFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M16.709 20.824L22 22l-1.176-5.291A9.955 9.955 0 0022 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10a9.955 9.955 0 004.709-1.176z"
      ></path>
    </svg>
  );
};

export default CommentFilled;
