import React from "react";
const Snow = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 22 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M12 14.268l1.964-1.134 1 1.732L13 16l1.964 1.134-1 1.732L12 17.732V20h-2v-2.268l-1.964 1.134-1-1.732L9 16l-1.964-1.134 1-1.732L10 14.268V12h2v2.268zM16 16v-2h.5A3.5 3.5 0 1014 8.05V8a6 6 0 10-8 5.659v2.089a8 8 0 119.458-10.65A5.5 5.5 0 1116.5 16H16z"
      ></path>
    </svg>
  );
};

export default Snow;
