import { trackCustomEvent } from '../Config/DataDog';
import useLogout from './_LogoutService';
import { getAccessTokenCookie, getIdTokenCookie } from '@ln/user.client.libs'
const handleLogout = useLogout();

const post = async (url, body, header = false) => {
    //TODO: Cuando este implementado el MVP2 usar BuildBearerAccessToken();
    const accessToken = getAccessTokenCookie()
    var headers = {
        'Content-Type': 'application/json',
        'X-Token': getIdTokenCookie(),
        'Authorization': accessToken && `Bearer ${accessToken}`
    };

    let isHandle = header ? handleWithHeader : handle;

    return fetch(`${url}`, {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(body),
    }).then(isHandle);
};

const postWithHeaders = async (url, body, headers, header = false) => {

    let isHandle = header ? handleWithHeader : handle;

    return fetch(`${url}`, {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(body),
    }).then(isHandle);
};

const getWithHeaders = async (url, headers) => {

    let urlObject = new URL(url);

    return fetch(`${urlObject}`, {
        method: 'GET',
        mode: 'cors',
        headers,
    }).then(handle);
};

const get = async (url, params) => {
    //TODO: Cuando este implementado el MVP2 usar BuildBearerAccessToken();
    const accessToken = getAccessTokenCookie()
    var headers = {
        'Content-Type': 'application/json',
        'X-Token': getIdTokenCookie(),
        'Authorization': accessToken && `Bearer ${accessToken}`
    };
    let urlObject = new URL(url);

    if (params) {
        urlObject.search = new URLSearchParams(params).toString();
    }

    return fetch(`${urlObject}`, {
        method: 'GET',
        mode: 'cors',
        headers,
    }).then(handle);
};

const handleWithHeader = async (response) => {
    if (response.ok) {
        let resp = {};
        resp.header = response.headers.get('X-Token');
        resp.body = await response.json();
        resp.XValue = response.headers.get('X-Value');
        return resp;
    } else {
        throw new Error('Error inesperado');
    }
};

const handle = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        return new Promise((resolve, reject) => {
            let message;
            switch (response.status) {
                case 401:
                    trackCustomEvent("logout", true, { message: `ApiService - ${error.message}` })
                    handleLogout();
                    message = `Se requiere autenticación. Por favor ingrese sus credenciales nuevamente - (status code: ${response.status})`;
                    break;
                case 402:
                    break;
                case 403:
                    message = `No posee los permisos necesarios para acceder al recurso solicitado. Por favor ingrese sus credenciales nuevamente - (status code: ${response.status})`;
                    break;
                case 404:
                    message = `No existe el recurso solicitado. Por favor intentelo nuevamente más tarde - (status code: ${response.status})`;
                    break;
                case 400:
                case 500:
                case 'parsererror':
                case 'timeout':
                case 'abort':
                case 0:
                default:
                    message =
                        'Ocurrió un error inesperado. Por favor intentelo nuevamente más tarde.';
            }
            reject(message);
        });
    }
};

export default { post, get, postWithHeaders, getWithHeaders };
