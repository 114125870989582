const DiccionarioCookiesAGuardarBorrar = [
  "usuariosexo",
  "usuarioemail",
  "usuarioanio",
  "usuarioDetalleClubNacion",
  "UsuarioDetalleGuid",
  "usuario%5Fdetalle%5Fguid",
  "UsuarioDetalleNick",
  "usuario%5Fdetalle%5Fnick",
  "UsuarioId",
  "usuario%5Fid",
  "UsuarioUsuario",
  "usuario%5Fusuario",
  "usuarioLogTkn",
  "usuario%5Flogtkn",
  "cookieLogin",
  "syncLfLN",
  "Provinciaid",
  "Paisid",
  "LNPreferencias",
  "Crm_id",
  "ProductoPremiumId",
  "token",
  "xvalue",
  "TokenJWT",
  "callbackCookie",
  "OriginId"
];

const ExpirationDays = 100;
const DefaultExpirationMinutes = 10;
const ACCESS_TOKEN_EXPIRATION = 10080;
const METERING_ARC_EXPIRATION = 10080;
const REFRESH_TOKEN_EXPIRATION = 129600;
const ID_TOKEN_EXPIRATION = 129600;

const Map = (key) => {
  switch (key) {
    case 'UsuarioDetalleGuid':
      return 'usuario%5Fdetalle%5Fguid';
    case 'UsuarioDetalleNick':
      return "usuario%5Fdetalle%5Fnick";
    case 'UsuarioId':
      return "usuario%5Fid";
    case 'UsuarioUsuario':
      return "usuario%5Fusuario";
    case 'usuarioLogTkn':
      return "usuario%5Flogtkn";
    case 'TokenJWT':
      return "PersoTKN";
    default:
      return key;

  }
}

export { DiccionarioCookiesAGuardarBorrar, Map, ExpirationDays, DefaultExpirationMinutes, ACCESS_TOKEN_EXPIRATION, REFRESH_TOKEN_EXPIRATION, ID_TOKEN_EXPIRATION, METERING_ARC_EXPIRATION }

