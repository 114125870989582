import React from "react";
const NavigationFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M2.9 2.3l18.805 6.268a.5.5 0 01.028.939L13 13l-4.425 8.85a.5.5 0 01-.928-.086L2.26 2.911A.5.5 0 012.9 2.3z"
      ></path>
    </svg>
  );
};

export default NavigationFilled;
