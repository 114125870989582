import React from "react";
const InfoFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12zm10-3a1 1 0 110-2 1 1 0 010 2zm1 3v4a1 1 0 11-2 0v-4a1 1 0 112 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default InfoFilled;
