import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from '../../Helpers/LoadingSpinner';
import { useStore } from '../../Store/storeProvider';


const Auth0Logout = () => {
    const { logout, isLoading, isAuthenticated } = useAuth0();
    const { Config } = useStore();
    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            logout({ returnTo: `${window.location.origin}/auth0-logout-callback/?callback=${window.btoa(Config.URLCallback)}` });
        }else{
            window.location = `${window.location.origin}/auth0-login/${Config.Modo}/${Config.SitioId}/?callback=${window.btoa(Config.URLCallback)}`
        }
    }, [isAuthenticated, isLoading]);

    return (
        <div className='callback-view containerSpinner'>
            <LoadingSpinner isLoading={isLoading}>
            </LoadingSpinner>
        </div>
    );
}

export default Auth0Logout;