import React from "react";
const Star = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 19 19"
      {...r}
    >
      <path
        fill="#272727"
        d="M10.772 8.212L9.5 5.202l-1.272 3.01-3.257.28 2.471 2.14-.74 3.184L9.5 12.128l2.799 1.688-.74-3.184 2.47-2.14-3.258-.28zm7.781-1.662c.43.037.604.573.278.854l-4.693 4.066 1.406 6.05a.487.487 0 01-.726.527L9.5 14.84l-4.888 2.948-.43.259a.487.487 0 01-.726-.528l1.406-6.048L.17 7.403a.488.488 0 01.278-.855l6.187-.531L9.051.298a.487.487 0 01.898 0l2.417 5.72 6.187.532z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Star;
