import React from "react";
const Ln = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M7.058 5.75H0v1.31h1.638v9.38H0v1.31h10.318v-5.091H9v.755c0 1.239-.403 1.97-1.018 2.407-.634.45-1.537.618-2.597.618h-.131V7.06h1.804V5.75zm9.228 0h-5.128v1.31h.534c.496 0 .995.407.995 1.022v7.447c0 .449-.12.652-.245.754-.137.112-.341.156-.611.156h-.73v1.311h4.56v-1.31h-.572c-.252 0-.455-.01-.619-.037a.86.86 0 01-.346-.116c-.123-.083-.218-.24-.218-.673V8.948l6.776 8.802h1.733V7.675c0-.128.054-.275.189-.393s.368-.222.743-.222H24V5.75h-4.569v1.31h.745c.386 0 .641.072.794.182.136.098.216.244.216.484v4.367l-4.9-6.343z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Ln;
