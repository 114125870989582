import { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "@lana-ui/common";
import "./_input.scss";

const propTypes = {
  /**
   * Icono izquierdo.
   */
  leftIcon: PropTypes.string,
  /**
   * Icono derecho.
   */
  rightIcon: PropTypes.string,
  /**
   * Atributo type asociado.
   */
  type: PropTypes.string,
  /**
   * Aributo name asociado.
   */
  name: PropTypes.string,
  /**
   * ID asociado al input.
   */
  id: PropTypes.string,
  /**
   * Placeholder asociado.
   */
  placeholder: PropTypes.string,
  /**
   * Default value.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Falg, ¿está deshabilitado?
   */
  disabled: PropTypes.bool,
  /**
   * Descripciones asociadas de tipo: {status, text}.
   */
  descriptions: PropTypes.array,
  /**
   * Etiqueta meta asociada.
   */
  funcIconDer: PropTypes.func,
  /**
   * Objeto de estado.
   *  1. status (Contiene el estado)
   *  2. msgs (Contiene el objeto de mensaje: {status, msgs: [{status, text}]})
   */
  state: PropTypes.object,
  /**
   * Handler para el evento change.
   */
  onChange: PropTypes.func,
  /**
   * Handler para el evento click.
   */
  onClick: PropTypes.func,
  /**
   * Handler para el evento keyDown.
   */
  onKeyDown: PropTypes.func,
  /**
   * Flag, ¿es de solo lectura?
   */
  readOnly: PropTypes.bool,
  /**
   * Clases adicionales.
   */
  className: PropTypes.string,
  /**
   * Para el caso del Input --large el maxLength debería ser igual a 1
   */
  maxLength: PropTypes.string,
};

const defaultProps = {
  funcIconDer: () => {},
  onChange: () => {},
  onClick: () => {},
  onKeyDown: () => {},
};

const Input = forwardRef((props, ref) => {
  const {
    meta,
    leftIcon,
    rightIcon,
    type,
    name,
    id,
    placeholder,
    value,
    disabled,
    descriptions,
    funcIconDer,
    state,
    className,
    hideStatusIcons,
    hideSuccessStatus,
    maxLength,
    inputLarge,
  } = props;
  const { status, msgs } = state || {};
  const classes = classNames("input", className, {
    "--error right-icon": status === "error",
    "--success right-icon": status === "success",
    "--left-icon": leftIcon,
    "--right-icon": rightIcon,
    "--hide-success-status": hideSuccessStatus,
    "--large": inputLarge,
  });

  return (
    <div className={classes}>
      <div className="box">
        {leftIcon && (
          <div className="left-icon">
            <Icon name={leftIcon} size="s" />
          </div>
        )}

        <input
          type={type}
          name={name}
          id={id}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          ref={ref}
          maxLength={maxLength}
          {...meta}
          {...props}
        />

        {!hideStatusIcons &&
          (status === "error" ? (
            <div className={"right-icon status-icon"}>
              <i className="right-icon icon-error-warning"></i>
            </div>
          ) : (
            status === "success" && (
              <div className={"right-icon status-icon"}>
                <i className="right-icon icon-check-mark"></i>
              </div>
            )
          ))}

        {rightIcon && (
          <div className="right-icon non-status">
            <i
              className={rightIcon}
              {...(funcIconDer && {
                onClick: funcIconDer,
                style: { cursor: "pointer" },
              })}
            ></i>
          </div>
        )}

        {status === "error"
          ? msgs &&
            (maxLength === "1" ? (
              <></>
            ) : (
              <ul className="box description --fivexs">
                {msgs.map((msg, index) => (
                  <li className="box-list" key={index}>
                    {msg.status === "error" && msgs.length > 1 && (
                      <i className="list-icon icon-close"></i>
                    )}
                    {msg.status === "success" && msgs.length > 1 && (
                      <i className="list-icon icon-check-mark"></i>
                    )}
                    {msg.status === "error" && inputLarge && (
                      <i className="list-icon icon-error-warning"></i>
                    )}

                    <p
                      className={
                        msg.status === "error" && inputLarge ? "text-error" : ""
                      }
                    >
                      {msg.text}
                    </p>
                  </li>
                ))}
              </ul>
            ))
          : descriptions && (
              <ul className="box description --fivexs">
                {descriptions.map((msg, index) => (
                  <li className="box-list" key={index}>
                    {status === "success" ? (
                      <i className="list-icon icon-check-mark"></i>
                    ) : (
                      <div className="list-margin"></div>
                    )}
                    <p>{msg.text}</p>
                  </li>
                ))}
              </ul>
            )}
      </div>
    </div>
  );
});

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
