import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './_divider.scss';

const propTypes = {
	/**
	 * Ancho asociado.
	 */
	width: PropTypes.string,
	/**
	 * Color asociado.
	 */
	color: PropTypes.string,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const defaultProps = {
	color: '#CCCCCC',
	width: '100',
};

const Divider = ({ color, width, className }) => {
	const classes = classNames('divider', className);

	return <hr className={classes} style={{ backgroundColor: color, width: width + '%' }} />;
};

Divider.propTypes = propTypes;
Divider.defaultProps = defaultProps;

export default Divider;
