import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_callback-article.scss';

import { Image, Title } from '@lana-ui/common';
import useWindowSize from '../../../common/src/helpers/useWindowSize';

const propTypes = {
    /**
     * Título asociado.
     */
    title: PropTypes.node,
    /**
     * URL asociada a la imagen.
     */
    imageUrl: PropTypes.string,
    /**
     * Descripción asociada.
     */
    description: PropTypes.node,
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
};

const CallbackArticle = ({
    title,
    imageUrl,
    description,
    className,
    bg,
    ...r
}) => {
    const classes = classNames('mc-callback-article', className, {
        '--bg': bg,
    });
    const windowWidth = useWindowSize();
    const isMobile = windowWidth < 768;

    return (
        <div className={classes} {...r}>
            <div className="description">
                {title && (
                    <Title
                        size="s"
                        className="--arial --font-bold callback-article"
                    >
                        {title}
                    </Title>
                )}
                {description}
            </div>

            {imageUrl && !isMobile && (
                <div className="img">
                    <Image src={imageUrl} alt={title} />
                </div>
            )}
        </div>
    );
};

CallbackArticle.propTypes = propTypes;

export default CallbackArticle;
