import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from '../../Store/storeProvider';

const RecaptchaWrapper = ({ children }) => {
    //Ref Recaptcha
    const reRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: 'SET_RECAPTCHA',
            value: reRef,
        });
    }, []);

    return (
        <>
            {children}
            {process.env.REACT_APP_RECAPTCHA_ACTIVE && (
                <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                size="invisible"
                ref={reRef}
                badge="bottomleft"
            />
            )}
            
        </>
    );
};

export default RecaptchaWrapper;
