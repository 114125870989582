import {
  IOSStore,
  AndroidStore,
  Checkmark,
  Email,
  EmailFilled,
  Whatsapp,
  WhatsappFilled,
  Facebook,
  FacebookFilled,
  Twitter,
  TwitterFilled,
  Instagram,
  InstagramFilled,
  Rss,
  Snapchat,
  SnapchatFilled,
  Soundcloud,
  SoundcloudFilled,
  Spotify,
  SpotifyFilled,
  Tiktok,
  Tumblr,
  Twitch,
  Youtube,
  YoutubeFilled,
  Linkedin,
  LinkedinFilled,
  Medium,
  MediumFilled,
  Pinterest,
  PinterestFilled,
  EmailOpen,
  EmailOpenFilled,
  Car,
  Coupons,
  DecoHome,
  Default,
  Eco,
  Entertainment,
  Fashion,
  Healthy,
  More,
  Restaurant,
  ShopOnline,
  Supermarket,
  Trip,
  ClearNight,
  Cloudy,
  Rain,
  RainyCloudy,
  Snow,
  SnowCloudy,
  Storm,
  StormCloudy,
  Sun,
  SunCloudy,
  Windy,
  BankCard,
  Bill,
  Qr,
  Shop,
  Eye,
  EyeOff,
  Lock,
  Star,
  StarFilled,
  Unlock,
  Radio,
  Sections,
  Home,
  HomeFilled,
  Settings,
  SettingsFilled,
  CardIcon,
  Like,
  Flag,
  Reply,
  Info,
  Calendar,
  CheckboxBlank,
  Map,
  Alert,
  ErrorWarning,
  Edit,
  Delete,
  Add,
  Direction,
  Navigation,
  NavigationFocus,
  Bell,
  Cursor,
  Comment,
  LockFilled,
  UnlockFilled,
  EyeFilled,
  EyeOffFilled,
  RadioOn,
  SectionFilled,
  Club,
  ClubFilled,
  LikeFilled,
  FlagFilled,
  ReplyFilled,
  InfoFilled,
  CalendarFilled,
  CheckboxFilled,
  MapFilled,
  AlertFilled,
  ErrorWarningFilled,
  EditFilled,
  DeleteFilled,
  AddFilled,
  DirectionFilled,
  NavigationFilled,
  NavigationFocusFilled,
  BellFilled,
  CursorFilled,
  CommentFilled,
  Menu,
  Search,
  Timer,
  UserIcon,
  ArrowLeft,
  ArrowRight,
  ArrowLeftLine,
  ArrowDown,
  ArrowUp,
  Close,
  Slow,
  Fast,
  BulletDefault,
  BulletOutline,
  BulletXs,
  VerticalLine,
  HorizontalLine,
  NoWifi,
  Forbid,
  Plus,
  ArrowRightLine,
  Profile,
  Ln,
  Group,
  Filter,
  Share,
  ShareAndroid,
  Copy,
  Clock,
  Checked,
  Android,
  Ios,
  Order,
  Go,
  Emoji,
  DownloadFile,
  Download,
  MoreVertical,
  Swap,
  ListIcon,
  UserUnlock,
  ContrastFill,
  Knife,
  Fire,
  Headset,
  Play,
  Pause,
  Zoom,
  HeadsetFilled,
  PlayFilled,
  SkipBack,
  SkipForward,
  Stop,
  AppleButton,
  GoogleButton,
  FacebookButton,
} from "@lana-ui/common";

const mapper = {
  "facebook-button": <FacebookButton />,
  "apple-button": <AppleButton />,
  "google-button": <GoogleButton />,
  "ios-store": <IOSStore />,
  "android-store": <AndroidStore />,
  email: <Email />,
  "email-filled": <EmailFilled />,
  whatsapp: <Whatsapp />,
  "whatsapp-filled": <WhatsappFilled />,
  facebook: <Facebook />,
  "facebook-filled": <FacebookFilled />,
  twitter: <Twitter />,
  "twitter-filled": <TwitterFilled />,
  instagram: <Instagram />,
  "instagram-filled": <InstagramFilled />,
  rss: <Rss />,
  snapchat: <Snapchat />,
  "snapchat-filled": <SnapchatFilled />,
  soundcloud: <Soundcloud />,
  "soundcloud-filled": <SoundcloudFilled />,
  spotify: <Spotify />,
  "spotify-filled": <SpotifyFilled />,
  tiktok: <Tiktok />,
  tumblr: <Tumblr />,
  twitch: <Twitch />,
  youtube: <Youtube />,
  "youtube-filled": <YoutubeFilled />,
  medium: <Medium />,
  "medium-filled": <MediumFilled />,
  linkedin: <Linkedin />,
  "linkedin-filled": <LinkedinFilled />,
  pinterest: <Pinterest />,
  "pinterest-filled": <PinterestFilled />,
  "email-open": <EmailOpen />,
  "email-open-filled": <EmailOpenFilled />,
  car: <Car />,
  coupons: <Coupons />,
  "deco-&-home": <DecoHome />,
  default: <Default />,
  eco: <Eco />,
  entertainment: <Entertainment />,
  fashion: <Fashion />,
  healthy: <Healthy />,
  more: <More />,
  restaurant: <Restaurant />,
  "shop-online": <ShopOnline />,
  supermarket: <Supermarket />,
  trip: <Trip />,
  "clear-night": <ClearNight />,
  cloudy: <Cloudy />,
  rain: <Rain />,
  "rainy-cloudy": <RainyCloudy />,
  snow: <Snow />,
  "snow-cloudy": <SnowCloudy />,
  storm: <Storm />,
  "storm-cloudy": <StormCloudy />,
  sun: <Sun />,
  "sun-cloudy": <SunCloudy />,
  windy: <Windy />,
  "bank-card": <BankCard />,
  bill: <Bill />,
  qr: <Qr />,
  shop: <Shop />,
  eye: <Eye />,
  "eye-off": <EyeOff />,
  lock: <Lock />,
  star: <Star />,
  "star-filled": <StarFilled />,
  unlock: <Unlock />,
  radio: <Radio />,
  sections: <Sections />,
  home: <Home />,
  "home-filled": <HomeFilled />,
  checkmark: <Checkmark />,
  settings: <Settings />,
  "settings-filled": <SettingsFilled />,
  "card-icon": <CardIcon />,
  like: <Like />,
  flag: <Flag />,
  reply: <Reply />,
  info: <Info />,
  calendar: <Calendar />,
  "checkbox-blank": <CheckboxBlank />,
  map: <Map />,
  alert: <Alert />,
  "error-warning": <ErrorWarning />,
  edit: <Edit />,
  delete: <Delete />,
  add: <Add />,
  direction: <Direction />,
  navigation: <Navigation />,
  "navigation-focus": <NavigationFocus />,
  bell: <Bell />,
  cursor: <Cursor />,
  comment: <Comment />,
  "lock-filled": <LockFilled />,
  "unlock-filled": <UnlockFilled />,
  "eye-filled": <EyeFilled />,
  "eye-off-filled": <EyeOffFilled />,
  "radio-on": <RadioOn />,
  "section-filled": <SectionFilled />,
  club: <Club />,
  "club-filled": <ClubFilled />,
  "like-filled": <LikeFilled />,
  "flag-filled": <FlagFilled />,
  "reply-filled": <ReplyFilled />,
  "info-filled": <InfoFilled />,
  "calendar-filled": <CalendarFilled />,
  "checkbox-filled": <CheckboxFilled />,
  "map-filled": <MapFilled />,
  "alert-filled": <AlertFilled />,
  "error-warning-filled": <ErrorWarningFilled />,
  "edit-filled": <EditFilled />,
  "delete-filled": <DeleteFilled />,
  "add-filled": <AddFilled />,
  "direction-filled": <DirectionFilled />,
  "navigation-filled": <NavigationFilled />,
  "navigation-focus-filled": <NavigationFocusFilled />,
  "bell-filled": <BellFilled />,
  "cursor-filled": <CursorFilled />,
  "comment-filled": <CommentFilled />,
  menu: <Menu />,
  search: <Search />,
  timer: <Timer />,
  "user-icon": <UserIcon />,
  "arrow-left": <ArrowLeft />,
  "arrow-right": <ArrowRight />,
  "arrow-down": <ArrowDown />,
  "arrow-up": <ArrowUp />,
  close: <Close />,
  slow: <Slow />,
  fast: <Fast />,
  "bullet-default": <BulletDefault />,
  "bullet-outline": <BulletOutline />,
  "bullet-xs": <BulletXs />,
  "vertical-line": <VerticalLine />,
  "horizontal-line": <HorizontalLine />,
  "no-wifi": <NoWifi />,
  forbid: <Forbid />,
  plus: <Plus />,
  "arrow-right-line": <ArrowRightLine />,
  "arrow-left-line": <ArrowLeftLine />,
  profile: <Profile />,
  ln: <Ln />,
  group: <Group />,
  filter: <Filter />,
  share: <Share />,
  "share-android": <ShareAndroid />,
  copy: <Copy />,
  clock: <Clock />,
  checked: <Checked />,
  android: <Android />,
  ios: <Ios />,
  order: <Order />,
  go: <Go />,
  emoji: <Emoji />,
  "download-file": <DownloadFile />,
  download: <Download />,
  "more-vertical": <MoreVertical />,
  swap: <Swap />,
  "list-icon": <ListIcon />,
  "user-unlock": <UserUnlock />,
  "contrast-fill": <ContrastFill />,
  knife: <Knife />,
  fire: <Fire />,
  headset: <Headset />,
  play: <Play />,
  pause: <Pause />,
  zoom: <Zoom />,
  "headset-filled": <HeadsetFilled />,
  "play-filled": <PlayFilled />,
  "skip-back": <SkipBack />,
  "skip-forward": <SkipForward />,
  stop: <Stop />,
};

export default mapper;
