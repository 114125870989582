import React, { useEffect } from 'react';
import { Layout } from '@lana-ui/common';
import { HeaderLogin } from '@lana-ui/login';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_login-layout.scss';
import { useStore } from '../../../../../Store/storeProvider';

const propTypes = {
    /**
     * Clases adicionales.
     */
    className: PropTypes.string,
};

const LoginLayout = ({
    showArrowBack,
    children,
    className,
    black,
    checkout,
    club,
}) => {
    const classes = classNames(className);
    const { Config } = useStore();

    useEffect(() => {
        const script = document.createElement('script');

        script.src =
            'https://api.wcx.cloud/widget/?id=35bfef27b1074750908a67ae7494f690';
        script.async = true;

        document.body.appendChild(script);
    }, []);

    return (
        <Layout className={classes} {...(black && { black: black })}>
            <HeaderLogin
                showArrowBack={showArrowBack}
                black={black}
                checkout={checkout}
                club={club}
                isUserModule={Config.IsUserModule}
            />
            <Layout as="section" type="wrapped-extra-small">
                {children}
            </Layout>
        </Layout>
    );
};

LoginLayout.propTypes = propTypes;

export default LoginLayout;
