/**
 * Recibimos un objeto { key: value} lo convertimos en array
 * y buscamos si existe el value dentro de el comparando el parametro key.
 * @param {object} obj
 * @param {char} key
 * @returns {Boolean}
 */
const SomeInArray = (obj, key) => {
    if (!!!obj || !!!key) return;
    let arr = Object.values(obj);
    return arr.some((elem) => elem == key);
};

export default SomeInArray;
