import PropTypes from "prop-types";

import classNames from "classnames";
import "./_wizard.scss";

import WizardItem from "./WizardItem";
import useResponsive from "../../../../../Hooks/useResponsive";

const propTypes = {
  /**
   * Paso activo.
   */
  activeStep: PropTypes.number,
  /**
   * Objeto que contiene los pasos,
   * texto, número.
   */
  steps: PropTypes.object,
  /**
   * Clases adicionales.
   */
  className: PropTypes.string,
  /**
   * Wizard de tipo mobile?
   */
  mobile: PropTypes.bool,
};

const Wizard = ({ steps, activeStep, className, mobile, ...r }) => {
  const classes = classNames("wizard", className);

  const stepsLength = Object.keys(steps).length,
    queryMobile = useResponsive("(max-width:767px)");

  const isInBetween = (step) => {
    return 1 < step && step < stepsLength;
  };

  const evaluateClassNames = (stepNumber) => {
    let className = "";

    if (activeStep === stepNumber)
      if (activeStep === 1) className = "active first";
      else if (activeStep === stepsLength) className = "active last";
      else className = "active";

    if (stepNumber === 1)
      className += activeStep !== stepNumber ? "done first" : "";
    else if (isInBetween(stepNumber)) {
      className +=
        activeStep < stepNumber
          ? "disabled"
          : activeStep > stepNumber
          ? "done"
          : "";
      className += " middle";
    } else className += stepNumber > activeStep ? "disabled last" : "";

    return className;
  };

  const stepText = (number) => {
    const step = Object.keys(steps).find(
      (step) => steps[step].number === number
    );
    return steps[step].text;
  };

  return (
    <div className={classes} {...r}>
      {!mobile ? (
        <div className="wizard-desktop">
          {Object.keys(steps).map((step, index) => (
            <WizardItem
              stepNumber={steps[step].number}
              mod={evaluateClassNames(steps[step].number)}
              {...((!queryMobile.matches ||
                (queryMobile.matches && stepsLength < 5)) && {
                text: steps[step].text,
              })}
              key={index}
            />
          ))}
        </div>
      ) : (
        <div className="wizard-mobile">
          <WizardItem
            stepsLength={stepsLength}
            stepNumber={activeStep}
            mobile={mobile}
            textNextStep={
              activeStep !== stepsLength ? stepText(activeStep + 1) : null
            }
            mod={evaluateClassNames(activeStep)}
            text={stepText(activeStep)}
          />
        </div>
      )}
    </div>
  );
};

Wizard.propTypes = propTypes;

export default Wizard;
