import React from "react";
const DeleteFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M17 6h4a1 1 0 110 2h-1v13a1 1 0 01-1 1H5a1 1 0 01-1-1V8H3a1 1 0 010-2h4V3a1 1 0 011-1h8a1 1 0 011 1v3zm-7 5a1 1 0 00-1 1v4a1 1 0 102 0v-4a1 1 0 00-1-1zm4 0a1 1 0 00-1 1v4a1 1 0 102 0v-4a1 1 0 00-1-1zM9 4v2h6V4H9z"
      ></path>
    </svg>
  );
};

export default DeleteFilled;
