import React from "react";
const Plus = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M11 11V6a1 1 0 112 0v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H6a1 1 0 110-2h5z"
      ></path>
    </svg>
  );
};

export default Plus;
