import React from "react";
const Reply = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M12 14v3.404a1 1 0 01-1.63.777l-7.91-6.404a1 1 0 010-1.554l7.91-6.404a1 1 0 011.63.777V8c5.523 0 10 4.477 10 10 0 .273-.01.543-.032.81A9 9 0 0014 14h-2zm-2 1.308V12h4c1.42 0 2.791.271 4.057.773A7.982 7.982 0 0012 10h-2V6.692L4.679 11 10 15.308z"
      ></path>
    </svg>
  );
};

export default Reply;
