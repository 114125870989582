import { trackCustomEvent } from '../Config/DataDog';
import ModosUrl from '../Models/ModosUrl';
import sitiosId from '../Models/SitiosId';
import RegexValidator from './regexValidator';
import SomeInArray from './SomeInArray';

const GetParamURL = () => {
    try {
        let location = new URL(document.location);
        const pathValues = getPathArray(location);
        let params = location.searchParams;
        let isParamsCallback = params.get('callback');
        return {
            Modo:
                pathValues.Modo !== '' && SomeInArray(ModosUrl, pathValues.Modo)
                    ? pathValues.Modo
                    : ModosUrl.default,
            SitioId:
                pathValues.SitioId !== '' && SomeInArray(sitiosId, pathValues.SitioId)
                    ? pathValues.SitioId
                    : sitiosId.webLN,
            URLCallback:
                isParamsCallback &&
                    RegexValidator.dominiosPermitidos.test(
                        window.atob(isParamsCallback)
                    )
                    ? window.atob(isParamsCallback)
                    : process.env.REACT_APP_LNREDIRECT,
        };
    } catch (error) {
        trackCustomEvent("Ingresar - GetParamUrl", true, { message: error.message, path: location.pathname });
        console.log('no se pudo obtener modo y sitio');
    }
};

const getPathArray = (location) => {
    let pathArray = location.pathname.split('/').filter(item => item !== '');
    const pathValues = pathArray.slice(-2)
    return { Modo: pathValues[0], SitioId: pathValues[1] }
}
export default GetParamURL;
