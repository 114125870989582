import React from "react";
const SnapchatFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 19"
      {...r}
    >
      <path
        fill="#272727"
        d="M9.871 18.764c-1.19 0-1.984-.561-2.693-1.056-.503-.357-.976-.696-1.533-.79a4.568 4.568 0 00-.803-.066c-.472 0-.847.071-1.114.125-.17.03-.312.058-.424.058-.116 0-.263-.032-.32-.228-.05-.16-.081-.312-.112-.459-.08-.37-.147-.597-.286-.62-1.489-.227-2.38-.57-2.554-.976-.014-.044-.031-.09-.031-.125-.01-.125.08-.227.205-.25 1.181-.196 2.242-.824 3.138-1.858.696-.803 1.035-1.579 1.066-1.663 0-.01.009-.01.009-.01.17-.351.205-.65.102-.895-.191-.46-.825-.656-1.257-.79-.111-.03-.205-.066-.285-.093-.37-.147-.986-.46-.905-.892.058-.312.472-.535.811-.535.094 0 .174.014.24.05.38.173.723.262 1.017.262.366 0 .54-.138.584-.182a24.93 24.93 0 00-.035-.593c-.09-1.365-.192-3.059.24-4.03C6.229.241 8.984.008 9.8.008L10.156 0h.05c.815 0 3.57.227 4.868 3.139.437.971.33 2.67.24 4.03l-.008.067c-.01.182-.023.356-.032.535.045.035.205.169.535.173.286-.008.598-.102.954-.263a.804.804 0 01.312-.066c.125 0 .25.03.357.066h.009c.299.112.495.321.495.54.009.205-.152.517-.914.825-.08.03-.174.067-.285.093-.424.13-1.057.335-1.258.79-.111.24-.066.548.103.895 0 .01.009.01.009.01.049.124 1.337 3.049 4.204 3.526a.246.246 0 01.205.25.267.267 0 01-.031.129c-.174.41-1.057.744-2.555.976-.138.022-.205.25-.285.62-.032.154-.07.307-.112.459-.044.147-.138.227-.298.227h-.023a2.24 2.24 0 01-.423-.049 5.282 5.282 0 00-1.115-.116 4.97 4.97 0 00-.802.067c-.553.09-1.03.433-1.534.79-.717.49-1.515 1.051-2.697 1.051h-.254z"
      ></path>
    </svg>
  );
};

export default SnapchatFilled;
