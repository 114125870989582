import React from "react";
const Soundcloud = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 13"
      {...r}
    >
      <path
        fill="#272727"
        d="M4 4a1 1 0 011 1v7a1 1 0 11-2 0V5a1 1 0 011-1zm3 1a1 1 0 011 1v6a1 1 0 11-2 0V6a1 1 0 011-1zm3-4a1 1 0 011 1v10a1 1 0 01-2 0V2a1 1 0 011-1zm5-1a6 6 0 015.996 5.775l.003.26a3.5 3.5 0 01-.307 6.96L20.5 13h-3.501a1 1 0 01-.117-1.993L17 11h3.447l.138-.002a1.5 1.5 0 00.267-2.957l-.135-.026-1.77-.252L19 5.976l-.004-.176A4 4 0 0015.2 2.005L15 2c-.268 0-.531.026-.788.077L14 2.126V12a1 1 0 01-.883.993L13 13a1 1 0 01-1-1L11.999.803A5.972 5.972 0 0115 0zM1 6a1 1 0 011 1v4a1 1 0 11-2 0V7a1 1 0 011-1z"
      ></path>
    </svg>
  );
};

export default Soundcloud;
