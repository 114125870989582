import PropTypes from "prop-types";

import classNames from "classnames";
import "./_logo.scss";

import { Link } from "@lana-ui/common";

const propTypes = {
  /**
   * Nombre asociado.
   */
  name: PropTypes.string.isRequired,
  /**
   * Atributo href asociado.
   */
  href: PropTypes.string,
  /**
   * Atributo target asociado.
   */
  target: PropTypes.string,
  /**
   * Título asociado.
   */
  title: PropTypes.string,
  /**
   * Clases adicionales.
   */
  className: PropTypes.string,
  /**
   * Tamaño del logo.
   */
  size: PropTypes.string,
};

const Logo = ({ name, href, title, target, className, size, ...r }) => {
  const classes = classNames("logo", className, {
    [`logo-${name}`]: name,
    [`--${size}`]: size,
  });

  return (
    <>
      {href ? (
        <Link href={href} title={title} target={target}>
          <i className={classes} {...r} />
        </Link>
      ) : (
        <i className={classes} {...r} />
      )}
    </>
  );
};

Logo.propTypes = propTypes;

export default Logo;
