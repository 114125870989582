import React from "react";
const Timer = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M17.618 5.968l1.453-1.453 1.414 1.414-1.453 1.453a9 9 0 11-1.414-1.414zM12 20a7.001 7.001 0 004.95-11.95A7 7 0 1012 20zM11 8h2v6h-2V8zM8 1h8v2H8V1z"
      ></path>
    </svg>
  );
};

export default Timer;
