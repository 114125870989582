import React from 'react';
import { Paragraph } from '@lana-ui/common';
import { ConfirmacionLogin } from '@lana-ui/login';
import ConfirmacionConTarjetaClub from '@images/confirmacionConTarjetaClub';
import ConfirmacionSinTarjetaClub from '@images/confirmacionSinTarjetaClub';
import { useStore } from '../../Store/storeProvider';
import '@styles/Components/Cierre.scss';

const Cierre = ({
    title,
    cardType,
    firstBtnLabel,
    secondBtnLabel,
    onClickCierre,
    secondBtnOnClick,
    validarPin,
    hasSuscripDigital,
}) => {
    const { Config } = useStore();
    const urlToNewsletter = "https://newsletter.lanacion.com.ar/"

    return (
        <div className="cierre">
            {(cardType === 'P' || cardType === 'B' || validarPin) && (
                <ConfirmacionLogin
                    titleText={title}
                    image={
                        <ConfirmacionConTarjetaClub
                            width="201.24"
                            height="160"
                        />
                    }
                    firstBtnLabel={
                        hasSuscripDigital ? 'Finalizar' : firstBtnLabel
                    }
                    firstBtnOnClick={() =>
                        hasSuscripDigital
                            ? (window.location.href = Config.URLCallback)
                            : onClickCierre()
                    }
                    secondBtnLabel={!hasSuscripDigital && secondBtnLabel}
                    secondBtnOnClick={() =>
                        !secondBtnOnClick
                            ? (window.location.href = Config.URLCallback)
                            : secondBtnOnClick()
                    }
                >
                    <Paragraph as="p" size="2xs" className="--fourxs text con-tarjeta-club"> 
                        <div className='--mb-24'>
                            Ya contás con Acceso Digital
                        </div>
                        También podés suscribirte a nuestros <strong>Newsletters</strong>{' '}
                        para manterte informado, haciendo{' '}  
                        <button 
                            onClick={urlToNewsletter} 
                            className="link user-links --arial --font-bold"
                        >
                            click acá
                        </button>
                    </Paragraph>
                </ConfirmacionLogin>
            )}

            {(cardType === 'C' || cardType === '') && (
                <ConfirmacionLogin
                    titleText={title}
                    image={
                        <ConfirmacionSinTarjetaClub
                            width="201.24"
                            height="160"
                        />
                    }
                    firstBtnLabel="Finalizar"
                    showCallbackArticle
                    firstBtnOnClick={() =>
                        (window.location.href = Config.URLCallback)
                    }
                >
                    <Paragraph as="p" size="2xs" className="--fourxs text sin-tarjeta-club">
                        Ya podés configurar y recibir los titulares de tus temas{' '}
                        de interés en tu casilla de email y asociar tu cuenta a{' '}
                        Facebook y Google.
                    </Paragraph>
                </ConfirmacionLogin>
            )}
        </div>
    );
};

export default Cierre;
