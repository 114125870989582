import React from 'react';
import { ErrorNotFound } from '@lana-ui/common';
import { LoginLayout } from '@lana-ui/login';

function Error404 (props) {
  if(!props.showHeader) return <ErrorNotFound buttonText={props.buttonText} handleClickButton={props.handleClickButton}/>;
  return (
      <LoginLayout>
        <ErrorNotFound buttonText={props.buttonText} handleClickButton={props.handleClickButton}/>
      </LoginLayout>
  );
}

  
export default Error404;