const trackDefault = (category, action, label, additional) => {
    let data = {
        event: 'trackClick',
        eventCategory: category,
        eventAction: action,
        eventLabel: label
    };
    data = { ...data, ...additional};
    window.dataLayer.push(data);
}

const trackPage = (pageName) => {
    let data = {
        event: 'trackPage',
        pageName: pageName
    };
    window.dataLayer.push(data);
}

const trackPageAndCheckout = (pageName) => {
    let data = {
        event: 'trackPage',
        pageName: pageName,
        checkoutProd: 'Acceso Digital Bonificado'
    };
    window.dataLayer.push(data);
}

const trackClickSSO = (loginMethod) => {
    let data = {
        event: 'trackClickSSO',
        loginMethod: loginMethod,
    };
    window.dataLayer.push(data);
}

const trackEvent = (category, action, label) => {
    let data = {
        event: 'trackEvent',
        eventCategory: category,
        eventAction: action,
        eventLabel: label
    };
    window.dataLayer.push(data);
}

const trackLogin = (loginMethod, newUser, userId) => {
    let data = {
        event: 'trackLogin',
        newUser: newUser,
        loginMethod: loginMethod,
        userId: userId
    };
    window.dataLayer.push(data);
}

const trackUser = ({ event, loginMethod, userId, originId, origenParsed }) => {
    let data = {
        event,
        loginMethod,
        userId,
        originId,
        origenParsed
    };
    window.dataLayer.push(data);
}

const trackClick = (eventLabel) => {
    let data = {
        event: 'trackClick',
        eventLabel: eventLabel,
    };
    window.dataLayer.push(data);
}

const trackCreateAccount = () => {
    let data = {
        event: 'trackCreateAccount',
    };
    window.dataLayer.push(data);
}

const trackActivateAccount = () => {
    let data = {
        event: 'trackActivateAccount',
    };
    window.dataLayer.push(data);
}

export default {
    trackDefault,
    trackPage,
    trackPageAndCheckout,
    trackClickSSO,
    trackEvent,
    trackLogin,
    trackUser,
    trackClick,
    trackCreateAccount,
    trackActivateAccount,
} 