import React from "react";
const BankCard = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 18"
      {...r}
    >
      <path
        fill="#272727"
        d="M1 0h18a1 1 0 011 1v16a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm17 9H2v7h16V9zm0-4V2H2v3h16z"
      ></path>
    </svg>
  );
};

export default BankCard;
