import React from "react";
const Windy = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
      {...r}
    >
      <path
        fill="#272727"
        d="M8.5 15H2v-2h6.5a3.5 3.5 0 11-3.278 4.73l1.873-.703A1.5 1.5 0 108.5 15zM3 9h13.5a3.5 3.5 0 11-3.278 4.73l1.873-.703A1.5 1.5 0 1016.5 11H3a3 3 0 110-6h8.5a1.5 1.5 0 10-1.405-2.027l-1.873-.702A3.501 3.501 0 0115 3.5 3.5 3.5 0 0111.5 7H3a1 1 0 100 2z"
      ></path>
    </svg>
  );
};

export default Windy;
