import GoogleAnalytics from './GoogleAnalytics';
import sitiosId from '../Models/SitiosId';
import LoginTypes from '../Models/LoginTypes';
import { User } from '@auth0/auth0-react';

/**
 * Se almacena en el data layer el método de autenticación del usuario y sus datos asociados.
 * @param { User | undefined } user Datos del usuario autenticado
 * @param { Number } sitioId Valor único que identifica el sitio del que fue redireccionado el usuario
 */
const trackUserHandler = (user, sitioId) => {
  
  if (user) {
      const origenParsed = Object.keys(sitiosId).find(key => sitiosId[key] === sitioId);
      
      const data = {
          event: !!user.is_registration ? "trackRegister" : "trackLogin",
          loginMethod: LoginTypes[user.connection_type],
          userId: user.app_metadata.ln_user_id,
          originId: sitioId,
          origenParsed: origenParsed
      }

      GoogleAnalytics.trackUser(data);
  }
};

export {
    trackUserHandler
}