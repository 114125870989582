import React from "react";
const CheckboxFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M6 2a4 4 0 00-4 4v12a4 4 0 004 4h12a4 4 0 004-4V6a4 4 0 00-4-4H6zm11.659 5.247a1 1 0 01.094 1.412l-7 8a1 1 0 01-1.46.048l-3-3a1 1 0 111.414-1.414l2.244 2.244 6.296-7.196a1 1 0 011.412-.094z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default CheckboxFilled;
