import React from "react";
const Pause = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M6 6a1 1 0 012 0v12a1 1 0 11-2 0V6zm10 0a1 1 0 112 0v12a1 1 0 11-2 0V6z"
      ></path>
    </svg>
  );
};

export default Pause;
