import ApiService from '../../src/Services/ApiService';

export default async function useGetMailWithDni(params)
{
    const { REACT_APP_APILOGIN } = process.env;
    const requestOptions = {
          'Content-Type': 'application/json' 
      }; 
      
    const url = `${REACT_APP_APILOGIN}UsuarioV1/ObtenerTipoSesionYEmailPorDni`;
    return await ApiService.postWithHeaders(url, params, requestOptions,false);
} 