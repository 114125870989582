import { readCookie } from "./Cookies";
import { getItem, removeItem, setItem } from "./LocalStorage";

let key = "CustomDimension";
let objCustomDimension = {};

const get = function () {
  objCustomDimension = getItem(key);
  return objCustomDimension;
};

const clear = function () {
  objCustomDimension = {};
  removeItem(key);
};

const set = function (prop, value) {
  if (!objCustomDimension) objCustomDimension = {};
  objCustomDimension[prop] = value;
  return objCustomDimension;
};

const save = function () {
  setItem(key, objCustomDimension);
};

const exist = function () {
  return get() !== null;
};

const setSuscriptorType = function (value) {
  set("suscriptorType", value);
};
const setRegistrado = function (value) {
  set("usuarioRegistrado", value);
};
const setUserId = function (value) {
  set("userId", value);
};

const createCustomDimension = () => {
  if (!exist()) {
    setRegistrado("no");
    setSuscriptorType("N/A");
    setUserId("");
    save();
  }

  let CD = get();
  let withToken = !!!readCookie("token");
  let usuarioLogeado = withToken ? "no" : "yes";
  let payUser = !readCookie("ProductoPremiumId") ? "no" : "yes";
  let userId = readCookie("usuario%5Fid");
  let clubLN = readCookie("usuarioDetalleClubNacion");
  let notasLeidas = readCookie("countNotas");

  if (!window.dataLayer) {
    window.dataLayer = [{}];
  }

  dataLayer[0].notasLeidas = notasLeidas ? notasLeidas : 0;
  dataLayer[0].usuarioLogeado = usuarioLogeado;
  dataLayer[0].usuarioRegistrado = CD.usuarioRegistrado;
  dataLayer[0].payUser = payUser;
  dataLayer[0].suscriptorType = CD.suscriptorType;
  dataLayer[0].userId = CD.userId;
  dataLayer[0].credentialType = clubLN ? clubLN : "N/A";
};

export {
  setSuscriptorType,
  setRegistrado,
  setUserId,
  save,
  createCustomDimension,
};
