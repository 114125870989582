import React from "react";
const Default = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M23.063 18.228H6.375a.95.95 0 01-.938-.938.95.95 0 01.938-.937h16.688a.95.95 0 01.937.937.95.95 0 01-.938.938zm-10.5-4.152H6.32a.95.95 0 01-.937-.938.95.95 0 01.937-.937h6.242a.95.95 0 01.937.937.932.932 0 01-.938.938z"
      ></path>
      <path
        fill="#272727"
        d="M1.875 20.05a.937.937 0 01-.857-.537c-.054-.134-.134-.268-.188-.401-.562-1.34-.83-2.786-.83-4.26a10.863 10.863 0 013.188-7.687c.99-1.018 2.17-1.794 3.455-2.357a10.962 10.962 0 014.232-.857c1.473 0 2.893.295 4.259.857a10.922 10.922 0 013.455 2.33c.991.991 1.795 2.17 2.33 3.456.242.535.402 1.098.563 1.66a.912.912 0 01-.696 1.125.912.912 0 01-1.125-.696 9.378 9.378 0 00-.456-1.393 9.01 9.01 0 00-4.794-4.794c-1.125-.483-2.304-.697-3.51-.697-1.205 0-2.41.241-3.508.697a9.009 9.009 0 00-4.795 4.794c-.482 1.179-.723 2.357-.723 3.563 0 1.205.241 2.41.696 3.509.054.107.108.214.134.321a.957.957 0 01-.455 1.259.615.615 0 01-.375.107z"
      ></path>
    </svg>
  );
};

export default Default;
