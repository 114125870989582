import React from 'react';
import { TermsLogin } from '../TermsLogin';
import Url from '../../../Helpers/Url'
import { getCallbackEmailVerification } from '../../../Helpers/Auth0StorageManager';

export const ConfirmarMail = ({ user, comeBack }) => {

   const callback = getCallbackEmailVerification();
  return (
    <div className="container-found-mail">
                <header className="found-description">
                    <h1 className="found-description-title">
                        Encontramos una cuenta con este número de DNI {user.dni}
                    </h1>
                </header>
                <section className="found-mail-text">
                    <div>El email con el que te registraste es:</div>
                    <div>
                        
                        <strong>{user.email}</strong>
                    </div>
                    <div>Tu método de acceso es:</div>
                    <div>{user.tipoDeIngreso}</div>
                    <div>
                        Al iniciar sesión, deberás seleccionar la opción
                        "Iniciar sesión con Google"
                    </div>
                    <div>¿Reconocés este mail? </div>
                </section>
                <div className="container-button-dni">
                    <button
                        className="ln-button --primary button-dni"
                        onClick={() => Url.RedirectToLoginPreserveCallback(callback)}
                    >
                        {'Sí, ir a iniciar sesión'}
                    </button>
                    <button
                        className="ln-button --secondary button-dni"
                        onClick={() => comeBack()}
                    >
                        {'No, volver atrás'}
                    </button>
                </div>
                <TermsLogin />
            </div>
  )
}