import {
  DiccionarioCookiesAGuardarBorrar,
  ExpirationDays,
} from "../Config/Cookies";
import { createCookie, readCookie } from "./Cookies";

const setupCookies = (obj) => {      
  for (let key in obj) {
    let aux;
    let cookie;
    // Busco si la key se encuentra en el diccionario de cookies para ver si la grabo o no .
    if (
      DiccionarioCookiesAGuardarBorrar.indexOf(key) > -1 &&
      typeof obj[key] == "string"
    ) {
      switch (key) {
        case "UsuarioDetalleGuid":
          //let _token = readCookie("token") || null;
          aux = "usuario%5Fdetalle%5Fguid";
          cookie = "";// "{" + _token + "}";
          break;
        case "UsuarioDetalleNick":
          aux = "usuario%5Fdetalle%5Fnick";
          cookie = obj[key];
          break;
        case "UsuarioId":
          aux = "usuario%5Fid";
          cookie = obj[key];
          break;
        case "UsuarioUsuario":
          aux = "usuario%5Fusuario";
          cookie = obj[key];
          break;
        case "usuarioLogTkn":
          aux = "usuario%5Flogtkn";
          cookie = obj[key];
          break;
        case "TokenJWT":
          aux = "PersoTKN";
          cookie = "";//obj[key];
          break;
        default:
          if (key == "token") {
            //TODO logear en datadog
            console.error("setupCookies token default" );
          }

          aux = key;
          cookie = obj[key];
      }
      createCookie(aux, cookie, ExpirationDays);
    }
  }

//   storage?.sessionStorage.clear();
};

export default setupCookies;
