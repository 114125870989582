import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './_button.scss';

import ConditionalWrapper from './ConditionalWrapper';
import { Icon } from '@lana-ui/common';

const propTypes = {
	/**
	 * Label del botón
	 */
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
	/**
	 * Modificador de estado.
	 */
	mod: PropTypes.oneOf(['', 'primary', 'secondary', 'tertiary', 'special']),
	/**
	 * Tipo de elemento a renderizar.
	 */
	as: PropTypes.oneOf(['input', 'button', 'a']),
	/**
	 * Url asociado al link.
	 */
	href: PropTypes.string,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Atributo target asociado
	 * al link.
	 */
	target: PropTypes.string,
	/**
	 * Icono asociado al button.
	 */
	icon: PropTypes.string,
	/**
	 * Tamaño del botón.
	 */
	size: PropTypes.oneOf(['mini', 'small', 'medium', 'large']),
	/**
	 * Flag, ¿está deshabilitado?
	 */
	disabled: PropTypes.bool,
	/**
	 * Tamaño del icono.
	 */
	iconSize: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
	/**
	 * Handler asociado al evento
	 * click.
	 */
	onClick: PropTypes.func,
	/**
	 * Flag, ¿el icono está
	 * posicionado a la izquierda?
	 */
	isFirstPositioned: PropTypes.bool,
	/**
	 * Flag, ¿es de solo lectura?
	 */
	readOnly: PropTypes.string,
	/**
	 * Flag, ¿sin estilos?
	 */
	unstyled: PropTypes.bool,
	/**
	 * Flag, ¿ocupar el ancho del padre?
	 */
	block: PropTypes.bool,
	/**
	 * Flag, ¿tiene la visual de un link?
	 */
	link: PropTypes.bool,
};

const defaultProps = {
	mod: 'primary',
	size: 'medium',
	iconSize: 's',
};

const Button = forwardRef(
	(
		{
			as,
			href,
			className,
			target,
			label,
			size,
			disabled,
			type,
			icon,
			onClick,
			onChange,
			iconSize,
			isFirstPositioned,
			readOnly,
			mod,
			unstyled,
			block,
			link,
			google,
			apple,
			facebook,
			...r
		},
		ref
	) => {
		const classes = classNames('button --font-bold', className, {
			'--disabled': disabled,
			[`--${mod}`]: mod,
			[`--${size}`]: size,
			'--link': link,
			'--icon-only': !label,
			'--unstyled': unstyled,
			'--block': block,
			'--google': google,
			'--apple': apple,
			'--facebook': facebook,
		});
		const CustomTag = as || 'button';
		const iconMargin = isFirstPositioned ? { marginRight: '8px' } : { marginLeft: '8px' };

		return (
			<ConditionalWrapper
				condition={CustomTag === 'input'}
				wrapper={(children) => (
					<CustomTag
						className={classes}
						type={type ? type : 'submit'}
						value={children.slice(1, -1)}
						ref={ref}
						onClick={onClick}
						onChange={onChange}
						readOnly={readOnly}
						disabled={disabled}
						{...r}
					/>
				)}
				defaultWrapper={(children) => (
					<CustomTag
						className={classes}
						{...(CustomTag === 'a' && { href: href })}
						ref={ref}
						target={target}
						{...(CustomTag === 'button' && { onClick: onClick })}
						{...(CustomTag === 'button' && { type: type ? type : 'button' })}
						{...(CustomTag === 'button' && disabled && { disabled })}
						{...r}
					>
						{children}
					</CustomTag>
				)}
			>
				{icon && isFirstPositioned && (
					<Icon name={icon} size={iconSize} style={label && iconMargin} />
				)}
				{label}
				{icon && !isFirstPositioned && (
					<Icon name={icon} size={iconSize} style={label && iconMargin} />
				)}
			</ConditionalWrapper>
		);
	}
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
