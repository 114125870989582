import React from "react";
const Youtube = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 16"
      {...r}
    >
      <path
        fill="#272727"
        d="M17.606 2.995c-.076-.298-.292-.523-.539-.592C16.63 2.28 14.5 2 10 2s-6.628.28-7.069.403c-.244.068-.46.293-.537.592C2.285 3.419 2 5.196 2 8s.285 4.58.394 5.006c.076.297.292.522.538.59C3.372 13.72 5.5 14 10 14s6.629-.28 7.069-.403c.244-.068.46-.293.537-.592C17.715 12.581 18 10.8 18 8s-.285-4.58-.394-5.005zm1.937-.497C20 4.28 20 8 20 8s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C15.896 16 10 16 10 16s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C0 11.72 0 8 0 8s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C4.107 0 10 0 10 0s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM8 11.5v-7L14 8l-6 3.5z"
      ></path>
    </svg>
  );
};

export default Youtube;
