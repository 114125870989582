import React from "react";
const Share = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M7.751 8.74l3.243-3.242v7.56a1 1 0 102 0v-7.56l3.243 3.243a1 1 0 101.414-1.414l-4.95-4.95a1 1 0 00-1.414 0l-4.95 4.95A1 1 0 107.751 8.74z"
      ></path>
      <path
        fill="#272727"
        d="M19 19.636H5V13a1 1 0 10-2 0v7.636a1 1 0 001 1h16a1 1 0 001-1V13a1 1 0 10-2 0v6.636z"
      ></path>
    </svg>
  );
};

export default Share;
