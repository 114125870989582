/**
 * Concentramos todos los eventos que logean y trackean el sitio
 * se dividen los metodos por Flow, Clicks, PageViews, EVENTS
 */
import { readCookie } from '../Helpers/Cookies';
import GoogleAnalytics from '../Helpers/GoogleAnalytics';
import {
    loginDataDog,
    registerActivationDataDog,
    registerDataDog,
} from './DataDog';

/**
 *  codigo asociado a cada tipo
    el valor tiene su origen en la tabla Autenticacion_Tipo
 */
const TipoAuthId = {
    lnol: 11,
    facebook: 12,
    google: 13,
    siwa: 15,
};

/**
 * Decidimos el tipo de de logeo dependiendo de lo q venga en el User
 * @param {Object} User
 * @returns number
 */
const authTipo = (User) => {
    const { UsuarioFacebookId, UsuarioGmailId, AppleId } = User;
    return (
        (UsuarioFacebookId && TipoAuthId.facebook) ||
        (UsuarioGmailId && TipoAuthId.google) ||
        (AppleId && TipoAuthId.siwa) ||
        TipoAuthId.lnol
    );
};

/**
 * mapeamos el contexto de ingreso (nativo, redes, etc)
 * por el id y retornamos el string que machea con event de Analytics
 * @param {Number} tipoId
 * @returns {String}
 */
const mapTipoForAnalytics = (tipoId) => {
    let result = null;
    switch (tipoId) {
        case 11:
            result = 'Nativo';

            break;
        case 12:
            result = 'Facebook';

            break;
        case 13:
            result = 'Google';

            break;
        //TODO FALTA LOGEAR ESTO (PREGUNTAR A GISE)
        case 15:
            result = 'Siwa';

            break;

        default:
            break;
    }
    return result;
};

//===================
//FLOWS
//===================
/**
 * trackeo del LOGIN
 * @param {Object} User
 * @returns void
 */
export const LoginTrackAndLogData = (User) => {
    DefaultLoginAndRegisterTrackAndLogData(User, false);
};

/**
 * trackeo del REGISTER
 * @param {Object} User
 * @returns void
 */
export const RegisterTrackAndLogData = (User) => {
    DefaultLoginAndRegisterTrackAndLogData(User, true);
};

/**
 * trackeo default para LOGIN y REGISTER donde solo varía el isNewUser
 * @param {Object} User
 * @returns void
 */
const DefaultLoginAndRegisterTrackAndLogData = (User, isNewUser) => {
    const tipo = authTipo(User);
    const { SitioId } = User;
    const email = User.Email || User.FbEmail || User.GMail;
    const usuarioIdCookie = readCookie('usuario%5Fid');
    const stringAuthTipo = mapTipoForAnalytics(tipo);

    //DATADOG
    if (isNewUser) {
        registerDataDog(tipo, SitioId, email);
    } else {
        loginDataDog(tipo, SitioId, email);
    }

    //ANALYTICS
    GoogleAnalytics.trackLogin(stringAuthTipo, isNewUser, usuarioIdCookie);
};

//===================
//CLICKS
//===================
export const ClickTrackAndLogData = (step) => {
    switch (step) {
        case 'facebook':
            GoogleAnalytics.trackClickSSO('Facebook');
            break;

        case 'google':
            GoogleAnalytics.trackClickSSO('Google');
            break;

        case 'ingresar-nativo':
            GoogleAnalytics.trackClickSSO('Nativo');
            break;

        case 'registrate':
            GoogleAnalytics.trackClick('Crear cuenta link');
            break;

        case 'vincular-credencial':
            GoogleAnalytics.trackClick('Ingresar_VincularCredencial');
            break;

        case 'no-tengo-club':
            GoogleAnalytics.trackClick('Ingresar_NoTengoClub');
            break;

        case 'ahora-no':
            GoogleAnalytics.trackClick('Ingresar_AhoraNo');
            break;

        default:
            console.log('Error codigo no encontrado ', step);
            break;
    }
};

//===================
//PAGES VIEWS
//===================
export const PageViewTrackAndLogData = (step) => {
    switch (step) {
        case 'ingresar':
            GoogleAnalytics.trackPage('inicio');
            break;

        case 'ya-registrado-redes':
            GoogleAnalytics.trackPage('facilitar_acceso_red_social');
            break;

        case 'registracion':
            GoogleAnalytics.trackPage('crear_cuenta_directo_unica');
            break;

        case 'activacion-cuenta':
            GoogleAnalytics.trackPage('crear_cuenta_validar_codigo');
            break;

        case 'crear_cuenta_consulta_vincular':
            GoogleAnalytics.trackPage('crear_cuenta_consulta_vincular');
            break;

        case 'crear_cuenta_vincular_credencial':
            GoogleAnalytics.trackPageAndCheckout('crear_cuenta_vincular_credencial');
            break;

        case 'crear_cuenta_gracias_credencial_vinculada':
            GoogleAnalytics.trackPageAndCheckout('crear_cuenta_gracias_credencial_vinculada');
            break;

        case 'crear_cuenta_gracias':
            GoogleAnalytics.trackPageAndCheckout('crear_cuenta_gracias');
            break;

        case 'reestablecer_password':
            GoogleAnalytics.trackPage('reestablecer_password');
            break;

        case 'reestablecer_password_envio':
            GoogleAnalytics.trackPage('reestablecer_password_envio');
            break;

        case 'reestablecer_password_codigo':
            GoogleAnalytics.trackPage('reestablecer_password_codigo');
            break;

        case 'reestablecer_password_codigo_gracias':
            GoogleAnalytics.trackPage('reestablecer_password_codigo_gracias');
            break;

        case 'validar_pines':
            GoogleAnalytics.trackPage('validar_pines');
            break;

        case 'validar_pines_gracias':
            GoogleAnalytics.trackPage('validar_pines_gracias');
            break;

        case 'facilitar_red_social_success':
            GoogleAnalytics.trackPage('facilitar_red_social_success');
            break;

        case 'generar_password':
            GoogleAnalytics.trackPage('generar_password');
            break;

        case 'generar_password_exitoso':
            GoogleAnalytics.trackPage('generar_password_exitoso');
            break;

        default:
            console.log('Error codigo no encontrado ', step);
            break;
    }
};

//===================
//EVENTS
//===================
export const EventTrackAndLogData = (step, data) => {
    switch (step) {
        case 'autenticacion-error-username':
            GoogleAnalytics.trackEvent(
                'autenticacion',
                'error-form',
                'acceder_nativo_username-Ingresá un email o un usuario válido'
            );
            break;

        case 'ya-registrado-nativo':
            GoogleAnalytics.trackEvent(
                'autenticacion',
                'error-form',
                'crear_cuenta_directa_email-Ya existe el email ingresado'
            );
            break;

        case 'ya-registrado-google':
            GoogleAnalytics.trackEvent(
                'autenticacion',
                'error-form',
                'crear_cuenta_directa_email-Email Registrado Google'
            );
            break;

        case 'ya-registrado-facebook':
            GoogleAnalytics.trackEvent(
                'autenticacion',
                'error-form',
                'crear_cuenta_directa_email-Email Registrado Facebook'
            );
            break;

        case 'ya-registrado-apple':
            GoogleAnalytics.trackEvent(
                'autenticacion',
                'error-form',
                'crear_cuenta_directa_email-Email Registrado Apple'
            );
            break;

        case 'datos-validos':
            GoogleAnalytics.trackCreateAccount();
            break;

        case 'registracion-codigo-validado':
            const tipo = authTipo(data);
            const { SitioId } = data;
            const email = data.Email || data.FbEmail || data.GMail;

            //DATADOG
            registerActivationDataDog(tipo, SitioId, email);

            //ANALYTICS
            GoogleAnalytics.trackActivateAccount();

            break;

        case 'nueva-pass-no-puede-ser-igual':
            GoogleAnalytics.trackEvent(
                'autenticacion',
                'error-form',
                'reestablecer_password_codigo_password-La nueva contraseña ' +
                    'no puede ser igual a una contraseña utilizada previamente.'
            );
            break;

        case 'pin_ya_utilizado':
            GoogleAnalytics.trackEvent(
                'Código de Activación',
                'error-form',
                'El código de activación que ingresaste ya ha sido utilizado'
            );
            break;

        case 'generar-acceso-con-contrasenia':
            GoogleAnalytics.trackEvent(
                'autenticacion',
                'Generar Acceso con Contraseña',
                'Inicio de proceso'
            );
            break;

        case 'contrasenia-generada-exitosamente':
            GoogleAnalytics.trackEvent(
                'autenticacion',
                'Generar Acceso con Contraseña',
                'Fin de proceso'
            );
            break;

        default:
            console.log('Error codigo no encontrado ', step);
            break;
    }
};
