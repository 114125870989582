import React from "react";
const Knife = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M4.342 1.408L22.373 19.44a1.5 1.5 0 01-2.121 2.122l-4.596-4.597L12.12 20.5l-7.778-7.778a8 8 0 01-.174-11.135l.174-.179zm.241 3.07l-.051.11a6.005 6.005 0 001.047 6.535l.177.185 6.363 6.363 2.829-2.828L4.583 4.478z"
      ></path>
    </svg>
  );
};

export default Knife;
