import { trackCustomEvent } from '../Config/DataDog';
import ApiService from '../Services/ApiService';
import useLogout from '../Services/_LogoutService';
import { getAccessTokenCookie, getIdTokenCookie, getRefreshTokenCookie } from '@ln/user.client.libs'

const useValidateUserAuth0Handler = () => {
    const { REACT_APP_APILOGIN } = process.env;
    const handleLogout = useLogout();
    const validateUser = async (returnCallback = process.env.REACT_APP_MYACCOUNT) => {
        try {
            const accessToken = getAccessTokenCookie();
            const headers = {
                'Content-Type': 'application/json',
                'X-Token': getIdTokenCookie(),
                'X-Value': getRefreshTokenCookie(),
                'Authorization': accessToken && `Bearer ${accessToken}`,
            };
            const response = await ApiService.getWithHeaders(
                `${REACT_APP_APILOGIN}Auth0/ValidateUserAuth0/`, headers)

            if (response?.code === "0000") {
                window.location.href = `${returnCallback}`;
            }
            else {
                throw new Error(response.message);
            }
        } catch (error) {
            trackCustomEvent("logout", true, { message: `useValidateUserAuth0Handler - ${error.message}` })
            handleLogout();
        }
    };
    return [validateUser]
}
export default useValidateUserAuth0Handler;