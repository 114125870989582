import React from "react";
const Email = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 18"
      {...r}
    >
      <path
        fill="#272727"
        fillRule="evenodd"
        d="M1 0h18a1 1 0 011 1v16a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1zm17 4.238l-7.266 6.507a1 1 0 01-1.329.005L2 4.216V16h16V4.238zM2.511 2l7.55 6.662L17.502 2H2.511z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Email;
