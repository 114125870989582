import React from "react";
const Twitter = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 21 18"
      {...r}
    >
      <path
        fill="#272727"
        d="M14.3 2.55a2.9 2.9 0 00-2.9 2.847l-.028 1.575a.599.599 0 01-.68.583l-1.561-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 01.034.993L6.793 15.17c.947.059 1.846.017 2.592-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141zM9.4 5.36a4.9 4.9 0 018.385-3.355c.711-.005 1.316.175 2.669-.645-.335 1.64-.5 2.352-1.214 3.331 0 7.642-4.697 11.358-9.463 12.309-3.268.652-8.02-.419-9.382-1.841.694-.054 3.514-.357 5.144-1.55C4.16 12.7-1.329 9.47 2.278.786c1.693 1.977 3.41 3.323 5.15 4.037 1.158.475 1.442.465 1.973.538L9.4 5.36z"
      ></path>
    </svg>
  );
};

export default Twitter;
