import React from "react";
const CalendarFilled = ({ ...r }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...r}
    >
      <path
        fill="#272727"
        d="M2 11h20v9a1 1 0 01-1 1H3a1 1 0 01-1-1v-9zm15-8h4a1 1 0 011 1v5H2V4a1 1 0 011-1h4V2a1 1 0 012 0v1h6V2a1 1 0 112 0v1z"
      ></path>
    </svg>
  );
};

export default CalendarFilled;
