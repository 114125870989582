import { useHistory } from 'react-router';
import {
    LoginTrackAndLogData,
    RegisterTrackAndLogData,
} from '../Config/TrackAndLogData';
import ModosUrl from '../Models/ModosUrl';
import { useStore } from '../Store/storeProvider';
import useValidarCredencialBlack from './useValidarCredencialBlack';

const useFinalFlow = () => {
    const history = useHistory();
    const { Config } = useStore();
    const [handleValidarCredBlack] = useValidarCredencialBlack();

    const handleFinalFlow = (tipoCred, User, callback) => {
        let isPin = !!Config.Pin;
        let isNewUserNativo = User.IsNewUser && User.Clave !== '';
        let isModoBlack = Config.Modo == ModosUrl.black;

        //Final Cut
        // Depende del state del user y que predomina mas que otro estado
        // cambia el camino.
        //Alicia le preg al conejo:  que camino tengo que seguir?
        //El conejo: eso depende al lugar donde quieras ir.
        // Alicia: No me importa mucho a donde.
        // Conejo: entonces no importa por cual vayas.
        if (isPin) {
            history.push(Config.URLCallback);
        } else if (isModoBlack) {
            handleValidarCredBlack(tipoCred);
        } else if (isNewUserNativo) {
            history.push('/registracion/vincular');
        } else {
            window.location = callback;
        }
    };

    return [handleFinalFlow];
};

export default useFinalFlow;
